import {Button, Col, Container, Row} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import AftLogo from "../Images/AftLogo";

type IndexPageProps = {};
const IndexPage = (props: IndexPageProps) => {

    const products = [
        { name: 'Term Life AD&D', path: '/apply/term-life-add' },
       /* { name: '$25K GI Term Life Special Offer', path: '/apply/guaranteed-term-life-add' },*/
        { name: '$5k, 12-Month Guaranteed Issue Term Life', path: '/apply/5k' },
        { name: "LTD - Disability Income", path: '/apply/ltd-disability' }
    ]
    const navigate = useNavigate();
    const navigateToProduct = (path: string) => {
        navigate(path);
    }

    return (
        <Container className="vh-100 vw-100 d-flex align-items-center justify-content-center flex-column">
            <Row className="w-100">
                <Col className="text-center">
                    <AftLogo className="mb-3 w-25"/>
                    <h1>Enrollment Application</h1>
                    <h2>Testing Assistance Screen</h2>
                    <p className="help-block">Select a product from the list below. Clicking a button will take you to
                        the corresponding product's log in / registration page.</p>
                </Col>
            </Row>
            <Row className="w-100">
                <Col className="d-flex justify-content-evenly">
                    {products.map(x => (<Button variant="primary" onClick={() => {
                        navigateToProduct(x.path)
                    }}>{x.name}</Button>))}
                </Col>
            </Row>
        </Container>
    );
}
export default IndexPage;