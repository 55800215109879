import React, { useState } from 'react';
import { Button, Col, Row } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import { RegisterValidator } from "../../helpers/validator";
import { AuthenticationMethod, DirectLogInAuthentication } from "../../services/auth/authentication";
import { InputEvent } from "../../types/events";
import AlertDanger from "../Alerts/AlertDanger";
import AlertPrimary from '../Alerts/AlertPrimary';
import Loading from "../Transitions/Loading";
import FormWithValidation from "./FormWithValidation";
import { TextInput } from "./Inputs/TextInput";
type ForgotPasswordProps = {
  onForgotPasswordBack(): void;
  onForgotPasswordSuccess(): void;
};

const ForgotPassword = (props: ForgotPasswordProps) => {
    const initialState = {
        emailAddress: "",
        password: "",
        isFailed: false, isLoading: false, isSuccesfull:false,
        loadingMessage: "Submitting....",
        isValid: false,
    }

    const [state, setState] = useState(initialState);
    const handleChange = (e: InputEvent) => {
        let {name, value} = e.target;
        setState(prevState => ({...prevState, [name]: value}));
    }
     
    const validator =  new RegisterValidator();

    let authMethod: AuthenticationMethod = new DirectLogInAuthentication();
    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        e.stopPropagation();
        setState(prevState => ({ ...prevState, isLoading:true }));

        //setLoading(true, state.loadingMessage);
        authMethod.forgotpassword(state.emailAddress).then(fulfilled => { 
            if (fulfilled) {
                setState(prevState => ({ ...prevState, isFailed: !fulfilled, isSuccesfull: true, isLoading:false }));
                // show success messsage
                props.onForgotPasswordSuccess();
                return;
            } else {
                //show error message
                setState(prevState => ({ ...prevState, isLoading: false, isFailed: true }));
            }
        })
            .catch(error => {
                setState(prevState => ({ ...prevState, isLoading: false, isFailed: true }));
            });
    }

    const alert = state.isFailed ?  (<AlertDanger message="We were unable send recovery link to provided email. Please try again later." />):null;
    return (
        <>
            <h3>Enter your email address</h3>
            {state.isLoading ? <Loading message={state.loadingMessage} /> : null }
            {alert}
            <FormWithValidation controlId="form-register" validator={validator} onSubmit={handleSubmit}>
                <Form.Group controlId="email-address-register" className="mb-3">
                    <TextInput label="Email Address" onChange={handleChange}
                        value={state.emailAddress} placeholder="Enter Email Address" name="emailAddress" controlId="reg-email" type="email" />
                </Form.Group>
                
            <Row>
                <Col><Button variant="primary" className="w-100 mb-3" type="submit">Submit</Button></Col>
                <Col><Button variant="primary" className="w-100 mb-3" type="submit" onClick={props.onForgotPasswordBack} >Cancel</Button></Col>
            </Row>
            </FormWithValidation> 
        </>
    )
}
export default ForgotPassword;


