export default class ConsoleLogger {
  private readonly _loggingEnabled: boolean = true;
  // private readonly _loggingEnabled: boolean = (process.env.REACT_APP_CONSOLE_LOGGING_ENABLED ?? false) == 'true';
  private readonly _name: string;
  constructor(n: string) {
    this._name = n;
  }

  debug(...params: any[]):void {
    if(!this._loggingEnabled){
      return;
    }
    const date = new Date(Date.now());
    console.debug(`[${this._name}]`,`[${date.toISOString()}]`, ...params);
    // console.trace();
  }

  info(...params: any[]):void {
    if(!this._loggingEnabled){
      return;
    }
    const date = new Date(Date.now());
    console.log(`[${this._name}]`,`[${date.toLocaleString()}]`, ...params);
  }

  error(...params: any[]):void {
    if(!this._loggingEnabled){
      return;
    }
    const date = new Date(Date.now());
    console.error(`[${this._name}]`,`[${date.toLocaleString()}]`, ...params);
  }
}