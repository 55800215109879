import React from "react";
import {
  ApplicationDto,
  AuthorizationsDto,
  CoverageDto,
  DemographicDto,
  DependentDto,
  HealthDataDto,
  HealthQuestionDto,
  MedicationDto,
  OptInDto,
  SerializedProduct,
  SpouseDto
} from "../api/api";
import {GenderOptions} from "../../enumerations";
import {ProductId} from "../../types/product";
import Numbers from "../../helpers/numbers";
import ConsoleLogger from "../logging/logger";
import {TBeneficiary} from "../../types/beneficiaries";
import {initAlert, ServerSideValidationAlert} from "./alerts";
import {isBmiValid} from "../../helpers/validator";
import { UnderwritingHelper } from "../../helpers/underwriting";

const logger = new ConsoleLogger("SessionHelper")
type UwReferral ={
  member: {
    shortFormCorrect: boolean,
    shownLongForm: boolean,
    shownSOHForm: boolean,
  },
  spouse: {
    shortFormCorrect: boolean,
    shownLongForm: boolean,
    shownSOHForm: boolean,
  }
}
export type SessionData = {
  pdmSuccess: boolean,
  uwReferral: UwReferral,
  applicationId: number,
  paymentFrequency: number,
  product: SerializedProduct,
  demographic: DemographicDto,
  coverages: CoverageDto,
  spouse: SpouseDto,
  authorization: AuthorizationsDto,
  dependents: DependentDto,
  underwriting: HealthQuestionDto[],
  beneficiaries: TBeneficiary[],
  healthData: HealthDataDto,
  optIns: OptInDto,
  errors: ServerSideValidationAlert,
  effectiveDate: Date
}

export const initialSession: SessionData = {
  product: {
    id: ProductId.TERM_LIFE_ADD,
    displayName: "",
    productKey: "",
    componentNames: [],
    showWeight: true,
    showHeight: true,
    showBasicAnnualEarnings: true,
    minAge: 18,
    maxAge: 70,
  },
  effectiveDate: new Date(),
  pdmSuccess: false,
  applicationId: -1,
  paymentFrequency: 1,
  demographic: {
    firstName: "",
    middleName: "",
    lastName: "",
    streetAddress: "",
    apartment: "",
    city: "",
    state: "",
    zipCode: "",
    dateOfBirth: "",
    ssn: "",
    phoneNumber: "",
    phoneType: "Home",
    altPhoneNumber: "",
    altPhoneType: "",
    gender: GenderOptions.NONE as string,
    height: null,
    weight: null,
    basicAnnualEarnings: 0,
    emailAddress: "",
    applicationId: -1,
    aftMembershipDate: "",
  },
  coverages: {
    applicationId: -1,
    benefitAmount: 0,
    eleminationDays: 60,
    isADDEnrolled: null,
    addBenefitAmount: 0
  },
  spouse: {
    applicationId: -1,
    isSpouseEnrolled: false,
    spouseBenefitAmount: 0,
    firstName: undefined,
    middleName: undefined,
    lastName: undefined,
    gender: undefined,
    dateOfBirth: undefined,
    isUsBorn: undefined,
    birthState: "",
    birthCountry: undefined,
    height: undefined,
    weight: undefined,
  },
  authorization: {
    applicationId: -1,
    birthStateOrRegion: "",
    birthCountry: "United States of America",
      isSigned: false,
      isSignedSpouse: false,
      birthCountrySpouse: "United States of America",
      birthStateOrRegionSpouse:""
  },
  dependents: {
    wantsDependents: false,
    benefitAmount: undefined,
    dependents: []
  },
  underwriting: [],
  beneficiaries: [],
  healthData: {
    applicationId: -1,
    memberPhysician: {
      applicationId: -1,
      appliesTo: "",
      apartment: undefined,
      firstName: undefined,
      lastName: undefined,
      streetAddress: undefined,
      city: undefined,
      state: undefined,
      zipCode: undefined,
      phoneNumber: undefined,
      lastVisitDate: undefined,
      visitReason: undefined,
      hasPrescription: undefined
    },
    spousePhysician: {
      applicationId: -1,
      appliesTo: undefined,
      apartment: undefined,
      firstName: undefined,
      lastName: undefined,
      streetAddress: undefined,
      city: undefined,
      state: undefined,
      zipCode: undefined,
      phoneNumber: undefined,
      lastVisitDate: undefined,
      visitReason: undefined,
      hasPrescription: undefined
    },
    memberMedications: new Array<MedicationDto>(),
    spouseMedications: new Array<MedicationDto>()
  },
  optIns: {
    applicationId: -1,
    edeliverPolicyDocuments: false,
    recurringBilling: false
  },
  errors: initAlert,
  uwReferral: {
    member: {
      shownLongForm: false,
      shownSOHForm: false,
      shortFormCorrect: true,
    },
    spouse: {
      shownLongForm: false,
      shownSOHForm: false,
      shortFormCorrect: true,
    }

  }
};


export type SessionState = {
  data: SessionData,
  setProduct(product: SerializedProduct): void,
  refresh(): void
};

const sessionState: SessionState = {
  data: initialSession,
  setProduct: (product: SerializedProduct) => {
  },
  refresh() {
  }
};


const SessionContext = React.createContext(sessionState);
export const SessionContextProvider = SessionContext.Provider;

export class SessionHelper {
  static memberName(s: SessionState): string {
    const {firstName, middleName, lastName} = s.data.demographic;
      if (middleName) {
        return `${firstName} ${middleName} ${lastName}`;
      }
    return `${firstName} ${lastName}`;
  }

  static spouseName(s: SessionState): string {
    if(!s.data.spouse.isSpouseEnrolled){
      return "";
    }

      const { firstName, middleName, lastName } = s.data.spouse;
      if (middleName) {
          return `${firstName} ${middleName} ${lastName}`;
      }
    return `${firstName} ${lastName}`;
  }

  static memberCoverageAmount(s: SessionState): string {
    return Numbers.toCurrency(parseFloat(`${s.data.coverages.benefitAmount}`));
  }

  static memberWantsAdd(s: SessionState): boolean {
    return s.data.coverages.isADDEnrolled !== null && s.data.coverages.isADDEnrolled;
  }

  static memberAddAmount(s: SessionState): string {
    const value = s.data.coverages.addBenefitAmount ?? 0;
    return Numbers.toCurrency(parseFloat(`${value}`));
  }

  static wantsSpouse(s: SessionState): boolean {
    return s.data.spouse.isSpouseEnrolled;
  }

  static hasDependents(s: SessionState): boolean {
    return s.data.dependents.wantsDependents;
  }

  static numberOfDependents(s: SessionState): number {
    return s.data.dependents.dependents.length;
  }

  static dependentCoverageAmount(s: SessionState): string {
    if(!this.hasDependents(s)){
      return Numbers.toCurrency(0);
    }
    const amount: number = parseFloat(`${s.data.dependents.benefitAmount}`);
    return Numbers.toCurrency(amount);
  }

  static spouseCoverageAmount(s:SessionState): string {
    if(!s.data.spouse.isSpouseEnrolled){
      return "";
    }
    const benefitAmount = parseFloat(`${s.data.spouse.spouseBenefitAmount}`);
    return Numbers.toCurrency(benefitAmount);
  }

  static setFromApiResponse(s: SessionState, emailAddress: string = ''): (a: ApplicationDto) => void {
    const parseDate = (date: string) => date.split('T')[0];


    return (a: ApplicationDto) => {

      s.data.applicationId = a.id;
      s.data.effectiveDate = a.effectiveDate;
      if (a.demographic !== null) {
        s.data.demographic = {
          ...a.demographic,
          dateOfBirth: parseDate(a.demographic.dateOfBirth),
          aftMembershipDate: parseDate(a.demographic.aftMembershipDate)
        }
      }
      else{
         s.data.demographic.emailAddress = emailAddress
      }
      if(a.memberCoverage !== null){
        s.data.coverages = a.memberCoverage;
      }
      if(a.spouse !== null){
        if(a.spouse.isSpouseEnrolled) {
          const dob = a.spouse.dateOfBirth as string;
          s.data.spouse = {
            ...a.spouse,
            dateOfBirth: dob.split('T')[0]
          };
        }
        else { s.data.spouse = {...a.spouse}}
      }
      if(a.dependents.length > 0){
        s.data.dependents.wantsDependents = true;
        s.data.dependents.benefitAmount = a.dependents[0].benefitAmount;
          s.data.dependents.dependents = a.dependents;
          for (var i = 0; i < s.data.dependents.dependents.length; i++) {
              s.data.dependents.dependents[i].dateOfBirth = parseDate(s.data.dependents.dependents[i].dateOfBirth)
          }
      }
      else{
        s.data.dependents.wantsDependents = false;
      }

        s.data.beneficiaries = a.beneficiaries;
        for (var i = 0; i < s.data.beneficiaries.length; i++) {
            s.data.beneficiaries[i].dateOfBirth = parseDate(s.data.beneficiaries[i].dateOfBirth);
        }
      if(a.underwritings) {
        s.data.underwriting = a.underwritings;
      }

      if(a.memberPhysician) {
        s.data.healthData.memberPhysician = {...a.memberPhysician, lastVisitDate: parseDate(a.memberPhysician.lastVisitDate as string)};
      }

      if(a.spousePhysician) {
        s.data.healthData.spousePhysician = {...a.spousePhysician, lastVisitDate: parseDate(a.spousePhysician.lastVisitDate as string)};
      }

      s.data.healthData.memberMedications = a.medications.filter(x => x.appliesTo?.toLowerCase() === 'member');
      s.data.healthData.spouseMedications = a.medications.filter(x => x.appliesTo?.toLowerCase() === 'spouse');
      s.data.optIns.applicationId = a.id;
      if(a.opt){
        s.data.optIns = a.opt;
      }
      if(a.authorization){
        s.data.authorization = a.authorization;
      }
    };
  }

  static spouseHeight(s: SessionState) {
    if(!s.data.spouse.isSpouseEnrolled){
      return 0;
    }
    return s.data.spouse.height;
  }

  static spouseWeight(s: SessionState) {
    if(!s.data.spouse.isSpouseEnrolled){
      return 0;
    }
    return s.data.spouse.weight;
  }

  static membershipWithinOneYear(s: SessionState): boolean {
    const membershipDate = Date.parse(s.data.demographic.aftMembershipDate+" 00:00:00.000+00:00");
    const now = new Date(Date.now()).getTime();
    const today = now - (now %86400000)
    const difference = today - membershipDate;
    return difference <= 31536000000; // Number of milliseconds in a year. 60*60*24*365*1000
  }

  static hasMemberFailedUw(s:SessionState): boolean {
    if(s.data.product.id === ProductId.TERM_LIFE_ADD){
      return s.data.uwReferral.member.shownLongForm || s.data.uwReferral.spouse.shownLongForm;
    }
    if(s.data.product.id === ProductId.LTD_DISABILITY){
      const {height, weight} = s.data.demographic;
      if(SessionHelper.membershipWithinOneYear(s) && isBmiValid(height as number, weight as number)){
        return s.data.uwReferral.member.shownSOHForm;
      }
      return s.data.uwReferral.member.shownLongForm;
    }
    return false;
  }

  static hasSpouseFailedUw(s: SessionState): boolean{
    if(!s.data.spouse.isSpouseEnrolled){
      return false;
    }
    if(s.data.product.id === ProductId.TERM_LIFE_ADD){
      return s.data.uwReferral.spouse.shownLongForm;
    }
    return false;
  }
    static fetchSubmissionStatus(s: SessionState): number {
        const uwHelper = new UnderwritingHelper(s);

        if (s.data.product.id === ProductId.GI_TERM_LIFE) {
            return STATUS_SUBMITTED_GI;
        }
        console.log("fetchSubmissionStatus");
        const isAllNo = s.data.underwriting.filter(x =>
            typeof x.answer === 'string' && x.answer.toLowerCase() === "yes" &&
            typeof x.answer_To === 'string' && x.answer_To.toLowerCase() === "member"
        ).length === 0;
        console.log("isAllNo=" + isAllNo);

        const { height, weight } = s.data.demographic;

        if (s.data.product.id === ProductId.LTD_DISABILITY) {
            if ((SessionHelper.membershipWithinOneYear(s) && isAllNo) || (!SessionHelper.membershipWithinOneYear(s) && isAllNo && isBmiValid(height as number, weight as number))) {
                return STATUS_SUBMITTED_SI;
            }

            return STATUS_UWREFERRAL;
        }

        if (s.data.product.id === ProductId.TERM_LIFE_ADD) {

            if (SessionHelper.membershipWithinOneYear(s) && s.data.coverages.benefitAmount == 25000)
                return STATUS_SUBMITTED_GI;

            if (this.hasMemberFailedUw(s)) {
                return STATUS_UWREFERRAL;
            }

            return STATUS_SUBMITTED_SI;
        }


        return STATUS_SUBMITTED;
    }
  }

  const STATUS_SUBMITTED = 2;
  const STATUS_UWREFERRAL = 3;
  const STATUS_UWREFERRAL_MEMBER = 4;
  const STATUS_UWREFERRAL_SPOUSE = 5;
  const STATUS_SUBMITTED_GI = 6;
  const STATUS_SUBMITTED_SI = 7;

export default SessionContext;

