export enum GenderOptions {
    NONE = "None",
    MALE = "Male",
    FEMALE = "Female"
}

export enum BeneficiaryRelationship {
    INDIVIDUAL = "Individual"
}

export enum EliminationPeriods {
    SIXTY_DAY = "60 Day",
    NINETY_DAY = "90 Day",
}