import {Alert} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleExclamation, faTriangleExclamation} from "@fortawesome/free-solid-svg-icons";

type AlertPrimaryProps = {
    children: any;
};
const AlertPrimary = (props: AlertPrimaryProps) => {
    return (
        <Alert variant="primary" className="d-flex align-items-center">
            <FontAwesomeIcon icon={faCircleExclamation} size="2x" className="me-3"/>
            <p className="mb-0">{props.children}</p>
        </Alert>
    );
}
export default AlertPrimary;