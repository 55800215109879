import {EnrollmentSequenceItem} from "../Sequence/EnrollmentSequence";
import {Button} from "react-bootstrap";

export type ProgressButtonProps = {
    sequenceItem: EnrollmentSequenceItem,
    controlId: string,
    selected: boolean,
    onClick(componentName: string): void
};


const ProgressButton = (props: ProgressButtonProps) => {
    const variant = props.selected ? "success" : "secondary";
    const disabled = !props.selected;

    const handleClick = () => {
        if(!props.selected){
            return;
        }
        props.onClick(props.sequenceItem.component.name);
    }


    return (
        <Button onClick={handleClick} id={props.controlId} disabled={disabled} variant={variant} className="btn-progress">
            <div>
                {props.sequenceItem.component.display}
            </div>
        </Button>
    );
}
ProgressButton.defaultProps = {
    selected: false
}
export default ProgressButton;