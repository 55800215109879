import React, { useContext, useState } from 'react';
import { Button } from "react-bootstrap";
import { Simulate } from "react-dom/test-utils";
import { LogInValidator } from "../../helpers/validator";
import { JwtApiClient } from "../../services/api/api";
import { AuthenticationMethod, DirectLogInAuthentication } from "../../services/auth/authentication";
import SessionContext, { SessionHelper } from "../../services/session/session";
import AlertDanger from "../Alerts/AlertDanger";
import Loading from "../Transitions/Loading";
import FormWithValidation from "./FormWithValidation";
import { TextInput } from "./Inputs/TextInput";
import load = Simulate.load;

export type LogInSuccessProps = {
    onLoginSuccess(): void;
}

export type LogInFailedProps = {
    onLogInFailed(): void;
};

export type DirectLoginCredential = {
    username: string;
    password: string;
}


export type LogInState = {
    isAttempted: boolean;
    isSuccessful: boolean;
    isFailed: boolean;
}

const initialLoginState: LogInState = {
    isAttempted: false,
    isSuccessful: false,
    isFailed: false
}

const LogIn = (props: LogInSuccessProps) => {

    const [logInState, setLogInState] = useState(initialLoginState);
    const initLoading = {
        isLoading: false,
        message: "Logging you in...", isValidated: false
    }
    const [credentials, setCredentials] = useState(["", ""]);
    const [username, password] = credentials;
    const [loading, setLoading] = useState(initLoading);
    const auth: AuthenticationMethod = new DirectLogInAuthentication();
    const apiClient = new JwtApiClient();
    const session = useContext(SessionContext);
    const handleEmailChange = (e: any) => {
        setCredentials(prev => {
            return [e.target.value, prev[1]];
        });
    }

    const handlePasswordChange = (e: any) => {
        setCredentials(prev => {
            return [prev[0], e.target.value];
        });
    }

    const onLogInFailed = () => {
        setLogInState(prev => {
            return {
                isFailed: true,
                isSuccessful: false,
                isAttempted: true
            };
        });
    }
    const validator = new LogInValidator();


    const handleLoginClick = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!validator.validate(event.target as HTMLFormElement)) {
            return;
        }
        setLoading(p => ({...p, isLoading: true}));
        auth.authenticate(credentials).then(result => {
            if (!result) {
                onLogInFailed();
            } else {
                setLogInState(prev => {
                    return {
                        isFailed: false,
                        isSuccessful: true,
                        isAttempted: true
                    };
                });
                //TODO: Replace with Promise for Demographics
                setLoading(p => ({...p, isLoading: true, message: "Retrieving your application..."}));
                apiClient.fetchApplication(session.data.product.productKey)
                    .then(SessionHelper.setFromApiResponse(session, credentials[0]))
                    .then(props.onLoginSuccess);
                return;
            }
            setLoading(p => ({...p, isLoading: false}));
        });
    }

    let alert = logInState.isFailed ? <AlertDanger
        message="We were unable to authenticate an account with the provided credentials. Please try again."/> : null;

    return (
        <>
            {loading.isLoading ? <Loading message={loading.message} /> : null}

            <FormWithValidation controlId="form-login" validator={validator} onSubmit={handleLoginClick}>
                {alert}
                <TextInput placeholder="" label="Email Address" onChange={handleEmailChange} value={username}
                           name="email" controlId="login-email" type="email"/>
                <TextInput label="Password" onChange={handlePasswordChange} value={password}
                           placeholder="" name="password" controlId="login-password" type="password"/>
                <Button id="form-login-submit" type="submit" variant="primary" className="w-100">Log In</Button>
            </FormWithValidation>
        </>
    );
}


export default LogIn;