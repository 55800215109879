import {Button, ButtonGroup, Card, Col, Row, Stack} from "react-bootstrap";
import {Container} from "reactstrap";
import AftLogo from "../Images/AftLogo";
import React, {useContext} from "react";
import {useNavigate} from "react-router-dom";
import session, {initialSession} from "../../services/session/session";
import SessionContext from "../../services/session/session";

const LogOut = () => {
    window.sessionStorage.clear();
    const  session = useContext(SessionContext);
    const navigate = useNavigate();
    const productKey = session.data.product.productKey;
    session.data = initialSession;
    const handleProductRedirect = () => {
        window.location.href = `/apply/${productKey}`;
    }
    return (
        <div className="d-flex flex-column min-vh-100 pt-3">
            <Container className="pb-5">
                <Row className="d-flex justify-content-between">
                    <Col className="align-items-center">
                        <AftLogo className="w-50"/>
                    </Col>
                    <Col className="d-flex flex-column justify-content-center align-items-end">
                        <div>
                            <h2 className="">Enrollment Form</h2>
                            <h2 className="text-primary ">{session.data.product.displayName}</h2>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Container className="d-flex align-items-center justify-content-center">
                <Row>
                    <Col>
                        <Card className="card-screen w-100">
                            <Card.Header>
                                <Card.Title>
                                    You have been successfully logged out!
                                </Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <p>Have a nice day!</p>
                            </Card.Body>
                            <Card.Footer>
                                <Button variant="success" onClick={handleProductRedirect} >Return to Product</Button>
                            </Card.Footer>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}
export default LogOut;