import {Col, Form, Row} from "react-bootstrap";
import ConsoleLogger from "../../../services/logging/logger";

export enum YesNoOptions {
    Yes="Yes",
    No="No",
    NotSelected="NotSelected"
}
const logger = new ConsoleLogger("YesNoSwitch");

export type YesNoProps = {
    controlId: string;
    name: string;
    value: YesNoOptions;
    required: boolean
    label: string|JSX.Element;
    onYesSelected(): void;
    onNoSelected(): void;
    invalidMessage: string,
};
const YesNoSwitch = (props: YesNoProps) => {
    const handleChange = (e: any) => {
        if (e.target.checked) {
            props.onYesSelected();
            return;
        }
        props.onNoSelected();
    }
    return (
        <Form.Group controlId={props.controlId}>
            <Form.Check aria-describedby={`${props.controlId}-invalid`} type="switch" label={props.label} onChange={handleChange}
                        checked={props.value === YesNoOptions.Yes} required={props.required} feedback={props.invalidMessage} feedbackType="invalid" />
           
            
        </Form.Group>
    );
}
YesNoSwitch.defaultProps = {
    required: false,
    invalidMessage: "This is required."
}

export const YesNoToggle = (props: YesNoProps) => {

    const isChecked = (expected: YesNoOptions):boolean => {
        return props.value as YesNoOptions === expected;
    }

    const yesChecked: boolean = isChecked(YesNoOptions.Yes);
    const noChecked: boolean = isChecked(YesNoOptions.No);
    const yesId: string = `${props.controlId}-yes`;
    const noId: string = `${props.controlId}-no`;
    const required = props.required ? 'required' : '';
    return (
        <Form.Group controlId={props.controlId}>
            <Row>
                <Col xs={8} sm={12}>
                    <Form.Label className={`d-block yn-label ${required}`} dangerouslySetInnerHTML={{__html: props.label as string}} />
                </Col>

                <Col className="align-self-center">

                    <input aria-label={"Select Yes for "+props.label} type="radio" checked={yesChecked} className="btn-check" onChange={props.onYesSelected}
                           name={props.name} id={yesId} autoComplete="off" required={props.required} aria-describedby={`${props.controlId}-invalid`}
                    />
                    <label className="btn btn-outline-primary me-3" htmlFor={yesId}>Yes</label>

                    <input aria-label={"Select No for "+props.label} aria-describedby={`${props.controlId}-invalid`} type="radio" className="btn-check" name={props.name} onChange={props.onNoSelected} id={noId}
                        autoComplete="off" checked={noChecked} required={props.required}/>
                    <label className="btn btn-outline-primary me-3" htmlFor={noId}>No</label>
                    <Form.Control.Feedback type="invalid" id={`${props.controlId}-invalid`}>
                        {props.invalidMessage}
                    </Form.Control.Feedback>
                </Col>
            </Row>
        </Form.Group>
    );
};
YesNoToggle.defaultProps = {required: false, invalidMessage: "Please make a selection"}

export default YesNoSwitch;