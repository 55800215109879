import {SessionHelper, SessionState} from "../services/session/session";
import {ProductId} from "../types/product";
import {Collection} from "./collections";
import {
  AggregatedForms,
  ByAskedFormByFormTypeItem,
  HealthQuestionAskedTo,
  HealthQuestionTypes
} from "../models/health-questions";
import {HealthQuestionDto} from "../services/api/api";
import {YesNoOptions} from "../components/Forms/Inputs/YesNoSwitch";
import AggregatedQuestionsHelper from "../stub/HealthQuestions";
import {HealthQuestionProps, HealthQuestionState} from "../components/Forms/HealthQuestions/HealthQuestion";
import ConsoleLogger from "../services/logging/logger";
import {isBmiValid} from "./validator";

const HEALTH_QUESTION_ASKED_MAP = new Collection([
  {lookupKey: "MEMBER", value: HealthQuestionAskedTo.MEMBER},
  {lookupKey: 1 << 0, value: HealthQuestionAskedTo.MEMBER},
  {lookupKey: "SPOUSE", value: HealthQuestionAskedTo.SPOUSE},
  {lookupKey: 1 << 1, value: HealthQuestionAskedTo.SPOUSE},
]);


const STATE_CONNECTICUT = 'CT';

export class UnderwritingHelper {
  private readonly _session: SessionState;

  constructor(session: SessionState) {
    this._session = session;
  }

  isBmiValid(heightInches: number, weight: number): boolean {
    return isBmiValid(heightInches, weight);
  }

    isMemberBase(): boolean {
        if (this._session.data.product.id === ProductId.TERM_LIFE_ADD) {
            return this._session.data.coverages.benefitAmount == 25000 &&
                SessionHelper.membershipWithinOneYear(this._session);
        }
        return false;
    };

  isMemberGi() {
    if (this._session.data.product.id === ProductId.TERM_LIFE_ADD) {
      return this._session.data.coverages.benefitAmount == 25000 &&
        SessionHelper.membershipWithinOneYear(this._session);
    }
    return this._session.data.product.id === ProductId.GUARANTEED_TERM_LIFE_ADD;
  }

  isSpouseGi() {
    if(!this.isMemberGi()){
      return false;
    }
    if (!this._session.data.spouse.isSpouseEnrolled) {
      return false;
    }
    if (this._session.data.product.id === ProductId.TERM_LIFE_ADD) {
      return this._session.data.spouse.spouseBenefitAmount == 13000 && SessionHelper.membershipWithinOneYear(this._session);
    }
    return false;
  }

  isMemberCoverageFavorable(): boolean {
    const memberCoverage = this._session.data.coverages.benefitAmount;
    const productId = this._session.data.product.id;

    if (productId === ProductId.TERM_LIFE_ADD) {
      return (memberCoverage >= 25000 && memberCoverage <= 200000);
    }

    if(productId === ProductId.LTD_DISABILITY && SessionHelper.membershipWithinOneYear(this._session)){
      return true;
    }

    if(productId === ProductId.LTD_DISABILITY && !SessionHelper.membershipWithinOneYear(this._session)){
      return this.isBmiValid(
        this._session.data.demographic.height ?? 0,
        this._session.data.demographic.weight ?? 0
      );
    }
    return true;
  }

  isSpouseCoverageFavorable(): boolean {
    if (!this._session.data.spouse.isSpouseEnrolled) {
      return true;
    }

    const spouseCoverage = this._session.data.spouse.spouseBenefitAmount as number;
    const productId = this._session.data.product.id;

    if (productId === ProductId.TERM_LIFE_ADD) {
      return spouseCoverage >= 13000 && spouseCoverage <= 200000;
    }
    return true;
  }

  isConnecticut(): boolean {
    return this._session.data.demographic.state === STATE_CONNECTICUT;
  }

  toDtoArray(aggQuestions: Collection<ByAskedFormByFormTypeItem>): HealthQuestionDto[] {
    let result = new Array<HealthQuestionDto>();
    aggQuestions.each(askedForItem => {
      const agForm: AggregatedForms = {
        shortForm: askedForItem.shortForm,
        longForm: askedForItem.longForm,
        sohShortTriggers: askedForItem.sohShortTriggers,
        sohLongTriggers: askedForItem.sohLongTriggers,
        gi: askedForItem.gi,
        soh: askedForItem.soh
      };

      Object.values(agForm).forEach((form) => {
        form.toArray().forEach(question => {
          const value = question.type === HealthQuestionTypes.YES_NO ? YesNoOptions[question.value as YesNoOptions] : question.value;
          if (question.label === undefined) {
            return;
          }
          result.push({
            applicationId: this._session.data.applicationId,
            question: question.label as string,
            answer: value as string,
            answer_To: HealthQuestionAskedTo[askedForItem.lookupKey as number],
            triggersUWReferral: false,
            key: `${question.lookupKey}`,
          });
          if (question.children.length > 0) {
            question.children.each(child => {
              const childValue = child.type === HealthQuestionTypes.YES_NO ? YesNoOptions[child.value as YesNoOptions] : child.value
              if (child.label === undefined) {
                return;
              }
              result.push({
                applicationId: this._session.data.applicationId,
                question: child.label as string,
                answer: childValue as string,
                answer_To: HealthQuestionAskedTo[askedForItem.lookupKey as number],
                triggersUWReferral: false,
                key: `${child.lookupKey}`,
              });
            });
          }
        });

      })
    })
    return result;
  }

  fromSession() {
    return this.fromDtoArray(this._session.data.underwriting);
  }

  fromDtoArray(arr: HealthQuestionDto[]): Collection<ByAskedFormByFormTypeItem> {

    const isLtdDisability = this._session.data.product.id === ProductId.LTD_DISABILITY;
    let result = AggregatedQuestionsHelper.aggregatedTermLifeQuestions();
    if (isLtdDisability) {
      const {height, weight, state} = this._session.data.demographic;
      const isOneYear = SessionHelper.membershipWithinOneYear(this._session);
      result = AggregatedQuestionsHelper.aggregatedLtdQuestions(this.isBmiValid(height as number, weight as number), isOneYear, state, SessionHelper.hasMemberFailedUw(this._session));
    }
    arr.forEach(i => {
      const askedTo = HEALTH_QUESTION_ASKED_MAP.get(i.answer_To.toUpperCase()).value;
      const askedToQuestions = result.get(askedTo);
      const {shortForm, longForm, sohShortTriggers, sohLongTriggers, gi, soh} = askedToQuestions;
      const answer = i.answer === 'undefined' ? "" : i.answer;
      [shortForm, longForm, sohShortTriggers, sohLongTriggers, gi, soh].forEach(q => {

        if (q.has(i.key)) {
          let value = answer;
          if (i.key === 'height') {
            if(this._session.data.product.id === ProductId.LTD_DISABILITY) {
              value = `${this._session.data.demographic.height ?? answer}`;
            }
            else{
              value = answer;
            }

            if (askedTo === HealthQuestionAskedTo.SPOUSE) {
              const ans = SessionHelper.wantsSpouse(this._session) ? SessionHelper.spouseHeight(this._session) ?? answer : answer;
              value = `${ans}`;
            }
          }
          if (i.key === 'weight') {
            if(this._session.data.product.id === ProductId.LTD_DISABILITY) {
              value = `${this._session.data.demographic.weight ?? answer}`;
            }
            else{
              value = answer;
            }
            if (askedTo === HealthQuestionAskedTo.SPOUSE) {
              const ans = SessionHelper.wantsSpouse(this._session) ? SessionHelper.spouseWeight(this._session) ?? answer : answer;
              value = `${ans}`;
            }

          }
          q.put({...q.get(i.key), value: value});
        } else {
          q.each(question => {
            if (question.children.has(i.key)) {
              question.children.put({...question.children.get(i.key), value: answer});
            }
          })
        }
      })
    });
    return result;
  }

  saveToSession(aggQuestions: Collection<ByAskedFormByFormTypeItem>) {
    this._session.data.underwriting = this.toDtoArray(aggQuestions);
  }

  isEntityFavorable(q: ByAskedFormByFormTypeItem){
    let shortForm = q.shortForm;
    let result = shortForm.filter((item=> item.value === "Yes")).length === 0 &&
      q.sohShortTriggers.filter((item=> item.value === "Yes")).length === 0;



    if(!result){
      return false;
    }
    const entityHasHeightAndWeight = shortForm.has('height') && shortForm.has('weight');
    if(!entityHasHeightAndWeight){
      return result;
    }

    const height = shortForm.get('height').value;
    const weight = shortForm.get('weight').value;
    if(!height || !weight || height === "undefined" || weight === "undefined"){
      return true; // We already know result wasn't false.
    }
    return this.isBmiValid(height as number, weight as number);
  }


  isMemberAnswersFavorable(){
    const q = this.fromSession();
    const memberQuestions = q.get(HealthQuestionAskedTo.MEMBER);
    return this.isEntityFavorable(memberQuestions);
  }

  isSpouseAnswersFavorable(){
    if(this._session.data.product.id === ProductId.LTD_DISABILITY){
      return true;
    }
    const q = this.fromSession();
    const spouseQuestions = q.get(HealthQuestionAskedTo.SPOUSE);
    return this.isEntityFavorable(spouseQuestions);
  }

}

export function areFavorable(questions: Collection<HealthQuestionProps&HealthQuestionState>, uwHelper: UnderwritingHelper) {
  let isFavorable = true;
  questions.each(i => isFavorable = isFavorable && i.isFavorable)
  const logger = new ConsoleLogger("underwriting.ts@areFavorable");
  //BMI Check
  if (isFavorable && (questions.has('height') && questions.has('weight'))) {
    const height = questions.get('height').value;
    const weight = questions.get('weight').value;

    if (height === undefined || weight === undefined) {
      isFavorable = isFavorable && true;
    } else {
      isFavorable = isFavorable && uwHelper.isBmiValid(height as number, weight as number);
    }
  }
  logger.debug("Favorability check", isFavorable, questions.toArray().map(x => x.lookupKey));
  return isFavorable;
}