import {LogInFailedProps, LogInState, LogInSuccessProps} from "../../components/Forms/LogIn";
import {ApiClient, JwtApiClient, RegistrationData, ResetPasswordData} from "../api/api";

export interface AuthenticationMethod extends LogInState, LogInSuccessProps, LogInFailedProps {

    authenticate(credentials: Array<string>): Promise<boolean>;
    register(details: RegistrationData): Promise<boolean>;
    resetPassword(details: ResetPasswordData): Promise<boolean>;
    checktoken(token: string): Promise<boolean>;
    forgotpassword(email: string): Promise<boolean>;
}


export class DirectLogInAuthentication implements AuthenticationMethod {

    isAttempted: boolean = false;
    isFailed: boolean = false;
    isSuccessful: boolean = false;
    api: ApiClient = new JwtApiClient();
    async authenticate(credentials: Array<string>): Promise<boolean> {
        let [username, password] = credentials;
        let result = await this.api.authenticateCredentials(username, password);
        return this.setLogInStatus(result);
    }

    async register(details: RegistrationData): Promise<boolean> {
        let result = await this.api.register(details);
        return this.setRegistrationStatus(result);
    }

    async resetPassword(details: ResetPasswordData): Promise<boolean> {
        let result = await this.api.resetPassword(details);
        return result;
    }

    async checktoken(token: string): Promise<boolean> {
        let result = await this.api.checktoken(token);
        return result;
    }

    async forgotpassword(email: string): Promise<boolean> {
        let result = await this.api.forgotpassword(email);
        return result;
    }

    setLogInStatus(status: boolean): boolean {
       this.isAttempted = true;
       this.isFailed = !status;
       this.isSuccessful = status;
       return status;
    }

    setRegistrationStatus(status:boolean): boolean{
        this.isSuccessful= status;
        this.isAttempted = true;
        this.isFailed = !status;
        return status;
    }


    onLogInFailed(): void {

    }

    onLoginSuccess(): void {

    }

}