import {InputEvent} from "../types/events";
import ConsoleLogger from "../services/logging/logger";
import {FormValidator} from "./validator";


const logger = new ConsoleLogger("Form");
export default class Listeners {
  static doNothing = () => {

  };

  /**
   * Returns a listener closure that updates the given state when fired.
   * @param e
   * @param state
   * @param setState
   */
  static updateStateFromInputEvent = (state: any, setState: (prev: typeof state) => void) => {
    return (e: InputEvent) => {
      const {name, value} = e.target;
      setState(
        (prev: typeof state) => {
          setState(({...prev, [name]: value}));
        });
    }
  }

  static submitForm(formId: string) {
    return () => {
      const form = document.getElementById(formId) as HTMLFormElement;
      form.requestSubmit();
    }
}

static validateForm(formId: string, validator: FormValidator){
    const form = document.getElementById(formId) as HTMLFormElement;
    validator.validate(form);
}




}