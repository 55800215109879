import React, {useContext, useState} from 'react';
import {Route, Routes, BrowserRouter as Router} from 'react-router-dom';
import logo from './logo.svg';
import './App.css';
import AppRoutes from './AppRoutes';
import SessionContext, {
  SessionContextProvider,
  SessionData,
  initialSession,
  SessionState
} from './services/session/session';
import {SerializedProduct} from "./services/api/api";
import ServerSideValidationAlert from "./services/session/alerts";
import { ScreenCardSavingContext } from './services/api/saving';

const API_ERR_MSG = "An error has occurred saving your information. Please try again.";
function App() {
  const [saving, setSaving] = useState({saving: false, showError:false, errorMessage: API_ERR_MSG});
  const setProduct = (product: SerializedProduct) => {
    setSession(p => {
      let n = {...p};
      n.data.product = product;
      return n;
    });
  }

  const refresh = () =>{
    setSession(prev => ({...prev}));
  }
  const [session, setSession] = useState({
    data: initialSession,
    setProduct,
    refresh,
  });
  const ssvAlert = useContext(ServerSideValidationAlert);

  return (
    <SessionContext.Provider value={session}>
      <ServerSideValidationAlert.Provider value={ssvAlert}>
        <ScreenCardSavingContext.Provider value={{saving, setSaving}}>
          <Router>
            <Routes>{AppRoutes.map((route, index) => {
              const {element, ...rest} = route;
              return <Route key={index} {...rest} element={element}/>;
            })}
            </Routes>
          </Router>
        </ScreenCardSavingContext.Provider>
      </ServerSideValidationAlert.Provider>

    </SessionContext.Provider>
  );
}

export default App;