import {Page} from "../types/page";
import Product, {ProductId} from "../types/product";
import {ApplicationLayout} from "../Layouts";
import {useContext, useEffect, useState} from "react";
import SessionContext, {SessionContextProvider, SessionHelper} from "../services/session/session";
import SummaryPage from "../components/Pages/SummaryPage";
import {JwtApiClient} from "../services/api/api";
import DependentCoverageOptionsPage from "../components/Pages/DependentCoverageOptionsPage";
import PaymentPage from "../components/Pages/PaymentPage";
import ConfirmationPage, {ConfApplicationDownload} from "../components/Pages/ConfirmationPage";
import ProductsStub from "../stub/Products";
import OptInsPage from "../components/Pages/OptInsPage";
import DeclarationsAndSignaturesPage from "../components/Pages/DeclarationsAndSignaturesPage";
import BeneficiariesPage from "../components/Pages/BeneficiariesPage";
import Select from "../components/Forms/Inputs/Select";
import Listeners from "../helpers/listeners";
import FormWithValidation from "../components/Forms/FormWithValidation";
import {HtmlFormValidator} from "../helpers/validator";
import {Button, Col, Container, Row} from "react-bootstrap";
import DemographicsPage from "../components/Pages/DemographicsPage";
import LtdDisabilityMemberCoverageOptions from "../components/Pages/LtdDisabilityMemberCoverageOptions";
import UnderwritingPage from "../components/Pages/UnderwritingPage";
import AuthorizationsPage from "../components/Pages/AuthorizationsPage";
import Prescription from "../components/Pages/Prescription";
import {UnderwritingHelper} from "../helpers/underwriting";
import SpouseCoverageOptionsPage from "../components/Pages/SpouseCoverageOptionsPage";
import ScreenCard from "../components/Cards/ScreenCard";


type TestPageProps = {};
const TestPage = (props: TestPageProps) => {
    const client = new JwtApiClient();
    const [state, setState] = useState(useContext(SessionContext));
    const session = state;
    session.data.product = ProductsStub["5k"];
    // session.data.demographic.basicAnnualEarnings = 500;
    session.data.coverages.benefitAmount = 10000;
    session.data.coverages.isADDEnrolled = true;
    // session.data.coverages.eleminationDays = 90;

    client.authenticateCredentials('test@testington.com', 'Testing@123');

    // session.data.spouse.isSpouseEnrolled = true;
    // session.data.spouse.spouseBenefitAmount = 13000;
    // session.data.spouse.height = 69;
    // session.data.spouse.weight = 180;
    session.data.applicationId = 349;


    session.data.demographic.aftMembershipDate = '2023-03-29';
    session.data.demographic.height = 69;
    session.data.demographic.weight = 400;
    session.data.uwReferral.member.shownLongForm = true;




    const confirmationProps = {
        memberName: "Test T. Testington",
        productDisplayName: session.data.product.displayName,
    }


    const product: Product = {
        name: "Test",
        showHeight: true,
        showWeight: true,
        showBasicAnnualEarnings: true,
        path: ""
    }
    const pageProps: Page = {
        showNext: true,
        showPrevious: true,
        showSubmit: true,
        onNext: () => {
        },
        onPrevious: () => {
        },
        onSubmit: () => {
        },
        product: product,
        progressButtons: [],
        progressPercent: 0
    }


    // useEffect(() => {
    //     client.authenticateCredentials('test@testington.com', 'Testing@123').then(() => {
    //         client.fetchApplication('term-life-add').then(app => {
    //             SessionHelper.setFromApiResponse(session)(app);
    //             session.data.demographic.aftMembershipDate = new Date(Date.now()).toLocaleString();
    //             session.data.demographic.height = 69;
    //             session.data.demographic.weight = 180;
    //             setState(session);
    //         });
    //     });
    // }, []);
    const colStyle = {overflow: "scroll"};
    const uwHelper = new UnderwritingHelper(state);

    session.data.pdmSuccess=true;

    // const client = new JwtApiClient();

return (
  // <OptInsPage {...pageProps}/>
<ConfirmationPage />
);
}
export default TestPage;
