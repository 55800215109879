import {ApiValidationError} from "../api/api";
import React from "react";

export type ServerSideValidationAlert = {
  showAlert: boolean,
  errors: ApiValidationError
};
export const initAlert: ServerSideValidationAlert = {
  showAlert: false,
  errors: {},
};

class ValidationAlert{
  set alert(value: ServerSideValidationAlert) {
    this._alert = value;
  }
  get alert(): ServerSideValidationAlert {
    return this._alert;
  }
  private _alert: ServerSideValidationAlert;

  constructor(alert: ServerSideValidationAlert) {
    this._alert = alert;
  }

}


const alert = React.createContext(new ValidationAlert(initAlert));

export const ServerSideValidationAlertContextProvider = alert.Provider;
export default alert;