import {Component} from "react";

type AftLogoProps = {
    className: string
}
const AftLogo = (props: AftLogoProps) => {
    return (
        <img className={props.className} src="/img/logos/aft.svg" alt="AFT Benefits" />
    )
};
AftLogo.defaultProps = {
    className: "logoImg"
};

export default AftLogo;