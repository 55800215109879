import React, {useContext} from "react";
import {lazy, useState} from "react";
import Product from "../../types/product";
import ProgressButton, {ProgressButtonProps} from "../Buttons/ProgressButton";
import {useNavigate, useParams} from "react-router-dom";
import {ApplicationLayout} from "../../Layouts";
import ApplyPage from "../Pages/ApplyPage";
import {SerializedProduct} from "../../services/api/api";
import SessionContext from "../../services/session/session";
import {Page} from "../../types/page";

type EnrollmentSequenceProps = {
  sponsor: string,
}

export type EnrollmentSequenceItem = {
  previous?: EnrollmentSequenceItem;
  next?: EnrollmentSequenceItem;
  isHead(): boolean;
  isTail(): boolean;
  component: Component;
}

class EnrollmentScreen implements EnrollmentSequenceItem {
  previous?: EnrollmentSequenceItem;
  next?: EnrollmentSequenceItem;
  component: Component;

  constructor(component: Component, previous?: EnrollmentSequenceItem, next?: EnrollmentSequenceItem) {
    this.component = component;
    this.next = next;
    this.previous = previous;
  }

  isHead(): boolean {
    return this.previous == null;
  }

  isTail(): boolean {
    return this.next == null;
  }
}

type Component = {
  name: string;
  display: string;
}


class EnrollmentApplication {

  product: SerializedProduct;
  sequence: EnrollmentSequenceItem;
  sponsor: string;

  constructor(sponsor: string, product: SerializedProduct) {
    this.sponsor = sponsor;
    this.product = product;
    this.sequence = this.deserialize();
  }

  private deserialize(): EnrollmentSequenceItem {
    let item: EnrollmentScreen = new EnrollmentScreen(this.product.componentNames[0]);
    for (let i = 0; i < this.product.componentNames.length; i++) {
      let next = i == this.product.componentNames.length - 1 ? null : new EnrollmentScreen(this.product.componentNames[i + 1]);
      item.next = next as EnrollmentSequenceItem;
      if (next != null) {
        item.next.previous = item;
        item = item.next;
      }
    }
    while (!item.isHead()) {
      // @ts-ignore isHead() checks for null previous.
      item = item.previous;
    }
    return item;
  }


}

const testProduct: Product = {
  showBasicAnnualEarnings: true,
  showHeight: true,
  showWeight: true,
  name: "Term Life AD&D",
  path: '/term-life-add'
}

const applyProps = {
  product: {
    name: 'Term Life AD&D',
    showHeight: false,
    showWeight: false,
    showBasicAnnualEarnings: false,
    path: '/term-life-add'
  }
};


export function EnrollmentSequence(props: EnrollmentSequenceProps) {
  /** @var currentItem EnrollmentSequenceItem **/
  const session = useContext(SessionContext);
  const {productKey} = useParams();
  let app = new EnrollmentApplication(props.sponsor, session.data.product);
  const [currentItem, setCurrentItem] = useState(app.sequence);
  let head = currentItem;
  while (!head.isHead()) {
    // @ts-ignore
    head = head.previous;
  }
  const handleProgressClick = (componentName: string) => {
    if (currentItem.component.name === componentName) {
      return;
    }

    // We only need to seek backwards because you can only click
    // backwards.
    let c = currentItem.previous;
    // @ts-ignore Mitigated by not being able to click before or on tail.
    while (c.component.name !== componentName) {
      // @ts-ignore Mitigated by not being able to click before or on tail.
      c = c.previous;
    }
    setCurrentItem(c as EnrollmentSequenceItem);
  }
  const pButtons = [];
  let hitCurrent = false;
  let selected = true;
  while (!head.isTail()) {
    if (!hitCurrent) {
      hitCurrent = currentItem.component.name === head.component.name;
    }

    pButtons.push(<ProgressButton onClick={handleProgressClick} selected={selected}
                                  controlId={`p-button-${head.component.name}`} sequenceItem={head}/>);
    if (hitCurrent) {
      selected = false;
    }
    // @ts-ignore
    head = head.next;
  }
  if (!selected && currentItem.component.name === head.component.name) {
    selected = true;
  }
  let pButtonProps = {
    selected: selected,
    onClick: handleProgressClick,
    controlId: `p-button-${head.component.name}`,
    sequenceItem: head
  }
  pButtons.push(<ProgressButton {...pButtonProps}/>);

  const navigate = useNavigate();

  const handleNext = () => {
    let next = currentItem.next;
    if (next === null) {
      return;
    }
    // @ts-ignore NULL check already performed.
    setCurrentItem(next);
  };
  const handlePrevious = () => {
    let prev = currentItem.previous;
    if (prev === null) {
      return;
    }
    // @ts-ignore NULL check already performed.
    setCurrentItem(prev);
  }
  const handleSubmit = () => {
    navigate('/confirmation');
  }
  const DynamicComponent = lazy(() => import(`../Pages/${currentItem.component.name}`));


  let dynamicProps = {
    progressButtons: pButtons,
    showNext: !currentItem.isTail(),
    showPrevious: !currentItem.isHead(),
    showSubmit: currentItem.isTail(),
    onNext: handleNext,
    onPrevious: handlePrevious,
    onSubmit: handleSubmit,
    product: testProduct
  };


  return (
    <React.Suspense>
      <ApplicationLayout productName={session.data.product.displayName} progressButtons={pButtons}>
        <DynamicComponent {...dynamicProps}/>
      </ApplicationLayout>
    </React.Suspense>
  )
}

