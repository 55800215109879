type SectProps = {
  title: string,
  children: any,
}
const Section = (props: SectProps) => {
  return (
    <>
      <h2>{props.title}</h2>
      {props.children}
    </>
  )
}
const EmailLink = () => {
  return <a href="mailto:GSP.Sarasota.UnionPlus@ajg.com">GSP.Sarasota.UnionPlus@ajg.com</a>;
}
type AgreementToDoBusinessProps = {};
const AgreementToDoBusiness = (props: AgreementToDoBusinessProps) => {
  return (
    <div className="container mt-5">
      <h1>AGREEMENT TO DO BUSINESS WITH GALLAGHER</h1>
      <p className="help-block">(Electronic Consent to Electronic Receipt of Electronic Record and Signature Disclosures)</p>
      <Section title="ELECTRONIC RECORD AND SIGNATURE DISCLOSURE">
        <p>From time to time, Gallagher (we, us or Gallagher) may be required by law to provide to you certain written notices or disclosures.  Described below are the terms and conditions for providing to you such notices and disclosures electronically through the website where this document is linked.  Please read the information below carefully and thoroughly, and if you can access this information electronically to your satisfaction and agree to these terms and conditions, please confirm your agreement by selecting the “Opt-In for eDelivery of Policy Documents” and/or the “Go Paperless for My Billing Invoices” radio buttons from the link provided on your Member Service Portal Account page.</p>
      </Section>
      <Section title="Getting paper copies">
        <p>At any time, you may request from us a paper copy of any record provided or made available electronically to you by us.  You may request delivery of such paper copies from us by following the procedure described below.</p>
      </Section>
      <Section title="Withdrawing your consent">
        <p>You may at any time withdraw your consent to receive notices and disclosures from us electronically, and request  that  thereafter  you  want  to  receive  required  notices  and  disclosures only in paper format by providing notice as described below.</p>
      </Section>
      <Section title="Consequences of changing your mind">
        <p>It will take significantly longer for us to deliver notices and disclosures in paper format.  However, if you still desire  to  withdraw  your  consent,  you  must  do  so  by  emailing  Gallagher  at <EmailLink /> .  This will indicate to us that you have withdrawn your consent to receive required notices and disclosures electronically from us.</p>
      </Section>
      <Section title="All notices and disclosures will be sent to you electronically">
        <p>Unless you inform us of your intent to withdraw consent per the email process specified above, we will provide electronically  to  you  by  email  or  through  the  website  all  required  notices,  disclosures,  authorizations, acknowledgements and other documents that are required to be provided or made available to you during the course of our relationship with you.  To reduce the chance of you inadvertently not receiving any notice or disclosure, we prefer to provide all of the required notices and disclosures to you by the same method and to the same address that you have given us.   Thus, you can receive all the disclosures and notices electronically  or  in  paper  format  through  the  paper  mail  delivery  system.   If  you  do  not  agree  with  this process, please let us know as described below.</p>
      </Section>
      <Section title="How to contact Gallagher">
        <p>You may contact us to let us know of your changes as to (i) how we may contact you electronically (including any changes in your email address where we should send notices and disclosures electronically to you), (ii) to request paper copies of certain information from us, and (iii) to withdraw your prior consent to receive notices and disclosures electronically as follows:</p>
        <p className="text-center">To contact us by email, please send messages to: <EmailLink /></p>
        <p>For  paper  copies  of  documents,  please  provide  the  following  information  in  any  email  communications: email address, full name, US Postal address, and telephone number.</p>
      </Section>
      <Section title="Acknowledging your access and consent to receive materials electronically">
        <p>To confirm to us that you can access this information electronically, which will be similar to other electronic notices and disclosures that we will provide to you, please verify that you were able to read this electronic disclosure  and  that  you  also  were  able  to  print  on  paper  or  electronically  save  this  page  for  your  future reference and access or that you were able to email this disclosure and consent to an address where you will be able to print on paper or save it for your future reference and access.   Further, if you consent to receiving  notices  and  disclosures  exclusively  in  electronic  format  on  the  terms  and  conditions  described above, please confirm by selecting the “Opt-In for eDelivery of Policy Documents” and/or the “Go Paperless for My Billing Invoices” radio buttons from the link provided on your Member Service Portal Account page.</p>
        <p>By opting-in to eDelivery and Going Paperless, I confirm that:</p>
        <ul>
          <li>I  can  access  and  read  this  AGREEMENT  TO  DO  BUSINESS  WITH  GALLAGHER  (Electronic Consent to Electronic Receipt of Electronic Record and Signature Disclosures) document; and</li>
          <li>I can print on paper the disclosure or save or send the disclosure to a place where I can print it, for future reference and access; and</li>
          <li>Unless and until I notify Gallagher as described above, I consent to receive all notices, disclosures, authorizations, acknowledgements and other documents that are required to be provided or made available  to  me  by  Gallagher  exclusively  through  electronic  means  during  the  course  of  my relationship with you.</li>
        </ul>
      </Section>


    </div>
  );
}


export default AgreementToDoBusiness;