/**
 * Term Life Health Questions
 * @see ./LtdHealthQuestions.ts for LTD Health Question Stubs
 */
import {HealthQuestionProps} from "../components/Forms/HealthQuestions/HealthQuestion";
import {Collection} from "../helpers/collections";
import {
  ByAskedFormByFormTypeItem,
  HealthQuestionAskedTo,
  HealthQuestionFormType,
  HealthQuestionTypes
} from "../models/health-questions";
import {YesNoOptions} from "../components/Forms/Inputs/YesNoSwitch";
import {ProductId} from "../types/product";
import ConsoleLogger from "../services/logging/logger";
import {
  formMembershipOutsideOneYear,
  formMembershipWithinOneYear,
  LTD_HOSPITALIZATION_QUESTION, LTD_HOSPITALIZATION_QUESTION_WITH_SOH
} from "./LtdHealthQuestions";

const DEFINITION_DEFAULT = null;
const BOTH_MEMBER_AND_SPOUSE = HealthQuestionAskedTo.MEMBER | HealthQuestionAskedTo.SPOUSE;
const NO_CHOICES: Array<string> = [];

const inPlaceSOHQuestions = (parentLookupKey: string, productId: ProductId, askedTo: HealthQuestionAskedTo): Collection<HealthQuestionProps> => {
  return new Collection<HealthQuestionProps>([
    {
      lookupKey: `${parentLookupKey}_condition`,
      type: HealthQuestionTypes.TEXT,
      label: "Condition / Diagnosis",
      value: undefined,
      askedTo: askedTo,
      product: productId,
      definition: DEFINITION_DEFAULT,
      formType: HealthQuestionFormType.LONG,
      children: emptyChildren,
      choices: NO_CHOICES
    },
    {
      lookupKey: `${parentLookupKey}_prescriptions`,
      type: HealthQuestionTypes.TEXT,
      label: "Please list any medication prescribed that you did not already identify in the Prescription Information above.",
      value: undefined,
      askedTo: askedTo,
      product: productId,
      definition: DEFINITION_DEFAULT,
      formType: HealthQuestionFormType.LONG,
      children: emptyChildren,
      choices: NO_CHOICES
    },
    {
      lookupKey: `${parentLookupKey}_date-of-diagnosis`,
      type: HealthQuestionTypes.MONTH,
      label: "Date of diagnosis (Month/Year)",
      value: undefined,
      askedTo: askedTo,
      product: productId,
      definition: DEFINITION_DEFAULT,
      formType: HealthQuestionFormType.LONG,
      children: emptyChildren,
      choices: NO_CHOICES
    },
    {
      lookupKey: `${parentLookupKey}_date-of-last-treatment`,
      type: HealthQuestionTypes.MONTH,
      label: "Date of last treatment (Month/Year)",
      value: undefined,
      askedTo: askedTo,
      product: productId,
      definition: DEFINITION_DEFAULT,
      formType: HealthQuestionFormType.LONG,
      children: emptyChildren,
      choices: NO_CHOICES
    },
    {
      lookupKey: `${parentLookupKey}_type-of-treatment`,
      type: HealthQuestionTypes.TEXT,
      label: "Type of treatment",
      value: undefined,
      askedTo: askedTo,
      product: productId,
      definition: DEFINITION_DEFAULT,
      formType: HealthQuestionFormType.LONG,
      children: emptyChildren,
      choices: NO_CHOICES
    },
    {
      lookupKey: `${parentLookupKey}_physicians-name`,
      type: HealthQuestionTypes.TEXT,
      label: "Physician's Name",
      value: undefined,
      askedTo: askedTo,
      product: productId,
      definition: DEFINITION_DEFAULT,
      formType: HealthQuestionFormType.LONG,
      children: emptyChildren,
      choices: NO_CHOICES
    },
    {
      lookupKey: `${parentLookupKey}_date-last-physician-visit`,
      type: HealthQuestionTypes.DATE_PAST,
      label: "Date of last visit",
      value: undefined,
      askedTo: askedTo,
      product: productId,
      definition: DEFINITION_DEFAULT,
      formType: HealthQuestionFormType.LONG,
      children: emptyChildren,
      choices: NO_CHOICES
    },
    {
      lookupKey: `${parentLookupKey}_reason-for-visit`,
      type: HealthQuestionTypes.TEXT,
      label: "Reason for visit",
      value: undefined,
      askedTo: askedTo,
      product: productId,
      definition: DEFINITION_DEFAULT,
      formType: HealthQuestionFormType.LONG,
      children: emptyChildren,
      choices: NO_CHOICES
    },
    {
      lookupKey: `${parentLookupKey}_physician-street-address`,
      type: HealthQuestionTypes.TEXT,
      label: "Physician Street Address",
      value: undefined,
      askedTo: askedTo,
      product: productId,
      definition: DEFINITION_DEFAULT,
      formType: HealthQuestionFormType.LONG,
      children: emptyChildren,
      choices: NO_CHOICES
    },
    {
      lookupKey: `${parentLookupKey}_physician-city`,
      type: HealthQuestionTypes.TEXT,
      label: "City",
      value: undefined,
      askedTo: askedTo,
      product: productId,
      definition: DEFINITION_DEFAULT,
      formType: HealthQuestionFormType.LONG,
      children: emptyChildren,
      choices: NO_CHOICES
    },
    {
      lookupKey: `${parentLookupKey}_physician-state`,
      type: HealthQuestionTypes.STATE,
      label: "State",
      value: undefined,
      askedTo: askedTo,
      product: productId,
      definition: DEFINITION_DEFAULT,
      formType: HealthQuestionFormType.LONG,
      children: emptyChildren,
      choices: NO_CHOICES
    },
    {
      lookupKey: `${parentLookupKey}_physician-zip-code`,
      type: HealthQuestionTypes.ZIP,
      label: "Zip Code",
      value: undefined,
      askedTo: askedTo,
      product: productId,
      definition: DEFINITION_DEFAULT,
      formType: HealthQuestionFormType.LONG,
      children: emptyChildren,
      choices: NO_CHOICES
    },
    {
      lookupKey: `${parentLookupKey}_physician-phone`,
      type: HealthQuestionTypes.PHONE,
      label: "Phone number",
      value: undefined,
      askedTo: askedTo,
      product: productId,
      definition: DEFINITION_DEFAULT,
      formType: HealthQuestionFormType.LONG,
      children: emptyChildren,
      choices: NO_CHOICES
    },
  ]);
}

const sohShortTriggers = (productId: ProductId, askedTo: HealthQuestionAskedTo) => {
  if(productId === ProductId.TERM_LIFE_ADD || productId === ProductId.GI_TERM_LIFE){
    return new Collection<HealthQuestionProps>([
      {
            lookupKey: 'diagnosed-with-cardiovascular-disorder' + (askedTo == HealthQuestionAskedTo.MEMBER ? "_member" : "_spouse"),
        type: HealthQuestionTypes.YES_NO,
        label: "Have you ever been diagnosed, treated or given medical advice by a physician or other health care provider for cardiac or cardiovascular disorder?",
        value: YesNoOptions.NotSelected,
        askedTo: askedTo,
        product: productId,
        definition: DEFINITION_DEFAULT,
        formType: HealthQuestionFormType.SOH_SHORT_TRIGGER,
        children: inPlaceSOHQuestions('diagnosed-with-cardiovascular-disorder', productId, askedTo, ),
        choices: NO_CHOICES,
      },
      {
          lookupKey: 'diagnosed-with-circulatory-disorder' + (askedTo == HealthQuestionAskedTo.MEMBER ? "_member" : "_spouse"),
        type: HealthQuestionTypes.YES_NO,
        label: "Have you ever been diagnosed, treated or given medical advice by a physician or other health care provider for stroke or circulatory disorder?",
        value: YesNoOptions.NotSelected,
        askedTo: askedTo,
        product: productId,
        definition: DEFINITION_DEFAULT,
        formType: HealthQuestionFormType.SOH_SHORT_TRIGGER,
        children: inPlaceSOHQuestions('diagnosed-with-circulatory-disorder', productId, askedTo, ),
        choices: NO_CHOICES,
      },
      {
          lookupKey: 'diagnosed-with-high-blood-pressure' + (askedTo == HealthQuestionAskedTo.MEMBER ? "_member" : "_spouse"),
        type: HealthQuestionTypes.YES_NO,
        label: "Have you ever been diagnosed, treated or given medical advice by a physician or other health care provider for high blood pressure?",
        value: YesNoOptions.NotSelected,
        askedTo: askedTo,
        product: productId,
        definition: DEFINITION_DEFAULT,
        formType: HealthQuestionFormType.SOH_SHORT_TRIGGER,
        children: inPlaceSOHQuestions('diagnosed-with-high-blood-pressure', productId, askedTo, ),
        choices: NO_CHOICES,
      },
      {
          lookupKey: 'diagnosed-with-cancer' + (askedTo == HealthQuestionAskedTo.MEMBER ? "_member" : "_spouse"),
        type: HealthQuestionTypes.YES_NO,
        label: "Have you ever been diagnosed, treated or given medical advice by a physician or other health care provider for cancer, Hodgkins disease, lymphoma or tumors?",
        value: YesNoOptions.NotSelected,
        askedTo: askedTo,
        product: productId,
        definition: DEFINITION_DEFAULT,
        formType: HealthQuestionFormType.SOH_SHORT_TRIGGER,
        children: inPlaceSOHQuestions('diagnosed-with-cancer', productId, askedTo, ),
        choices: NO_CHOICES,
      },
      {
          lookupKey: 'diagnosed-with-diabetes' + (askedTo == HealthQuestionAskedTo.MEMBER ? "_member" : "_spouse"),
        type: HealthQuestionTypes.YES_NO,
        label: "Have you ever been diagnosed, treated or given medical advice by a physician or other health care provider for diabetes?",
        value: YesNoOptions.NotSelected,
        askedTo: askedTo,
        product: productId,
        definition: DEFINITION_DEFAULT,
        formType: HealthQuestionFormType.SOH_SHORT_TRIGGER,
        children: new Collection<HealthQuestionProps>([
          {
                lookupKey: "diagnosed-with-diabetes_age" + (askedTo == HealthQuestionAskedTo.MEMBER ? "_member" : "_spouse"),
            type: HealthQuestionTypes.NUMBER,
            label: "Age at diagnosis",
            value: undefined,
            askedTo: askedTo,
            product: productId,
            definition: "",
            formType: HealthQuestionFormType.LONG,
            children: emptyChildren,
            choices: NO_CHOICES,
          },
          {
              lookupKey: "diagnosed-with-diabetes_insulin-treated" + (askedTo == HealthQuestionAskedTo.MEMBER ? "_member" : "_spouse"),
            type: HealthQuestionTypes.YES_NO,
            label: "Insulin treated?",
            value: YesNoOptions.NotSelected,
            askedTo: askedTo,
            product: productId,
            definition: "",
            formType: HealthQuestionFormType.LONG,
            children: emptyChildren,
            choices: NO_CHOICES,
          },
          ...inPlaceSOHQuestions('diagnosed-with-diabetes', productId, askedTo ).toArray()
        ]),
        choices: NO_CHOICES,

      },
    ]);
  }
  return new Collection<HealthQuestionProps>([])
}

const sohLongTriggers = (productId: ProductId, askedTo: HealthQuestionAskedTo) => {
  if(productId === ProductId.TERM_LIFE_ADD){
    return new Collection<HealthQuestionProps>([
      {
        lookupKey: 'diagnosed-with-blood-disorder' + (askedTo == HealthQuestionAskedTo.MEMBER?"_member":"_spouse"),
        type: HealthQuestionTypes.YES_NO,
        label: "Have you ever been diagnosed, treated or given medical advice by a physician or other health care provider for anemia, leukemia or other blood disorder?",
        value: YesNoOptions.NotSelected,
        askedTo: askedTo,
        product: productId,
        definition: DEFINITION_DEFAULT,
        formType: HealthQuestionFormType.SOH_LONG_TRIGGER,
        children: inPlaceSOHQuestions('diagnosed-with-blood-disorder', productId, askedTo, ),
        choices: NO_CHOICES,
      },

      {
        lookupKey: 'diagnosed-with-lung-disease' + (askedTo == HealthQuestionAskedTo.MEMBER ? "_member" : "_spouse"),
        type: HealthQuestionTypes.YES_NO,
        label: "Have you ever been diagnosed, treated or given medical advice by a physician or other health care provider for asthma, COPD, emphysema or other lung disease?",
        value: YesNoOptions.NotSelected,
        askedTo: askedTo,
        product: productId,
        definition: DEFINITION_DEFAULT,
        formType: HealthQuestionFormType.SOH_LONG_TRIGGER,
        children: inPlaceSOHQuestions('diagnosed-with-lung-disease', productId, askedTo ),
        choices: NO_CHOICES,
      },
      {
        lookupKey: 'diagnosed-with-stomach-or-liver-disorder' + (askedTo == HealthQuestionAskedTo.MEMBER ? "_member" : "_spouse"),
        type: HealthQuestionTypes.YES_NO,
        label: "Have you ever been diagnosed, treated or given medical advice by a physician or other health care provider for ulcers, stomach, hepatitis or other liver disorder?",
        value: YesNoOptions.NotSelected,
        askedTo: askedTo,
        product: productId,
        definition: DEFINITION_DEFAULT,
        formType: HealthQuestionFormType.SOH_LONG_TRIGGER,
        children: inPlaceSOHQuestions('diagnosed-with-stomach-or-liver-disorder', productId, askedTo ),
        choices: NO_CHOICES,
      },
      {
        lookupKey: 'diagnosed-with-intestinal-disorder' + (askedTo == HealthQuestionAskedTo.MEMBER ? "_member" : "_spouse"),
        type: HealthQuestionTypes.YES_NO,
        label: "Have you ever been diagnosed, treated or given medical advice by a physician or other health care provider for colitis, Crohn’s, diverticulitis or other intestinal disorder?",
        value: YesNoOptions.NotSelected,
        askedTo: askedTo,
        product: productId,
        definition: DEFINITION_DEFAULT,
        formType: HealthQuestionFormType.SOH_LONG_TRIGGER,
        children: inPlaceSOHQuestions('diagnosed-with-intestinal-disorder', productId, askedTo ),
        choices: NO_CHOICES,
      },
      {
        lookupKey: 'diagnosed-with-memory-loss' + (askedTo == HealthQuestionAskedTo.MEMBER ? "_member" : "_spouse"),
        type: HealthQuestionTypes.YES_NO,
        label: "Have you ever been diagnosed, treated or given medical advice by a physician or other health care provider for memory loss?",
        value: YesNoOptions.NotSelected,
        askedTo: askedTo,
        product: productId,
        definition: DEFINITION_DEFAULT,
        formType: HealthQuestionFormType.SOH_LONG_TRIGGER,
        children: inPlaceSOHQuestions('diagnosed-with-memory-loss', productId, askedTo ),
        choices: NO_CHOICES,
      },
      {
        lookupKey: 'diagnosed-neurological-disorder' + (askedTo == HealthQuestionAskedTo.MEMBER ? "_member" : "_spouse"),
        type: HealthQuestionTypes.YES_NO,
        label: "Have you ever been diagnosed, treated or given medical advice by a physician or other health care provider for epilepsy, paralysis, seizures, dizziness or other neurological disorder?",
        value: YesNoOptions.NotSelected,
        askedTo: askedTo,
        product: productId,
        definition: DEFINITION_DEFAULT,
        formType: HealthQuestionFormType.SOH_LONG_TRIGGER,
        children: inPlaceSOHQuestions('diagnosed-neurological-disorder', productId, askedTo ),
        choices: NO_CHOICES,
      },
      {
        lookupKey: 'diagnosed-with-fatigue-syndromes' + (askedTo == HealthQuestionAskedTo.MEMBER ? "_member" : "_spouse"),
        type: HealthQuestionTypes.YES_NO,
        label: "Have you ever been diagnosed, treated or given medical advice by a physician or other health care provider for Epstein-Barr, chronic fatigue syndrome or fibromyalgia?",
        value: YesNoOptions.NotSelected,
        askedTo: askedTo,
        product: productId,
        definition: DEFINITION_DEFAULT,
        formType: HealthQuestionFormType.SOH_LONG_TRIGGER,
        children: inPlaceSOHQuestions('diagnosed-with-fatigue-syndromes', productId, askedTo ),
        choices: NO_CHOICES,
      },
      {
        lookupKey: 'diagnosed-with-muscular-dystrophy' + (askedTo == HealthQuestionAskedTo.MEMBER ? "_member" : "_spouse"),
        type: HealthQuestionTypes.YES_NO,
        label: "Have you ever been diagnosed, treated or given medical advice by a physician or other health care provider for multiple sclerosis, ALS or muscular dystrophy?",
        value: YesNoOptions.NotSelected,
        askedTo: askedTo,
        product: productId,
        definition: DEFINITION_DEFAULT,
        formType: HealthQuestionFormType.SOH_LONG_TRIGGER,
        children: inPlaceSOHQuestions('diagnosed-with-muscular-dystrophy', productId, askedTo ),
        choices: NO_CHOICES,
      },
      {
        lookupKey: 'diagnosed-with-auto-immune' + (askedTo == HealthQuestionAskedTo.MEMBER ? "_member" : "_spouse"),
        type: HealthQuestionTypes.YES_NO,
        label: "Have you ever been diagnosed, treated or given medical advice by a physician or other health care provider for lupus, scleroderma, auto immune disease or connective tissue disorder?",
        value: YesNoOptions.NotSelected,
        askedTo: askedTo,
        product: productId,
        definition: DEFINITION_DEFAULT,
        formType: HealthQuestionFormType.SOH_LONG_TRIGGER,
        children: inPlaceSOHQuestions('diagnosed-with-auto-immune', productId, askedTo ),
        choices: NO_CHOICES,
      },
      {
        lookupKey: 'diagnosed-with-arthritis' + (askedTo == HealthQuestionAskedTo.MEMBER ? "_member" : "_spouse"),
        type: HealthQuestionTypes.YES_NO,
        label: "Have you ever been diagnosed, treated or given medical advice by a physician or other health care provider for arthritis?",
        value: YesNoOptions.NotSelected,
        askedTo: askedTo,
        product: productId,
        definition: DEFINITION_DEFAULT,
        formType: HealthQuestionFormType.SOH_LONG_TRIGGER,
        children: new Collection<HealthQuestionProps>([
          {
            lookupKey: "diagnosed-with-arthritis_type" + (askedTo == HealthQuestionAskedTo.MEMBER ? "_member" : "_spouse"),
            type: HealthQuestionTypes.SELECT,
            label: "Type",
            value: undefined,
            askedTo: askedTo,
            product: productId,
            definition: "",
            formType: HealthQuestionFormType.LONG,
            children: emptyChildren,
            choices: [
              'Osteoarthritis',
              'Rheumatoid Arthritis',
              'Other'
            ],
          },
          ...inPlaceSOHQuestions('diagnosed-with-arthritis', productId, askedTo ).toArray()
        ]),
        choices: NO_CHOICES,
      },
      {
        lookupKey: 'diagnosed-with-musculoskeletal-disorder' + (askedTo == HealthQuestionAskedTo.MEMBER ? "_member" : "_spouse"),
        type: HealthQuestionTypes.YES_NO,
        label: "Have you ever been diagnosed, treated or given medical advice by a physician or other health care provider for back, neck, knee, spinal, joint or other musculoskeletal disorder?",
        value: YesNoOptions.NotSelected,
        askedTo: askedTo,
        product: productId,
        definition: DEFINITION_DEFAULT,
        formType: HealthQuestionFormType.SOH_LONG_TRIGGER,
        children: inPlaceSOHQuestions('diagnosed-with-musculoskeletal-disorder', productId, askedTo ),
        choices: NO_CHOICES,
      },

      {
        lookupKey: 'diagnosed-with-carpal-tunnel' + (askedTo == HealthQuestionAskedTo.MEMBER ? "_member" : "_spouse"),
        type: HealthQuestionTypes.YES_NO,
        label: "Have you ever been diagnosed, treated or given medical advice by a physician or other health care provider for carpal tunnel syndrome?",
        value: YesNoOptions.NotSelected,
        askedTo: askedTo,
        product: productId,
        definition: DEFINITION_DEFAULT,
        formType: HealthQuestionFormType.SOH_LONG_TRIGGER,
        children: inPlaceSOHQuestions('diagnosed-with-carpal-tunnel', productId, askedTo ),
        choices: NO_CHOICES,
      },
      {
        lookupKey: 'diagnosed-with-kidney-urinary-tract-prostate-disorder' + (askedTo == HealthQuestionAskedTo.MEMBER ? "_member" : "_spouse"),
        type: HealthQuestionTypes.YES_NO,
        label: "Have you ever been diagnosed, treated or given medical advice by a physician or other health care provider for kidney, urinary tract or prostate disorder?",
        value: YesNoOptions.NotSelected,
        askedTo: askedTo,
        product: productId,
        definition: DEFINITION_DEFAULT,
        formType: HealthQuestionFormType.SOH_LONG_TRIGGER,
        children: inPlaceSOHQuestions('diagnosed-with-kidney-urinary-tract-prostate-disorder', productId, askedTo ),
        choices: NO_CHOICES,
      },
      {
        lookupKey: 'diagnosed-with-gland-disorder' + (askedTo == HealthQuestionAskedTo.MEMBER ? "_member" : "_spouse"),
        type: HealthQuestionTypes.YES_NO,
        label: "Have you ever been diagnosed, treated or given medical advice by a physician or other health care provider for thyroid or other gland disorder?",
        value: YesNoOptions.NotSelected,
        askedTo: askedTo,
        product: productId,
        definition: DEFINITION_DEFAULT,
        formType: HealthQuestionFormType.SOH_LONG_TRIGGER,
        children: inPlaceSOHQuestions('diagnosed-with-gland-disorder', productId, askedTo ),
        choices: NO_CHOICES,
      },
      {
        lookupKey: 'diagnosed-with-mental-health-disorder' + (askedTo == HealthQuestionAskedTo.MEMBER ? "_member" : "_spouse"),
        type: HealthQuestionTypes.YES_NO,
        label: "Have you ever been diagnosed, treated or given medical advice by a physician or other health care provider for mental, anxiety, depression, attempted suicide or nervous disorder?",
        value: YesNoOptions.NotSelected,
        askedTo: askedTo,
        product: productId,
        definition: DEFINITION_DEFAULT,
        formType: HealthQuestionFormType.SOH_LONG_TRIGGER,
        children: inPlaceSOHQuestions('diagnosed-with-mental-health-disorder', productId, askedTo ),
        choices: NO_CHOICES,
      },
      {
        lookupKey: 'diagnosed-with-sleep-apnea' + (askedTo == HealthQuestionAskedTo.MEMBER ? "_member" : "_spouse"),
        type: HealthQuestionTypes.YES_NO,
        label: "Have you ever been diagnosed, treated or given medical advice by a physician or other health care provider for sleep apnea?",
        value: YesNoOptions.NotSelected,
        askedTo: askedTo,
        product: productId,
        definition: DEFINITION_DEFAULT,
        formType: HealthQuestionFormType.SOH_LONG_TRIGGER,
        children: inPlaceSOHQuestions('diagnosed-with-sleep-apnea', productId, askedTo ),
        choices: NO_CHOICES,
      },
    ]);
  }
  else{
    return new Collection<HealthQuestionProps>([
      {
        lookupKey: 'diagnosed-with-blood-disorder',
        type: HealthQuestionTypes.YES_NO,
        label: "Have you ever been diagnosed, treated or given medical advice by a physician or other health care provider for anemia, leukemia or other blood disorder?",
        value: YesNoOptions.NotSelected,
        askedTo: askedTo,
        product: productId,
        definition: DEFINITION_DEFAULT,
        formType: HealthQuestionFormType.SOH_LONG_TRIGGER,
        children: inPlaceSOHQuestions('diagnosed-with-blood-disorder', productId, askedTo, ),
        choices: NO_CHOICES,
      },
      {
        lookupKey: 'diagnosed-with-lung-disease',
        type: HealthQuestionTypes.YES_NO,
        label: "Have you ever been diagnosed, treated or given medical advice by a physician or other health care provider for asthma, COPD, emphysema or other lung disease?",
        value: YesNoOptions.NotSelected,
        askedTo: askedTo,
        product: productId,
        definition: DEFINITION_DEFAULT,
        formType: HealthQuestionFormType.SOH_LONG_TRIGGER,
        children: inPlaceSOHQuestions('diagnosed-with-lung-disease', productId, askedTo ),
        choices: NO_CHOICES,
      },
      {
        lookupKey: 'diagnosed-with-stomach-or-liver-disorder',
        type: HealthQuestionTypes.YES_NO,
        label: "Have you ever been diagnosed, treated or given medical advice by a physician or other health care provider for ulcers, stomach, hepatitis or other liver disorder?",
        value: YesNoOptions.NotSelected,
        askedTo: askedTo,
        product: productId,
        definition: DEFINITION_DEFAULT,
        formType: HealthQuestionFormType.SOH_LONG_TRIGGER,
        children: inPlaceSOHQuestions('diagnosed-with-stomach-or-liver-disorder', productId, askedTo ),
        choices: NO_CHOICES,
      },
      {
        lookupKey: 'diagnosed-with-intestinal-disorder',
        type: HealthQuestionTypes.YES_NO,
        label: "Have you ever been diagnosed, treated or given medical advice by a physician or other health care provider for colitis, Crohn’s, diverticulitis or other intestinal disorder?",
        value: YesNoOptions.NotSelected,
        askedTo: askedTo,
        product: productId,
        definition: DEFINITION_DEFAULT,
        formType: HealthQuestionFormType.SOH_LONG_TRIGGER,
        children: inPlaceSOHQuestions('diagnosed-with-intestinal-disorder', productId, askedTo ),
        choices: NO_CHOICES,
      },
      {
        lookupKey: 'diagnosed-with-memory-loss',
        type: HealthQuestionTypes.YES_NO,
        label: "Have you ever been diagnosed, treated or given medical advice by a physician or other health care provider for memory loss?",
        value: YesNoOptions.NotSelected,
        askedTo: askedTo,
        product: productId,
        definition: DEFINITION_DEFAULT,
        formType: HealthQuestionFormType.SOH_LONG_TRIGGER,
        children: inPlaceSOHQuestions('diagnosed-with-memory-loss', productId, askedTo ),
        choices: NO_CHOICES,
      },
      {
        lookupKey: 'diagnosed-neurological-disorder',
        type: HealthQuestionTypes.YES_NO,
        label: "Have you ever been diagnosed, treated or given medical advice by a physician or other health care provider for epilepsy, paralysis, seizures, dizziness or other neurological disorder?",
        value: YesNoOptions.NotSelected,
        askedTo: askedTo,
        product: productId,
        definition: DEFINITION_DEFAULT,
        formType: HealthQuestionFormType.SOH_LONG_TRIGGER,
        children: inPlaceSOHQuestions('diagnosed-neurological-disorder', productId, askedTo ),
        choices: NO_CHOICES,
      },
      {
        lookupKey: 'diagnosed-with-fatigue-syndromes',
        type: HealthQuestionTypes.YES_NO,
        label: "Have you ever been diagnosed, treated or given medical advice by a physician or other health care provider for Epstein-Barr, chronic fatigue syndrome or fibromyalgia?",
        value: YesNoOptions.NotSelected,
        askedTo: askedTo,
        product: productId,
        definition: DEFINITION_DEFAULT,
        formType: HealthQuestionFormType.SOH_LONG_TRIGGER,
        children: inPlaceSOHQuestions('diagnosed-with-fatigue-syndromes', productId, askedTo ),
        choices: NO_CHOICES,
      },
      {
        lookupKey: 'diagnosed-with-muscular-dystrophy',
        type: HealthQuestionTypes.YES_NO,
        label: "Have you ever been diagnosed, treated or given medical advice by a physician or other health care provider for multiple sclerosis, ALS or muscular dystrophy?",
        value: YesNoOptions.NotSelected,
        askedTo: askedTo,
        product: productId,
        definition: DEFINITION_DEFAULT,
        formType: HealthQuestionFormType.SOH_LONG_TRIGGER,
        children: inPlaceSOHQuestions('diagnosed-with-muscular-dystrophy', productId, askedTo ),
        choices: NO_CHOICES,
      },
      {
        lookupKey: 'diagnosed-with-auto-immune',
        type: HealthQuestionTypes.YES_NO,
        label: "Have you ever been diagnosed, treated or given medical advice by a physician or other health care provider for lupus, scleroderma, auto immune disease or connective tissue disorder?",
        value: YesNoOptions.NotSelected,
        askedTo: askedTo,
        product: productId,
        definition: DEFINITION_DEFAULT,
        formType: HealthQuestionFormType.SOH_LONG_TRIGGER,
        children: inPlaceSOHQuestions('diagnosed-with-auto-immune', productId, askedTo ),
        choices: NO_CHOICES,
      },
      {
        lookupKey: 'diagnosed-with-arthritis',
        type: HealthQuestionTypes.YES_NO,
        label: "Have you ever been diagnosed, treated or given medical advice by a physician or other health care provider for arthritis?",
        value: YesNoOptions.NotSelected,
        askedTo: askedTo,
        product: productId,
        definition: DEFINITION_DEFAULT,
        formType: HealthQuestionFormType.SOH_LONG_TRIGGER,
        children: new Collection<HealthQuestionProps>([
          {
            lookupKey: "diagnosed-with-arthritis_type",
            type: HealthQuestionTypes.SELECT,
            label: "Type",
            value: undefined,
            askedTo: askedTo,
            product: productId,
            definition: "",
            formType: HealthQuestionFormType.LONG,
            children: emptyChildren,
            choices: NO_CHOICES,
          },
          ...inPlaceSOHQuestions('diagnosed-with-arthritis', productId, askedTo ).toArray()
        ]),
        choices: NO_CHOICES,
      },
      {
        lookupKey: 'diagnosed-with-carpal-tunnel',
        type: HealthQuestionTypes.YES_NO,
        label: "Have you ever been diagnosed, treated or given medical advice by a physician or other health care provider for carpal tunnel syndrome?",
        value: YesNoOptions.NotSelected,
        askedTo: askedTo,
        product: productId,
        definition: DEFINITION_DEFAULT,
        formType: HealthQuestionFormType.SOH_LONG_TRIGGER,
        children: inPlaceSOHQuestions('diagnosed-with-carpal-tunnel', productId, askedTo ),
        choices: NO_CHOICES,
      },
      {
        lookupKey: 'diagnosed-with-kidney-urinary-tract-prostate-disorder',
        type: HealthQuestionTypes.YES_NO,
        label: "Have you ever been diagnosed, treated or given medical advice by a physician or other health care provider for kidney, urinary tract or prostate disorder?",
        value: YesNoOptions.NotSelected,
        askedTo: askedTo,
        product: productId,
        definition: DEFINITION_DEFAULT,
        formType: HealthQuestionFormType.SOH_LONG_TRIGGER,
        children: inPlaceSOHQuestions('diagnosed-with-kidney-urinary-tract-prostate-disorder', productId, askedTo ),
        choices: NO_CHOICES,
      },
      {
        lookupKey: 'diagnosed-with-kidney-urinary-tract-prostate-disorder',
        type: HealthQuestionTypes.YES_NO,
        label: "Have you ever been diagnosed, treated or given medical advice by a physician or other health care provider for kidney, urinary tract or prostate disorder?",
        value: YesNoOptions.NotSelected,
        askedTo: askedTo,
        product: productId,
        definition: DEFINITION_DEFAULT,
        formType: HealthQuestionFormType.SOH_LONG_TRIGGER,
        children: inPlaceSOHQuestions('diagnosed-with-kidney-urinary-tract-prostate-disorder', productId, askedTo ),
        choices: NO_CHOICES,
      },
      {
        lookupKey: 'diagnosed-with-gland-disorder',
        type: HealthQuestionTypes.YES_NO,
        label: "Have you ever been diagnosed, treated or given medical advice by a physician or other health care provider for thyroid or other gland disorder?",
        value: YesNoOptions.NotSelected,
        askedTo: askedTo,
        product: productId,
        definition: DEFINITION_DEFAULT,
        formType: HealthQuestionFormType.SOH_LONG_TRIGGER,
        children: inPlaceSOHQuestions('diagnosed-with-gland-disorder', productId, askedTo ),
        choices: NO_CHOICES,
      },
      // {
      //   lookupKey: 'diagnosed-with-mental-health-disorder',
      //   type: HealthQuestionTypes.YES_NO,
      //   label: "Have you ever been diagnosed, treated or given medical advice by a physician or other health care provider for mental, anxiety, depression, attempted suicide or nervous disorder?",
      //   value: YesNoOptions.NotSelected,
      //   askedTo: askedTo,
      //   product: productId,
      //   definition: DEFINITION_DEFAULT,
      //   formType: HealthQuestionFormType.SOH_LONG_TRIGGER,
      //   children: inPlaceSOHQuestions('diagnosed-with-mental-health-disorder', productId, askedTo ),
      //   choices: NO_CHOICES,
      // },
      {
        lookupKey: 'diagnosed-with-sleep-apnea',
        type: HealthQuestionTypes.YES_NO,
        label: "Have you ever been diagnosed, treated or given medical advice by a physician or other health care provider for sleep apnea?",
        value: YesNoOptions.NotSelected,
        askedTo: askedTo,
        product: productId,
        definition: DEFINITION_DEFAULT,
        formType: HealthQuestionFormType.SOH_LONG_TRIGGER,
        children: inPlaceSOHQuestions('diagnosed-with-sleep-apnea', productId, askedTo ),
        choices: NO_CHOICES,
      },

    ]);
  }

}

let emptyChildren = new Collection<HealthQuestionProps>();
const TermLifeADDHealthQuestions = new Collection<HealthQuestionProps>([
  {
    lookupKey: "height",
    type: HealthQuestionTypes.HEIGHT,
    label: "",
    value: null,
    askedTo: HealthQuestionAskedTo.MEMBER | HealthQuestionAskedTo.SPOUSE,
    product: ProductId.TERM_LIFE_ADD,
    definition: DEFINITION_DEFAULT,
    formType: HealthQuestionFormType.SHORT,
    children: emptyChildren,
    choices: NO_CHOICES,
  },
  {
    lookupKey: "weight",
    type: HealthQuestionTypes.WEIGHT,
    label: "Weight (lbs)",
    value: null,
    askedTo: HealthQuestionAskedTo.MEMBER | HealthQuestionAskedTo.SPOUSE,
    product: ProductId.TERM_LIFE_ADD,
    definition: DEFINITION_DEFAULT,
    formType: HealthQuestionFormType.SHORT,
    children: emptyChildren,
    choices: NO_CHOICES,
  },
  {
    lookupKey: "tobacco-last-year",
    type: HealthQuestionTypes.YES_NO,
    label: "Have you smoked cigarettes, pipes or cigars or used tobacco in any form in the past one year?",
    value: YesNoOptions.NotSelected,
    askedTo: HealthQuestionAskedTo.MEMBER | HealthQuestionAskedTo.SPOUSE,
    product: ProductId.TERM_LIFE_ADD | ProductId.GUARANTEED_TERM_LIFE_ADD,
    definition: DEFINITION_DEFAULT,
    formType: HealthQuestionFormType.GI,
    children: emptyChildren,
    choices: NO_CHOICES,
  },
  {
    lookupKey: "disability-benefits_member",
    type: HealthQuestionTypes.YES_NO,
    label: "Are you now receiving or applying for any disability benefits, including workers’ compensation?",
    value: YesNoOptions.NotSelected,
    askedTo: HealthQuestionAskedTo.MEMBER ,
    product: ProductId.TERM_LIFE_ADD,
    definition: "",
    formType: HealthQuestionFormType.SHORT,
    children: new Collection<HealthQuestionProps>([
      {
        lookupKey: "disability-benefits_details",
        type: HealthQuestionTypes.TEXT,
        label: "Details",
        value: undefined,
        askedTo: HealthQuestionAskedTo.MEMBER,
        product: ProductId.TERM_LIFE_ADD,
        definition: "",
        formType: HealthQuestionFormType.LONG,
        children: emptyChildren,
        choices: NO_CHOICES,
      }
    ]),
    choices: NO_CHOICES,
  },
  {
    lookupKey: "disability-benefits_spouse",
    type: HealthQuestionTypes.YES_NO,
    label: "Are you now receiving or applying for any disability benefits, including workers’ compensation?",
    value: YesNoOptions.NotSelected,
    askedTo: HealthQuestionAskedTo.SPOUSE,
    product: ProductId.TERM_LIFE_ADD,
    definition: "",
    formType: HealthQuestionFormType.SHORT,
    children: new Collection<HealthQuestionProps>([
      {
        lookupKey: "disability-benefits_details",
        type: HealthQuestionTypes.TEXT,
        label: "Details",
        value: undefined,
        askedTo: HealthQuestionAskedTo.SPOUSE,
        product: ProductId.TERM_LIFE_ADD,
        definition: "",
        formType: HealthQuestionFormType.LONG,
        children: emptyChildren,
        choices: NO_CHOICES,
      }
    ]),
    choices: NO_CHOICES,
    },
    {
        lookupKey: "has-been-hospitalized-90-days_member",
        type: HealthQuestionTypes.YES_NO,
        label: "Have you been Hospitalized as defined below (not including well-baby delivery) in the past 90 days?",
        value: YesNoOptions.NotSelected,
        askedTo: HealthQuestionAskedTo.MEMBER,
        product: ProductId.TERM_LIFE_ADD,
        definition: "Hospitalized means admission for inpatient care in a hospital; receipt of care in a hospice facility, intermediate care facility, or long term care facility; or receipt of the following treatment wherever performed: chemotherapy, radiation therapy, or dialysis.",
        formType: HealthQuestionFormType.SHORT,
        children: inPlaceSOHQuestions('has-been-hospitalized-90-days', ProductId.TERM_LIFE_ADD, HealthQuestionAskedTo.MEMBER),
        choices: NO_CHOICES,
    },
    {
        lookupKey: "has-been-hospitalized-90-days_spouse",
        type: HealthQuestionTypes.YES_NO,
        label: "Have you been Hospitalized as defined below (not including well-baby delivery) in the past 90 days?",
        value: YesNoOptions.NotSelected,
        askedTo: HealthQuestionAskedTo.SPOUSE,
        product: ProductId.TERM_LIFE_ADD,
        definition: "Hospitalized means admission for inpatient care in a hospital; receipt of care in a hospice facility, intermediate care facility, or long term care facility; or receipt of the following treatment wherever performed: chemotherapy, radiation therapy, or dialysis.",
        formType: HealthQuestionFormType.SHORT,
        children: inPlaceSOHQuestions('has-been-hospitalized-90-days', ProductId.TERM_LIFE_ADD, HealthQuestionAskedTo.SPOUSE),
        choices: NO_CHOICES,
    },
  {
    lookupKey: "has-been-hospitalized-2-years",
    type: HealthQuestionTypes.YES_NO,
    label: "In the past 2 years, have you been Hospitalized as defined below (not including well-baby delivery)?",
    value: YesNoOptions.NotSelected,
    askedTo: HealthQuestionAskedTo.MEMBER | HealthQuestionAskedTo.SPOUSE,
    product: ProductId.TERM_LIFE_ADD,
    definition: "Hospitalized means admission for inpatient care in a hospital; receipt of care in a hospice facility,  intermediate care facility, or long  term care facility; or receipt of the following treatment wherever performed: chemotherapy, radiation therapy, or dialysis.",
    formType: HealthQuestionFormType.LONG,
    children: emptyChildren,
    choices: NO_CHOICES,
  },
  {
    lookupKey:"has-prescribed-diet_member",
    type: HealthQuestionTypes.YES_NO,
    label: "Are you now on a diet prescribed by a physician or other health care provider?",
    value: YesNoOptions.NotSelected,
    askedTo: HealthQuestionAskedTo.MEMBER,
    product: ProductId.TERM_LIFE_ADD,
    definition: DEFINITION_DEFAULT,
    formType: HealthQuestionFormType.LONG,
    children: new Collection<HealthQuestionProps>([
      {
        lookupKey: "has-prescribed-diet_diet-type_member",
        type: HealthQuestionTypes.TEXT,
        label: "Diet Type",
        value: undefined,
        askedTo: HealthQuestionAskedTo.MEMBER,
        product: ProductId.TERM_LIFE_ADD,
        definition: "",
        formType: HealthQuestionFormType.LONG,
        children: emptyChildren,
        choices: NO_CHOICES,
      }
    ]),
    choices: NO_CHOICES,
  },
  {
    lookupKey:"has-prescribed-diet_spouse",
    type: HealthQuestionTypes.YES_NO,
    label: "Are you now on a diet prescribed by a physician or other health care provider?",
    value: YesNoOptions.NotSelected,
    askedTo: HealthQuestionAskedTo.SPOUSE,
    product: ProductId.TERM_LIFE_ADD,
    definition: DEFINITION_DEFAULT,
    formType: HealthQuestionFormType.LONG,
    children: new Collection<HealthQuestionProps>([
      {
        lookupKey: "has-prescribed-diet_diet-type_spouse",
        type: HealthQuestionTypes.TEXT,
        label: "Diet Type",
        value: undefined,
        askedTo: HealthQuestionAskedTo.SPOUSE,
        product: ProductId.TERM_LIFE_ADD,
        definition: "",
        formType: HealthQuestionFormType.LONG,
        children: emptyChildren,
        choices: NO_CHOICES,
      },
    ]),
    choices: NO_CHOICES,
  },
  {
    lookupKey:"is-pregnant_member",
    type: HealthQuestionTypes.YES_NO,
    label: "Are you now pregnant?",
    value: YesNoOptions.NotSelected,
    askedTo: HealthQuestionAskedTo.MEMBER,
    product: ProductId.TERM_LIFE_ADD,
    definition: DEFINITION_DEFAULT,
    formType: HealthQuestionFormType.LONG,
    children: new Collection<HealthQuestionProps>([
      {
        lookupKey: "is-pregnant_pregnancy-due-date_member",
        type: HealthQuestionTypes.DATE,
        label: "Due Date",
        value: undefined,
        askedTo: HealthQuestionAskedTo.MEMBER ,
        product: ProductId.TERM_LIFE_ADD,
        definition: "",
        formType: HealthQuestionFormType.LONG,
        children: emptyChildren,
        choices: NO_CHOICES,
      },
      {
        lookupKey: "is-pregnant_pregnancy-physician-name_member",
        type: HealthQuestionTypes.TEXT,
        label: "Physician's Name",
        value: undefined,
        askedTo: HealthQuestionAskedTo.MEMBER,
        product: ProductId.TERM_LIFE_ADD,
        definition: "",
        formType: HealthQuestionFormType.LONG,
        children: emptyChildren,
        choices: NO_CHOICES,
      },
      {
        lookupKey: "is-pregnant_pregnancy-physician-phone_member",
        type: HealthQuestionTypes.PHONE,
        label: "Physician's Phone",
        value: undefined,
        askedTo: HealthQuestionAskedTo.MEMBER,
        product: ProductId.TERM_LIFE_ADD,
        definition: "",
        formType: HealthQuestionFormType.LONG,
        children: emptyChildren,
        choices: NO_CHOICES,
      }
     ]),
    choices: NO_CHOICES,
  },
  {
    lookupKey:"is-pregnant_spouse",
    type: HealthQuestionTypes.YES_NO,
    label: "Are you now pregnant?",
    value: YesNoOptions.NotSelected,
    askedTo: HealthQuestionAskedTo.SPOUSE,
    product: ProductId.TERM_LIFE_ADD,
    definition: DEFINITION_DEFAULT,
    formType: HealthQuestionFormType.LONG,
    children: new Collection<HealthQuestionProps>([
      {
        lookupKey: "is-pregnant_pregnancy-due-date_spouse",
        type: HealthQuestionTypes.DATE,
        label: "Due Date",
        value: undefined,
        askedTo: HealthQuestionAskedTo.SPOUSE ,
        product: ProductId.TERM_LIFE_ADD,
        definition: "",
        formType: HealthQuestionFormType.LONG,
        children: emptyChildren,
        choices: NO_CHOICES,
      },
      {
        lookupKey: "is-pregnant_pregnancy-physician-name_spouse",
        type: HealthQuestionTypes.TEXT,
        label: "Physician's Name",
        value: undefined,
        askedTo: HealthQuestionAskedTo.SPOUSE,
        product: ProductId.TERM_LIFE_ADD,
        definition: "",
        formType: HealthQuestionFormType.LONG,
        children: emptyChildren,
        choices: NO_CHOICES,
      },
      {
        lookupKey: "is-pregnant_pregnancy-physician-phone_spouse",
        type: HealthQuestionTypes.PHONE,
        label: "Physician's Phone",
        value: undefined,
        askedTo: HealthQuestionAskedTo.SPOUSE,
        product: ProductId.TERM_LIFE_ADD,
        definition: "",
        formType: HealthQuestionFormType.LONG,
        children: emptyChildren,
        choices: NO_CHOICES,
      }
     ]),
    choices: NO_CHOICES,
  },
  {
    lookupKey: "tobacco-past-two-years",
    type: HealthQuestionTypes.YES_NO,
    label: "Are you now, or have you in the past 2 years, used tobacco in any form?",
    value: YesNoOptions.NotSelected,
    askedTo: HealthQuestionAskedTo.MEMBER | HealthQuestionAskedTo.SPOUSE,
    product: ProductId.TERM_LIFE_ADD,
    definition: "",
    formType: HealthQuestionFormType.LONG,
    children: emptyChildren,
    choices: NO_CHOICES,
  },
  {
    lookupKey: "dui-past-five-years_member",
    type: HealthQuestionTypes.YES_NO,
    label: "In the past 5 years, have you been convicted of driving while intoxicated or under the influence of alcohol and/or any drug?",
    value: YesNoOptions.NotSelected,
    askedTo: HealthQuestionAskedTo.MEMBER,
    product: ProductId.TERM_LIFE_ADD,
    definition: "",
    formType: HealthQuestionFormType.LONG,
    children: new Collection<HealthQuestionProps>([
      {
        lookupKey: "dui_date-of-conviction_member",
        type: HealthQuestionTypes.TEXT,
        label: "Date(s) of conviction",
        value: undefined,
        askedTo: HealthQuestionAskedTo.MEMBER,
        product: ProductId.TERM_LIFE_ADD,
        definition: "",
        formType: HealthQuestionFormType.LONG,
        children: emptyChildren,
        choices: NO_CHOICES,
      },
    ]),
    choices: NO_CHOICES,
  },
  {
    lookupKey: "dui-past-five-years_spouse",
    type: HealthQuestionTypes.YES_NO,
    label: "In the past 5 years, have you been convicted of driving while intoxicated or under the influence of alcohol and/or any drug?",
    value: YesNoOptions.NotSelected,
    askedTo: HealthQuestionAskedTo.SPOUSE,
    product: ProductId.TERM_LIFE_ADD,
    definition: "",
    formType: HealthQuestionFormType.LONG,
    children: new Collection<HealthQuestionProps>([
      {
        lookupKey: "dui_date-of-conviction_spouse",
        type: HealthQuestionTypes.TEXT,
        label: "Date(s) of conviction",
        value: undefined,
        askedTo: HealthQuestionAskedTo.SPOUSE,
        product: ProductId.TERM_LIFE_ADD,
        definition: "",
        formType: HealthQuestionFormType.LONG,
        children: emptyChildren,
        choices: NO_CHOICES,
      },
    ]),
    choices: NO_CHOICES,
  },
  {
    lookupKey: "has-unfavorable-past-application_member",
    type: HealthQuestionTypes.YES_NO,
    label: "Have you had any application for life, accidental death and dismemberment or disability insurance declined, postponed, withdrawn, rated, modified, or issued other than as applied for?",
    value: YesNoOptions.NotSelected,
    askedTo: HealthQuestionAskedTo.MEMBER,
    product: ProductId.TERM_LIFE_ADD,
    definition: "",
    formType: HealthQuestionFormType.SHORT,
    children: new Collection<HealthQuestionProps>([
      {
        lookupKey: "has-unfavorable-past-application_type",
        type: HealthQuestionTypes.SELECT,
        label: "Which?",
        value: undefined,
        askedTo: HealthQuestionAskedTo.MEMBER,
        product: ProductId.TERM_LIFE_ADD,
        definition: "",
        formType: HealthQuestionFormType.LONG,
        children: emptyChildren,
        choices: [
          'Declined',
          'Postponed',
          'Withdrawn',
          'Rated',
          'Modified',
          'Issued other than as applied'
        ]

      },
      {
        lookupKey: "has-unfavorable-past-application_reason",
        type: HealthQuestionTypes.TEXT,
        label: "Reason",
        value: undefined,
        askedTo: HealthQuestionAskedTo.MEMBER,
        product: ProductId.TERM_LIFE_ADD,
        definition: "",
        formType: HealthQuestionFormType.LONG,
        children: emptyChildren,
        choices: [
          'Declined',
          'Postponed',
          'Withdrawn',
          'Rated',
          'Modified',
          'Issued other than as applied'
        ],
      }
    ]),
    choices: NO_CHOICES,
  },
  {
    lookupKey: "has-unfavorable-past-application_spouse",
    type: HealthQuestionTypes.YES_NO,
    label: "Have you had any application for life, accidental death and dismemberment or disability insurance declined, postponed, withdrawn, rated, modified, or issued other than as applied for?",
    value: YesNoOptions.NotSelected,
    askedTo: HealthQuestionAskedTo.SPOUSE,
    product: ProductId.TERM_LIFE_ADD,
    definition: "",
    formType: HealthQuestionFormType.SHORT,
    children: new Collection<HealthQuestionProps>([
      {
        lookupKey: "has-unfavorable-past-application_type_spouse",
        type: HealthQuestionTypes.SELECT,
        label: "Which?",
        value: undefined,
        askedTo: HealthQuestionAskedTo.SPOUSE,
        product: ProductId.TERM_LIFE_ADD,
        definition: "",
        formType: HealthQuestionFormType.LONG,
        children: emptyChildren,
        choices: [
          'Declined',
          'Postponed',
          'Withdrawn',
          'Rated',
          'Modified',
          'Issued other than as applied'
        ]
      },
      {
        lookupKey: "has-unfavorable-past-application_reason_spouse",
        type: HealthQuestionTypes.TEXT,
        label: "Reason",
        value: undefined,
        askedTo: HealthQuestionAskedTo.SPOUSE,
        product: ProductId.TERM_LIFE_ADD,
        definition: "",
        formType: HealthQuestionFormType.LONG,
        children: emptyChildren,
        choices: [
          'Declined',
          'Postponed',
          'Withdrawn',
          'Rated',
          'Modified',
          'Issued other than as applied'
        ],
      },

    ]),
    choices: NO_CHOICES,
  },
  {
    lookupKey: "past-5-years-doctor-said-stop-alcohol-or-drugs_member",
    type: HealthQuestionTypes.YES_NO,
    label: "In the past 5 years, have you received medical treatment or counseling by a physician or other health care provider for, or been advised by a physician or other health care provider to discontinue, the use of alcohol or prescribed or non-prescribed drugs?",
    value: YesNoOptions.NotSelected,
    askedTo: HealthQuestionAskedTo.MEMBER,
    product: ProductId.TERM_LIFE_ADD,
    definition: "",
    formType: HealthQuestionFormType.LONG,
    children: inPlaceSOHQuestions('past-5-years-doctor-said-stop-alcohol-or-drugs', ProductId.TERM_LIFE_ADD, HealthQuestionAskedTo.MEMBER),
    choices: NO_CHOICES,
  },
  {
    lookupKey: "past-5-years-doctor-said-stop-alcohol-or-drugs_spouse",
    type: HealthQuestionTypes.YES_NO,
    label: "In the past 5 years, have you received medical treatment or counseling by a physician or other health care provider for, or been advised by a physician or other health care provider to discontinue, the use of alcohol or prescribed or non-prescribed drugs?",
    value: YesNoOptions.NotSelected,
    askedTo: HealthQuestionAskedTo.SPOUSE,
    product: ProductId.TERM_LIFE_ADD,
    definition: "",
    formType: HealthQuestionFormType.LONG,
    children: inPlaceSOHQuestions('past-5-years-doctor-said-stop-alcohol-or-drugs', ProductId.TERM_LIFE_ADD, HealthQuestionAskedTo.SPOUSE),
    choices: NO_CHOICES,
  },
  {
    lookupKey: "hiv-aids-non-ct_member",
    type: HealthQuestionTypes.YES_NO,
    label: "<strong>For residents of all states except CT, please answer the following question:</strong> Have you ever been diagnosed or treated by a physician or other health care provider for Acquired Immunodeficiency Syndrome (AIDS), AIDS Related Complex (ARC) or the Human Immunodeficiency Virus (HIV) infection?<br /><strong>For CT residents, please answer the following question:</strong> To the best of your knowledge and belief, have you ever been diagnosed or treated by a physician or other health care provider for Acquired Immunodeficiency Syndrome (AIDS), AIDS Related Complex (ARC) or the Human Immunodeficiency Virus (HIV) infection?",
    value: YesNoOptions.NotSelected,
    askedTo: HealthQuestionAskedTo.MEMBER,
    product: ProductId.TERM_LIFE_ADD,
    definition: "",
    formType: HealthQuestionFormType.SHORT,
    children: inPlaceSOHQuestions('hiv-aids-non-ct', ProductId.TERM_LIFE_ADD, HealthQuestionAskedTo.MEMBER),
    choices: NO_CHOICES,
  },
  {
    lookupKey: "hiv-aids-non-ct_spouse",
    type: HealthQuestionTypes.YES_NO,
    label: "<strong>For residents of all states except CT, please answer the following question:</strong> Have you ever been diagnosed or treated by a physician or other health care provider for Acquired Immunodeficiency Syndrome (AIDS), AIDS Related Complex (ARC) or the Human Immunodeficiency Virus (HIV) infection?<br /><strong>For CT residents, please answer the following question:</strong> To the best of your knowledge and belief, have you ever been diagnosed or treated by a physician or other health care provider for Acquired Immunodeficiency Syndrome (AIDS), AIDS Related Complex (ARC) or the Human Immunodeficiency Virus (HIV) infection?",
    value: YesNoOptions.NotSelected,
    askedTo: HealthQuestionAskedTo.SPOUSE,
    product: ProductId.TERM_LIFE_ADD,
    definition: "",
    formType: HealthQuestionFormType.SHORT,
    children: inPlaceSOHQuestions('hiv-aids-non-ct', ProductId.TERM_LIFE_ADD, HealthQuestionAskedTo.SPOUSE),
    choices: NO_CHOICES,
  },
]);

const shortTriggers = sohShortTriggers(ProductId.TERM_LIFE_ADD, HealthQuestionAskedTo.MEMBER).merge(sohShortTriggers(ProductId.TERM_LIFE_ADD, HealthQuestionAskedTo.SPOUSE));
shortTriggers.each(item => {
  TermLifeADDHealthQuestions.put(item);
})
const healthQuestionPropsCollection = sohLongTriggers(ProductId.TERM_LIFE_ADD, HealthQuestionAskedTo.MEMBER).merge(sohLongTriggers(ProductId.TERM_LIFE_ADD, HealthQuestionAskedTo.SPOUSE));
healthQuestionPropsCollection.each(item => {
  TermLifeADDHealthQuestions.put(item);
});

//End Term Life AD&D

const logger = new ConsoleLogger("HealthQuestionStub");
logger.debug("Flat data", TermLifeADDHealthQuestions);

const aggregate = (questions: Collection<HealthQuestionProps>) => {
  const hasSpouse = questions.filter(q => { return (q.askedTo & HealthQuestionAskedTo.SPOUSE) === HealthQuestionAskedTo.SPOUSE;}).length > 0;

  let result = new Collection<ByAskedFormByFormTypeItem>([
    {
      lookupKey: HealthQuestionAskedTo.MEMBER,
      shortForm: questions.filter(q => (
        (q.askedTo & HealthQuestionAskedTo.MEMBER) === HealthQuestionAskedTo.MEMBER &&
        (q.formType === HealthQuestionFormType.SHORT)
      )),
      sohShortTriggers: questions.filter(q => (
        (q.askedTo & HealthQuestionAskedTo.MEMBER) === HealthQuestionAskedTo.MEMBER &&
        (q.formType === HealthQuestionFormType.SOH_SHORT_TRIGGER)
      )),
      sohLongTriggers: questions.filter(q => (
        (q.askedTo & HealthQuestionAskedTo.MEMBER) === HealthQuestionAskedTo.MEMBER &&
        (q.formType === HealthQuestionFormType.SOH_LONG_TRIGGER)
      )),
      longForm: questions.filter(q => (
        (q.askedTo & HealthQuestionAskedTo.MEMBER) === HealthQuestionAskedTo.MEMBER &&
        (q.formType === HealthQuestionFormType.LONG)
      )),
      gi: questions.filter(q => (
        (q.askedTo & HealthQuestionAskedTo.MEMBER) === HealthQuestionAskedTo.MEMBER &&
        (q.formType === HealthQuestionFormType.GI)
      )),
      soh: questions.filter(q => (
        (q.askedTo & HealthQuestionAskedTo.MEMBER) === HealthQuestionAskedTo.MEMBER &&
        (q.formType === HealthQuestionFormType.SOH)
      ))
    },
  ]);
  if(hasSpouse){
    result.put( {
      lookupKey: HealthQuestionAskedTo.SPOUSE,
      shortForm: TermLifeADDHealthQuestions.filter(q => (
        (q.askedTo & HealthQuestionAskedTo.SPOUSE) === HealthQuestionAskedTo.SPOUSE &&
        (q.formType === HealthQuestionFormType.SHORT)
      )),
      longForm: TermLifeADDHealthQuestions.filter(q => (
        (q.askedTo & HealthQuestionAskedTo.SPOUSE) === HealthQuestionAskedTo.SPOUSE &&
        (q.formType === HealthQuestionFormType.LONG)
      )),
      sohShortTriggers: questions.filter(q => (
        (q.askedTo & HealthQuestionAskedTo.SPOUSE) === HealthQuestionAskedTo.SPOUSE &&
        (q.formType === HealthQuestionFormType.SOH_SHORT_TRIGGER)
      )),
      sohLongTriggers: TermLifeADDHealthQuestions.filter(q => (
        (q.askedTo & HealthQuestionAskedTo.SPOUSE) === HealthQuestionAskedTo.SPOUSE &&
        (q.formType === HealthQuestionFormType.SOH_LONG_TRIGGER)
      )),
      gi: questions.filter(q => (
        (q.askedTo & HealthQuestionAskedTo.SPOUSE) === HealthQuestionAskedTo.SPOUSE &&
        (q.formType === HealthQuestionFormType.GI)
      )),
      soh: questions.filter(q => (
        (q.askedTo & HealthQuestionAskedTo.MEMBER) === HealthQuestionAskedTo.MEMBER &&
        (q.formType === HealthQuestionFormType.SOH)
      ))
    })
  }
  return result;
}

export default {
  aggregatedTermLifeQuestions: () => aggregate(TermLifeADDHealthQuestions),
  aggregatedLtdQuestions: (isBmiSuccessful: boolean, isWithinOneYearAftMem: boolean, state: string = 'ct', isSoh: boolean) => {
    const action:(f: boolean, s: boolean) => Array<HealthQuestionProps> = isWithinOneYearAftMem ? formMembershipWithinOneYear : formMembershipOutsideOneYear;
    const raw = new Collection<HealthQuestionProps>(action(isBmiSuccessful, isSoh));

    return aggregate(raw);
  }
}