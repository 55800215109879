import axios, {AxiosError} from "axios";
import ProductsStub from "../../stub/Products";
import Timers from "../../helpers/timers";
import {Dependent, DependentCollection} from "../../models/dependents";
import {ProductId} from "../../types/product";
import {HealthQuestionAskedTo, HealthQuestionFormType} from "../../models/health-questions";
import ConsoleLogger from "../logging/logger";
import {BeneficiaryCollection} from "../../models/beneficiaries";
import {TBeneficiary} from "../../types/beneficiaries";
import {DeclarationType} from "../../stub/Declarations";

const logger = new ConsoleLogger("ApiClient");
export type RegistrationData = {
  emailAddress: string,
  password: string,
  confirmedPassword: string,
  aftMembershipDate: string,
  termsAccepted:boolean
}
export type ResetPasswordData = {
  password: string,
  confirmedPassword: string,
  token: string | undefined
}
type Component = {
  name: string;
  display: string;
}

export type Token = {
  expiration: string,
  token: string
};


export type SerializedProduct = {
  id: ProductId;
  displayName: string;
  productKey: string;
  componentNames: Array<Component>;
  showBasicAnnualEarnings: boolean;
  showHeight: boolean;
  showWeight: boolean;
  minAge: number;
  maxAge: number;
}

export type SerializedProducts = {
  "term-life-add": SerializedProduct,
  "guaranteed-term-life-add": SerializedProduct,
  "5k": SerializedProduct,
  "ltd-disability": SerializedProduct
}

export type ApplicationDto = {
  id: number,
  product: string,
  demographic: DemographicDto,
  memberCoverage: CoverageDto,
  spouse: SpouseDto,
  dependents: Dependent[],
  beneficiaries: TBeneficiary[],
  underwritings: HealthQuestionDto[],
  signature: SignatureDto|null,
  physician: PhysicianDto[]|null,
  memberPhysician: PhysicianDto|null,
  spousePhysician: PhysicianDto|null,
  medications: MedicationDto[],
  opt: OptInDto,
  authorization: AuthorizationsDto,
  effectiveDate: Date,
}

export type DemographicDto = {
  applicationId: number;
  firstName: string;
  lastName: string;
  middleName: string,
  emailAddress: string;
  phoneNumber: string;
  phoneType: string;
  altPhoneNumber: string;
  altPhoneType: string;
  gender: string;
  dateOfBirth: string;
  streetAddress: string;
  apartment: string;
  city: string;
  state: string;
  zipCode: string;
  height: number | null;
  weight: number | null;
  basicAnnualEarnings: number | null;
  ssn: string;
  aftMembershipDate: string;
}


export type CoverageDto = {
  applicationId: number, // Should be able to get product from this.
  benefitAmount: number, //Validation is different for each product.
  eleminationDays: number | null,   // Only applies to LTD Disability.
  isADDEnrolled: boolean | null, //Only applies to Term Life AD&D.
  addBenefitAmount: number | null, // Only applies to Term Life AD&D where wantsAdd = true.
}

export type SpouseDto = {
  applicationId: number;
  isSpouseEnrolled: boolean;
  spouseBenefitAmount: number | undefined,
  firstName: string | undefined,
  middleName: string | undefined,
  lastName: string | undefined,
  gender: string | undefined,
  dateOfBirth: string | undefined,
  isUsBorn: boolean | undefined,
  birthState: string,
  birthCountry: string | undefined,
  height: number | undefined,
  weight: number | undefined,
}

export type BeneficiaryDto = {
  beneficiaries: TBeneficiary[],
  id: number
}

export type DependentDto = {
  wantsDependents: boolean,
  benefitAmount: number | undefined,
  dependents: Array<Dependent>
}

type DependentApiDto = {
  id: number,
  dependents: Dependent[]
}

export type OptInDto = {
  applicationId: number,
  edeliverPolicyDocuments: boolean,
  recurringBilling: boolean
}

export type AuthorizationsDto = {
  applicationId: number,
  birthStateOrRegion: string,
  birthCountry: string,
  isSigned: boolean,
  birthStateOrRegionSpouse: string,
  birthCountrySpouse: string,
  isSignedSpouse: boolean
}

export type HealthDataDto = {
  applicationId: number,
  memberPhysician: PhysicianDto|null,
  memberMedications: MedicationDto[],
  spousePhysician: PhysicianDto|null,
  spouseMedications: MedicationDto[],
}

export type PhysicianDto = {
  appliesTo: string|undefined,
  applicationId: number,
  firstName: string|undefined,
  lastName: string|undefined,
  streetAddress: string|undefined,
  apartment: string|undefined,
  city: string|undefined,
  state: string|undefined,
  zipCode: string|undefined,
  phoneNumber: string|undefined,
  lastVisitDate: string|undefined,
  visitReason: string|undefined,
  hasPrescription: boolean|undefined,
}

export type MedicationDto = {
  applicationId: number,
  appliesTo: string|undefined,
  physician: string|undefined,
  medicationTitle: string|undefined,
  diagnosis: string|undefined,
  streetAddress: string|undefined,
  apartment: string|undefined,
  city: string|undefined,
  state: string|undefined,
  zipCode: string|undefined,
  phoneNumber: string|undefined,
}

export type HealthQuestionDto = {
  applicationId: number;
  question: string,
  answer: string,
  answer_To: string,
  triggersUWReferral: boolean;
  key: string
}

export type DeclarationDto = {
  declarations: Array<string>,
  type: DeclarationType
};

export type FraudWarningDto = {
  state: string,
  warning: string,
  cssStyle: string
};

export type SignatureDto = {
  applicationId: number,
  member: boolean | null,
  spouse: boolean | null;
}

export type RateDto = {
  coverage: string,
  type: string,
  amount: number,
  number: number
};

export type PaymentRequestDto = {
  applicationId: number,
  frequency: number
}
export type PaymentPlanDto = {
 url: string
}

export type PaymentStatus = {
  success: boolean,
  pdmSuccess: boolean
}


export type ApiSuccessResponse = {
  isSuccessful: boolean,
  message: string,
}

export type ApiValidationErrorResponse = {
  status: number,
  errors: ApiValidationError,
  title: string
}

export type ApiValidationError =  {
  [key: string]: Array<string>
}

export type ApiResponse = ApiSuccessResponse | ApiValidationErrorResponse;

export interface ApiClient {
  authenticateCredentials(username: string, password: string): Promise<boolean>

  authenticatePortal(): Promise<boolean>;

  register(data: RegistrationData): Promise<boolean>;

  resetPassword(data: ResetPasswordData): Promise<boolean>;

  checktoken(token: string): Promise<boolean>;

  forgotpassword(email: string): Promise<boolean>;

  makeAuthenticatedRequest<T>(method: string, path: string, requestData: object): Promise<T>;

  isAuthenticated(): boolean;

  fetchProductInfo(productKey: string): Promise<SerializedProduct>

  fetchApplication(productKey: string): Promise<ApplicationDto>

  saveDemographicInfo(dto: DemographicDto, productKey: string): Promise<ApiResponse>;

  saveCoverageInfo(dto: CoverageDto, productKey: string): Promise<ApiSuccessResponse>;

}


/*
 *  Create .env.development.local and add REACT_APP_API_ENDPOINT="<url>"
 **/
export class JwtApiClient implements ApiClient {

  baseUrl: string = process.env.REACT_APP_API_ENDPOINT ?? '';

  url(path: string): string {
    return `${this.baseUrl}${path}`;
  }

  isAuthenticated(): boolean {
    return window.sessionStorage.getItem("auth") !== null && new Date(this.getToken().expiration) > new Date();
  }

  protected storeToken(token: Token): void {
    window.sessionStorage.setItem("auth", JSON.stringify(token));
  }

  protected getToken(): Token {
    /** @ts-ignore **/
    return JSON.parse(window.sessionStorage.getItem("auth"));
  }


  async makeAuthenticatedRequest<T>(method: string, path: string, requestData: Array<any>|object): Promise<T> {
    if (!this.isAuthenticated()) {
      /**
       * TODO: Implement Refresh Token if Desired.
       */
      throw new Error("Authentication is required!")
    }

    let config = {
      method: method,
      url: this.url(path),
      headers: {Authorization: `Bearer ${this.getToken().token}`, "Content-Type": "application/json"},
      params: {},
      paramsSerializer: {
        indexes: false,
      },
      data: {}
    };
    const convertToNull:Array<any>|object = requestData;
    if(!Array.isArray(convertToNull)){
      Object.keys(requestData).forEach(key => {
        const k = key as keyof typeof convertToNull;
        if(convertToNull[k] === ''){
          delete convertToNull[k];
        }
      })
    }


    if (method === "GET") {
      config.params = requestData;
    } else {
      config.data = requestData;
    }


    try {
      logger.debug("Making Authenticated API Request", config);
      const {data} = await axios.request<T>(config);
      logger.debug("Response", data);
      return data;
    } catch (error: any) {
      if(error.response){
        // @ts-ignore Mitigated by if-statement.
        logger.error("API Error", error.response.data);
        return error.response.data;
      }
      else{
        logger.error(error);
        return {isSuccessful: false} as T;
      }
    }
  }

  async authenticateCredentials(username: string, password: string): Promise<boolean> {
    let result: boolean = true;
    try {
      const {data} = await axios.post<Token>(this.url("/api/auth/login"), {
        'username': username,
        'password': password
      }, {
        headers: {'Content-Type': 'application/json'},
      });
      this.storeToken(data);
    } catch (error:any) {
      if(error.response){
        // @ts-ignore Mitigated by if-statement.
        logger.error("API Error", error.response.data);
      }
      else{
        logger.error(error);
      }
      result = false;
    }

    return result;
  }


  async authenticatePortal(): Promise<boolean> {
    return true;
  }

  async makeFileRequest(path: string): Promise<Response> {
    if (!this.isAuthenticated()) {
      /**
       * TODO: Implement Refresh Token if Desired.
       */
      throw new Error("Authentication is required!")
    }
    const headers = {Authorization: `Bearer ${this.getToken().token}`, "Content-Type": "application/json"};
    return await fetch(this.url(path), {headers});
  }

  /**
   * Promise that reset a user password and returns TRUE if successful and FALSE otherwise.
   * @param resetData
   */
  async resetPassword(resetData: ResetPasswordData): Promise<boolean> {
    let result: boolean = true;
    try {
        const { data } = await axios.post<Token>(this.url("/api/auth/resetpassword"), {
            'token': resetData.token,
            'password': resetData.password,
            'confirmpassword': resetData.confirmedPassword   
      });
      this.storeToken(data);
      result = true;
    } catch (error) {
      result = false;
    }
    return result;
  }
    
  /**
   * Promise that checks password reset token 
   * @param token
   */
    async checktoken(token: string): Promise<boolean> {
    let result: boolean = true;
    try {
        const { data } = await axios.get<Token>(this.url("/api/auth/checktoken?token="+token));
      result = true;
    } catch (error) {
      result = false;
    }
    return result;
  }
    

  /**
   * Promise that registers a user and returns TRUE if successful and FALSE otherwise.
   * @param registrationData
   */
  async register(registrationData: RegistrationData): Promise<boolean> {
    let result: boolean = true;
    try {
      const {data} = await axios.post<Token>(this.url("/api/auth/register"), {
        'username': registrationData.emailAddress,
        'password': registrationData.password,
        'termsAccepted': registrationData.termsAccepted
      });
      this.storeToken(data);
      result = true;
    } catch (error) {
      result = false;
    }
    return result;
  }

    /**
   * Promise that makes request for email with password recover link
   * @param registrationData
   */
    async forgotpassword(email: string): Promise<boolean> {
        let result: boolean = true;
        try {
            const { data } = await axios.post<Token>(this.url("/api/auth/forgotpassword"), {
                'username': email,
            });
            result = true;
        } catch (error) {
            result = false;
        }
        return result;
    }


  async fetchProductInfo(productKey: string): Promise<SerializedProduct> {
    return Timers.delayFor<SerializedProduct>(1000,
      () => {
        return ProductsStub[productKey as keyof typeof ProductsStub];
      });
  }


  async fetchApplication(productKey: string): Promise<ApplicationDto> {
    return await this.makeAuthenticatedRequest<ApplicationDto>(
      "GET",
      "/api/Application",
      {product: productKey}
    );
  }

  async saveDemographicInfo(dto: DemographicDto, productKey: string): Promise<ApiResponse> {
    return await this.makeAuthenticatedRequest<ApiResponse>(
      "POST",
      "/api/Application/Demographic",
      {...dto}
    );
  }

  async saveTermLifeCoverageInfo(dto: CoverageDto, productKey: string): Promise<ApiSuccessResponse> {
    return await this.makeAuthenticatedRequest<ApiSuccessResponse>(
      "POST",
      `/api/Application/MemberCoverage`,
      dto
    )
  }

  async saveApplication(id: number): Promise<ApiSuccessResponse> {
      return await axios.get(this.url('/api/Payment/pdm/'+id));
  }

  async saveCoverageInfo(dto: CoverageDto, productKey: string): Promise<ApiSuccessResponse> {
    return await this.makeAuthenticatedRequest<ApiSuccessResponse>("POST", '/api/Application/MemberCoverage', dto);
  }


  async saveSpouseInfo(dto: SpouseDto): Promise<ApiSuccessResponse> {
    return await this.makeAuthenticatedRequest<ApiSuccessResponse>("POST", '/api/Application/Spouse', dto);
  }


  async saveDependentInfo(dto: DependentApiDto, wantsEnroll: boolean): Promise<ApiSuccessResponse> {
    // const params = [...dto.dependents.map(d => {
    //   return {
    //     ApplicationId: d.applicationId,
    //     Gender: d.gender,
    //     DateOfBirth: d.dateOfBirth,
    //     FirstName: d.firstName,
    //     MiddleName: d.middleName,
    //     LastName: d.lastName,
    //     BenefitAmount: d.benefitAmount
    //   }
    // })
    // ];

    return await this.makeAuthenticatedRequest<ApiSuccessResponse>("POST", `/api/Application/Dependents?enroll=${wantsEnroll}`, dto);
  }

  async saveBeneficiary(dto: BeneficiaryDto) {
    return await this.makeAuthenticatedRequest<ApiSuccessResponse>("POST", `/api/Application/Beneficiary`, dto);
  }

  async saveUnderwriting(dto: HealthQuestionDto[]) {
    const data = dto.map(x => {
      const answer = `${x.answer}`;
      const transform = {...x, answer: answer, answer_to: x.answer_To}
      return transform;
    })
    return await this.makeAuthenticatedRequest<ApiSuccessResponse>("POST", `/api/Application/Underwriting`, data);
  }

  async fetchDeclarations(appId: number, uwReferral: boolean): Promise<Array<DeclarationDto>> {
    return await this.makeAuthenticatedRequest<Array<DeclarationDto>>("GET", `/api/Application/ProductDeclaration?id=${appId}&uwReferral=${uwReferral}`, {});
  }

  async fetchWarnings(appId: number): Promise<Array<FraudWarningDto>> {
    return await this.makeAuthenticatedRequest<Array<FraudWarningDto>>("GET", `/api/Application/ProductFraudWarning?id=${appId}`, {});
  }

  async saveSignature(dto: SignatureDto){
    return await this.makeAuthenticatedRequest<ApiSuccessResponse>("POST", '/api/Application/Sign', dto);
  }

  async saveAuthorization(dto: AuthorizationsDto){
    return await this.makeAuthenticatedRequest<ApiSuccessResponse>("POST", '/api/Application/Auth', dto);
  }

  async requestPaymentUrl(dto: PaymentRequestDto): Promise<PaymentPlanDto> {
    return await this.makeAuthenticatedRequest("GET", `/api/Payment/Request?applicationId=${dto.applicationId}&frequency=${dto.frequency}`, {});
  }

  async fetchRates(applicationId: number): Promise<RateDto[]> {
    return await this.makeAuthenticatedRequest("GET", `/api/Application/Rates?id=${applicationId}`, {})
  }

  async saveHealthData(dto: HealthDataDto): Promise<ApiSuccessResponse> {
    return await this.makeAuthenticatedRequest("POST", `/api/Application/Healthdata`, dto);
  }

  async confirmPayment(applicationId: number, status: number): Promise<PaymentStatus> {
    return await this.makeAuthenticatedRequest("GET", `/api/Payment/Confirm?applicationId=${applicationId}&status=${status}`, {});
  }

  async confirmGiApplication(applicationId: number, uwReferral: boolean = false): Promise<PaymentStatus> {
    return await this.makeAuthenticatedRequest("GET", `/api/Payment/ConfirmGI?applicationId=${applicationId}&status=2`, {});
  }

  async saveOptIns(dto: OptInDto) :Promise<ApiSuccessResponse>{
    return await this.makeAuthenticatedRequest("POST", "/api/Application/Opt", dto);
  }

  async fetchAppPdf(id: number): Promise<Response>{
     return this.makeFileRequest("/api/Application/Pdf?applicationId="+id);
  }
}
