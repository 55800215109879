
import {EnrollmentSequence} from "./components/Sequence/EnrollmentSequence";
import ApplyPage from "./components/Pages/ApplyPage";
import {useNavigate} from "react-router-dom";
import LogOut from "./components/Pages/LogOut";
import Loading from "./components/Transitions/Loading";
import IndexPage from "./components/Pages/IndexPage";
import TestPage from "./tests/TestPage";
import PaymentComplete from "./components/Pages/PaymentComplete";
import ConfirmationPage from "./components/Pages/ConfirmationPage";
import AgreementToDoBusiness from "./components/Pages/AgreementToDoBusiness";
import ResetPassword from "./components/Forms/ResetPassword";
import UiStencil from "./components/Pages/UiStencil";






const AppRoutes: Array<any> = [
    {
      path: '/test',
      element: <TestPage/>,
    },
    {
        path: '/stencil',
        element: <UiStencil />
    },
    {
        path: '/agreement-to-do-business-with-gallagher',
        element: <AgreementToDoBusiness />
    },
    {
        path: '/confirmation',
        element: <ConfirmationPage/>,
    },
    {
      path: '/apply/:productKey',
      element: <ApplyPage />
    },
    {
        path: '/:productKey',
        element: <EnrollmentSequence sponsor="American Federation of Teachers" />
    },
    {
        path: ':productKey/payment-complete',
        element: <PaymentComplete />
    },
    {
        path: '/logout',
        element: <LogOut />
    },
    {
        path: '/',
        element: <IndexPage />,
        index: true
    },
    {
        path: '/reset-password/:token',
        element: <ResetPassword />,
        index: true
    }
];

export default AppRoutes;