import React, {Component, useContext, useState} from 'react';
import {Row, Col, Stack, ButtonGroup, Carousel} from 'react-bootstrap';
import {Container} from 'reactstrap';
import AftLogo from './components/Images/AftLogo';
import {ProgressBar} from "./components/Progress/ProgressBar";
import {Page} from "./types/page";
import {faDoorOpen} from "@fortawesome/free-solid-svg-icons/faDoorOpen";
import IconButton from "./components/Buttons/IconButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {PhoneNumberInline} from "./components/Utility/Layout";
import {ProgressButtonProps} from "./components/Buttons/ProgressButton";
import AlertApiValidation from "./components/Alerts/AlertApiValidation";
import ServerSideValidationAlert from "./services/session/alerts";
import SessionContext from "./services/session/session";

//import { NavMenu } from './NavMenu';

export interface LayoutProps {
    children: any;
}

export interface ApplicationLayoutProps extends LayoutProps {
    productName: string;
    progressButtons: Array<any>;
}


export class ProgressStats {
    min: number = 0;
    max: number = 100;
    now: number = 0;

    constructor(now: number) {
        this.now = now;
    }
}


export function RegistrationLayout(props: LayoutProps) {
    return (
        <div className="d-flex min-vh-100 align-items-center">
            <Container>
                {props.children}
            </Container>
        </div>
    )
}


export function ApplicationLayout(props: ApplicationLayoutProps) {
    const [index, setIndex] = useState(0);
    const ssvAlert = useContext(ServerSideValidationAlert);
    const session = useContext(SessionContext);
    const handleSelect = (selectedIndex: number, e: any) => {
        setIndex(selectedIndex);
    };
    return (

        <div className="d-flex bg-light flex-column pt-3  page-container" >
            <Container className="pb-5">
                <Row className="d-flex justify-content-between align-items-center">
                    <Col xs={6}  lg={4}  className="align-items-center">
                        <AftLogo />
                    </Col>
                    <Col className="d-flex flex-column justify-content-center align-items-end">
                        <div>
                            <p className="h2">Enrollment Form</p>
                            <p className="text-primary h2">{props.productName}</p>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Container className="d-flex align-items-center">
                <Stack gap={3}>
                    <Row>
                        <Col className="d-flex justify-content-center">
                            <ButtonGroup className="btn-group-progress">
                                {props.progressButtons}
                            </ButtonGroup>
                        </Col>
                    </Row>
                    {session.data.errors.showAlert ? <AlertApiValidation errors={session.data.errors.errors} /> : null}
                    <Row>

                    </Row>
                    <Row>
                        <Col>
                            {props.children}
                        </Col>
                    </Row>
                </Stack>
            </Container>
            <footer className="bg-primary text-light mt-3 mt-lg-0" >
                <Container className="mb-0">
                    <Row className="justify-content-center justify-content-sm-between">
                        <Col xs={12} sm={6} className="d-flex justify-content-center justify-content-sm-start align-items-center">
                            <p className="mb-0">Customer Service: <PhoneNumberInline phoneNumber={`(888) 423-8700`} className="text-light" />. <br className="d-xlg-none" /> Monday - Friday 8 a.m. - 8 p.m. EST</p>
                        </Col>
                        <Col className="d-flex justify-content-center justify-content-sm-end align-items-center">
                            <p className="mb-0">&copy; {new Date().getFullYear()} Gallagher Affinity. <a href="https://www.ajg.com/privacy-policy" target="_blank" className="text-light">View Privacy Policy</a></p>
                        </Col>
                    </Row>
                </Container>
            </footer>
        </div>

    )
}