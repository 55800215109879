import {Form} from "react-bootstrap";
import React from "react";
import exp from "constants";
import {ApiValidationErrorResponse} from "../services/api/api";
import {BMIChart} from "../stub/BMIChart";

export enum ValidationMessages {
    ZIP_CODE= "Please enter a zip code with 5 digits.",
    SSN = "Please enter a SSN with 9 digits.",
}

export function isBmiValid(heightInches: number, weight: number): boolean {
    if (!BMIChart.has(heightInches)) {
        return false;
    }
    return weight <= BMIChart.get(heightInches).weight;
}




export interface FormValidator {
    validate(f:HTMLFormElement): boolean;
}

export const performBrowserValidation = (f: HTMLFormElement) :boolean=> {
    let a = f.elements as HTMLFormControlsCollection;
    for(let i = 0; i < a.length; i++){
        let e = a[i] as HTMLInputElement;
        e.setCustomValidity("");
    }

    // a.forEach(i => {
    //     i.setCustomValidity("");
    // })
    return f.checkValidity()
}


abstract class Validator implements FormValidator{
    protected abstract complexValidation(f:HTMLFormElement):boolean;
    validate(f: HTMLFormElement): boolean{
        let browserResult = performBrowserValidation(f);
        let complexResult = this.complexValidation(f);
        return browserResult && complexResult;
    }
}

export class LogInValidator extends Validator {
    //Browser validation handles all of our needs.
    protected complexValidation(f: HTMLFormElement): boolean {
        return true;
    }
}

export class RegisterValidator extends Validator {
    //Browser validation handles all of our needs.
    protected complexValidation(f: HTMLFormElement): boolean {
        return true;
    }
}
type DemographicRules = {
    phoneNumber: any,
    altPhoneNumber: any,
    state: any,
}
export class DemographicsValidator extends Validator {

    private rules: DemographicRules = {
        phoneNumber: [PhoneRule],
        altPhoneNumber: [PhoneRule],
        state: [],
    };


    protected complexValidation(f: HTMLFormElement): boolean {
        let ruleKeys = Object.keys(this.rules);
        let result = true;
        ruleKeys.forEach((key) => {
            let k = key as keyof DemographicRules;
            let input = f.elements.namedItem(key) as HTMLInputElement;
            let value = input.value;
            let rules = this.rules[k];
            let rResult = true;

            rules.forEach((p:any) => {
                let r = new p(value);
                if(!rResult){
                    return;
                }
                //If input is not required and the value is empty, bypass validation.
                if(!input.hasAttribute("required") && value === ""){
                    return;
                }
                rResult = rResult && r.passes();
                if(!rResult){
                    input.setCustomValidity(r.message());
                }
            });
            result = result && rResult;
        });
        return result;
    }
}

export class LtdDemographicsValidator extends Validator {

    private rules: DemographicRules = {
        phoneNumber: [PhoneRule],
        altPhoneNumber: [PhoneRule],
        state: [USStateNotInBlacklist]
    };


    protected complexValidation(f: HTMLFormElement): boolean {
        let ruleKeys = Object.keys(this.rules);
        let result = true;
        ruleKeys.forEach((key) => {
            let k = key as keyof DemographicRules;
            let input = f.elements.namedItem(key) as HTMLInputElement;
            let value = input.value;
            let rules = this.rules[k];
            let rResult = true;

            rules.forEach((p:any) => {
                let r = new p(value);
                if(!rResult){
                    return;
                }
                //If input is not required and the value is empty, bypass validation.
                if(!input.hasAttribute("required") && value === ""){
                    return;
                }
                rResult = rResult && r.passes();
                if(!rResult){
                    input.setCustomValidity(r.message());
                }
            });
            result = result && rResult;
        });
        return result;
    }
}

type BeneficiaryRules = {
    phone: any[],
}
export class BeneficiaryModalFormValidator extends Validator {
    private rules: BeneficiaryRules = {
        phone: [PhoneRule],
    };

    protected complexValidation(f: HTMLFormElement): boolean {
        let ruleKeys = Object.keys(this.rules);
        let result = true;
        ruleKeys.forEach((key) => {
            let k = key as keyof BeneficiaryRules;
            let input = f.elements.namedItem(key) as HTMLInputElement;
            let value = input.value;
            let rules = this.rules[k];
            let rResult = true;

            rules.forEach((p:any) => {
                let r = new p(value);
                if(!rResult){
                    return;
                }
                //If input is not required and the value is empty, bypass validation.
                if(!input.hasAttribute("required") && value === ""){
                    return;
                }
                rResult = rResult && r.passes();
                if(!rResult){
                    input.setCustomValidity(r.message());
                }
            });
            result = result && rResult;
        });
        return result;
    }

}


export class HtmlFormValidator extends Validator {
    protected complexValidation(f: HTMLFormElement): boolean {
        return true;
    }
}



export enum ValidationMessages {
    REQUIRED = "This field is required.",
    EMAIL = "Please enter a valid email address",
}

export interface Rule {
    passes(): boolean
    message(): string;
}

export abstract class ValidationRule implements Rule {
    abstract passes(): boolean;
    abstract message(): string;
    protected _value: any = "";
    constructor(value: any) {
        this._value = value;
    }
}


export class PhoneRule extends ValidationRule{
    message(): string {
        return "Please enter a phone number with 10 digits.";
    }

    passes(): boolean {
        let phone:string = this._value.replace(/\D/g,'');
        return phone.length === 10;
    }

}


export class USStateNotInBlacklist extends ValidationRule{
    message(): string {
        return "Coverage is not available in your state or territory."
    }

    passes(): boolean{
        let state: string = this._value;
        const allowedStates = ['UT', 'PR', 'VI'];
        return allowedStates.filter(x => x.toLowerCase() === state.toLowerCase()).length === 0
    }
}







