import Loading from "../Transitions/Loading";
import {useContext} from "react";
import SessionContext from "../../services/session/session";

type PaymentCompleteProps = {

};
const PaymentComplete = (props: PaymentCompleteProps) => {
  window.parent.postMessage('PaymentComplete');
  return (
    <div>
      <Loading message={`Submitting your order...`}/>
    </div>
  );
}
export default PaymentComplete