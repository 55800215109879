import {Button, Card, Col, Row} from "react-bootstrap";
import SequenceButtons from "../Sequence/SequenceButtons";
import IconButton from "../Buttons/IconButton";
import {faDoorClosed} from "@fortawesome/free-solid-svg-icons";
import {faDoorOpen} from "@fortawesome/free-solid-svg-icons/faDoorOpen";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useNavigate} from "react-router-dom";
import {useContext, useMemo, useState} from "react";
import ServerSideValidationAlert, {initAlert} from "../../services/session/alerts";
import Loading from "../Transitions/Loading";
import {ScreenCardSavingContext} from "../../services/api/saving";
import AlertDanger from "../Alerts/AlertDanger";

export type ScreenCardProps = {
  children: any;
  showNext: boolean;
  showPrevious: boolean;
  showSubmit: boolean;
  onNext(): void;
  onPrevious(): void;
  onSubmit(): void;
  title: string;
  showRequiredField: boolean;
};
const ScreenCard = (props: ScreenCardProps) => {

  const {saving} = useContext(ScreenCardSavingContext);
  // const value = useMemo(() => ({saving, setSaving}), [saving])
  const navigate = useNavigate();
  const handleLogout = () => {
    navigate("/logout");
  }
  const ssvAlert = useContext(ServerSideValidationAlert);
  const clearAlert = () => {
    ssvAlert.alert = initAlert;
  }
  const onNext = () => {
    clearAlert();

    props.onNext();

  }


  const onPrevious = () => {
    clearAlert();
    props.onPrevious();
  }

  const onSubmit = () => {
    clearAlert();
    props.onSubmit();
  }




  return (

      <Card className="card-screen">
        <Card.Header className="py-0 pe-0 border-bottom-0">
          <Card.Title className="d-flex justify-content-between  mb-0">
            <h1 className="py-3 pe-1 card-sequence-title">{props.title}</h1>
            <Button className="rounded-0 btn-logout" onClick={handleLogout} aria-label="Log Out" variant="secondary">
              <FontAwesomeIcon icon={faDoorOpen} className="me-1"/>
              Log Out
            </Button>
          </Card.Title>
        </Card.Header>
        <Card.Body>
          <RequiredField show={props.showRequiredField} />
          {saving.showError ? <AlertDanger message={saving.errorMessage} /> : null}
          {saving.saving ? <Loading message={"Saving information..."}/> : null}
          {!saving.saving ? props.children : null}
        </Card.Body>
        <Card.Footer className="p-0">
          <SequenceButtons showNext={props.showNext} showPrevious={props.showPrevious}
                           showSubmit={props.showSubmit} onNext={onNext} onPrevious={onPrevious}
                           onSubmit={onSubmit}/>
        </Card.Footer>
      </Card>

  );
}
ScreenCard.defaultProps = {
  showRequiredField: true
}
type RequiredFieldProps = {
  show: boolean
}
const RequiredField = (p: RequiredFieldProps) => {
  if(p.show){
    return  (
      <div className="w-100">
        <p className={"mb-0 pb-3"}><span className="text-danger">*</span> indicates a required field.</p>
      </div>
    );
  }
  else{
    return null;
  }
}
export default ScreenCard;

