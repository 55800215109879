import {ApiValidationError} from "../../services/api/api";
import AlertDanger from "./AlertDanger";
import {Alert} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTriangleExclamation} from "@fortawesome/free-solid-svg-icons";

type AlertApiValidationProps = {
    errors: ApiValidationError
};

const GenericErrorAlert =() =>  {
    return (
        <AlertDanger message={"An error has occurred. Please try again later."} />
    )
}
type ErrorSetProps = {
  key: string,
  values: Array<string>
}
const ErrorSet = (props: ErrorSetProps) => {
  const {key, values} = props;
  return <>
    <strong>{key}</strong>
    <ul>
      {values.map(x => <li>{x}</li>)}
    </ul>
  </>
}

const AlertApiValidation = (props: AlertApiValidationProps) => {
    if(Object.keys(props.errors).filter(i => i === 'GenericError' || i === 'ApplicationId').length > 0){
      return <GenericErrorAlert />
    }
  return (
    <>
      <Alert variant="danger" className="d-flex align-items-center">
        <FontAwesomeIcon icon={faTriangleExclamation} size="2x" className="me-3"/>
        {Object.keys(props.errors).map(x => <ErrorSet key={x} values={props.errors[x]}  />)}
      </Alert>
    </>
  );
}
export default AlertApiValidation;