import {Col, Row} from "react-bootstrap";
import {ApplicationLayout} from "../../Layouts";
import {useContext, useState} from "react";
import SessionContext, {SessionHelper} from "../../services/session/session";
import ScreenCard from "../Cards/ScreenCard";
import Listeners from "../../helpers/listeners";
import AlertWarning from "../Alerts/AlertWarning";
import {CoverageTable} from "./SummaryPage";
import {ProductId} from "../../types/product";
import ApplicationDownloadButton from "../Buttons/AppPdfButton";
import {TimeSpan} from "../../helpers/time";
import {PhoneNumberInline} from "../Utility/Layout";

export type ConfirmationProps = {
  memberName: string,
  productDisplayName: string,
  hasBeneficiaries: boolean,
  isUWReferral: boolean
}
const ConfirmationPage = () => {
  const session = useContext(SessionContext);
  const data: ConfirmationProps = {
    memberName: SessionHelper.memberName(session),
    productDisplayName: session.data.product.displayName,
    hasBeneficiaries: session.data.product.id === ProductId.LTD_DISABILITY || session.data.beneficiaries.length > 0,
    isUWReferral: SessionHelper.hasMemberFailedUw(session) || SessionHelper.hasSpouseFailedUw(session)
  }
  const {memberName, productDisplayName, hasBeneficiaries} = data;
  const scProps = {
    showNext: false,
    showPrevious: false,
    showSubmit: false,
    onNext: Listeners.doNothing,
    onPrevious: Listeners.doNothing,
    onSubmit: Listeners.doNothing,
    title: 'Order Confirmation',
    showRequiredField: false
  }

  const host = window.location.host;
  let portalDomain = 'my.aftbenefits.org';
  if (host === 'enrollstage.aftbenefits.org') {
    portalDomain = 'mystage.aftbenefits.org';
  }
  if (host === 'enrolldev.aftbenefits.org') {
    portalDomain = 'mydev-aft.amer.ajgco.com';
  }


  return (
    <ApplicationLayout productName={productDisplayName} progressButtons={[]}>
      <ScreenCard {...scProps}>
        <div className={"confirmation"}>
          {session.data.product.id === ProductId.GI_TERM_LIFE ? <WithoutPayment {...data} /> :
            <WithPayment {...data} />}
          <Row>
            <Col>
              <h3>Summary of your Coverage:</h3>
              <CoverageTable/>
              <p>If you have any questions, please feel free to contact us.</p>
              <p>We thank you for the opportunity to be of service.</p>
              <p>Customer service: <PhoneNumberInline phoneNumber="(888) 423-8700" className=""/></p>
              <p>Monday - Friday 8:00am - 8:00pm EST</p>
            </Col>
          </Row>
          <Row>
            <Col>
              <ConfApplicationDownload applicationId={session.data.applicationId} pdmSuccess={session.data.pdmSuccess}/>
            </Col>
          </Row>
        </div>
      </ScreenCard>
    </ApplicationLayout>
  );
}


const WithPayment = (props: ConfirmationProps) => {
  return (
    <Row>
      <h2>Thank you for choosing AFT + Member Benefits for your insurance needs.</h2>
      <p>Your {props.productDisplayName} application has been submitted. Your policy will be issued following review. This review may include referral to the carrier for final determination.</p>
      <p>Should any additional information be required, our team will contact you directly via the contact
        information you provided on this application. Please allow 3 to 5 business days to complete the review
        process.</p>
      <p>Please note that your coverage with MetLife is only effective if you meet eligibility requirements. You will not be billed until your application has been approved.</p>
    </Row>
  );
};

const WithoutPayment = (props: ConfirmationProps) => {
  return (
    <Row>
      <h2>Thank you for choosing AFT + Member Benefits for your insurance needs.</h2>
      <p>Your {props.productDisplayName} application has been submitted. Your policy will be issued following review.
        This review may include referral to the carrier for final determination.</p>
      <p>Should any additional information be required, our team will contact you directly via the contact
        information you provided on this application. Please allow 3 to 5 business days to complete the review
        process.</p>
      <p>Please note: coverage is only active if you meet the requirements and your application is approved.</p>
    </Row>
  );
}


type ApplicationReferredToUWProps = {
  productId: ProductId
}
const ApplicationReferredToUW = () => {

  return (
    <p>
      Thank you for choosing AFT for your insurance needs. Your application is pending review by the underwriting team.
      Should any additional information be required, the underwriting team will contact you directly.
      Please allow 7 to 10 business days for the review process.
    </p>
  )
}

const ApplicationApproved = () => {
  return (
    <p>Thank you for choosing AFT for your insurance needs. Your application has been approved.
      Your policy will be issued following successful payment.</p>
  )
}

type AppProps = {
  pdmSuccess: boolean,
  applicationId: number
}
export const ConfApplicationDownload = (p: AppProps) => {
  const [showDownloadButton, setShowDownloadButton] = useState(p.pdmSuccess);

  if (showDownloadButton) {
    setTimeout(() => {
      setShowDownloadButton(false);
    }, TimeSpan.convertMinutesToMs(15))
  }
  if (showDownloadButton) {
    return (<ApplicationDownloadButton appId={p.applicationId} onError={() => {
      setShowDownloadButton(false)
    }}/>)
  }
  return (<UnableToDownload/>);
}

const UnableToDownload = () => {
  return (
    <AlertWarning>We were unable to retrieve your application document at this time. Please contact customer service to
      assist with a copy of your application.</AlertWarning>
  )
}


export default ConfirmationPage;