/**
 * O(1) collection implementation.
 */
export type CollectionItem = {
  lookupKey: string|number;
}
export class Collection<T extends CollectionItem> {
  private _items: { [key: string | number]: T } = {};

  constructor(items: Array<T> = []) {
    items.forEach((item) => {
      this._items[item.lookupKey] = item;
    });
  }

  get length():number {
    return Object.values(this._items).length;
  }

  toArray():Array<T>{
    return Object.values(this._items);
  }

    get(key: string | number): T {
        if (key && key.toString().includes('uuid')) {
            let len = key.toString().indexOf('uuid');
            const key2 = key.toString().substring(0, len-1);
            return this._items[key2];
        }
    return this._items[key];
    }

    has(key: string | number) {
        if (key && key.toString().includes('uuid')) {
            let len = key.toString().indexOf('uuid');
            const key2 = key.toString().substring(0, len - 1);
            return this._items[key2];
        }
    return this._items.hasOwnProperty(key);
  }

  each(callback: (item: T) => void) {
    Object.values(this._items).forEach(callback);
  }

  map<U extends CollectionItem>(callback: (item: T) => U): Collection<U> {
    return new Collection<U>(Object.values(this._items).map(callback));
  }

  filter(callback: (item: T) => boolean): Collection<T> {
    return new Collection<T>(this.toArray().filter(callback));
  }

  /**
   * Puts an item in the collection. If there is a collision, the entry is replaced.
   * @param item
   * @param key
   */
  put(item:T):Collection<T>{
    this._items[item.lookupKey] = item;
    return this;
  }

  remove(key: string|number): Collection<T> {
    delete this._items[key];
    return this;
  }

  first(): T{
    return this.toArray()[0];
  }

  merge<U extends CollectionItem>(collection: Collection<U>): Collection<T | U> {
    const mergedCollection = new Collection<T | U>();
    this.each((item) => mergedCollection.put(item));
    collection.each((item) => mergedCollection.put(item));
    return mergedCollection;
  }
}


