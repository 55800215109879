import {FormValidator} from "../../helpers/validator";
import {Form} from "react-bootstrap";
import React, {useState} from "react";
import ConsoleLogger from "../../services/logging/logger";
import Loading from "../Transitions/Loading";

type FormWithValidationProps = {
    validator: FormValidator,
    controlId: string;
    children: any;
    onSubmit(e: React.FormEvent<HTMLFormElement>):void;
};
const logger = new ConsoleLogger("FormWithValidation");
const FormWithValidation = (props: FormWithValidationProps) => {
    const [isValidated, setIsValidated] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        setSubmitting(true);
        logger.debug("Internal submit");
        e.preventDefault();
        e.stopPropagation();
        let result = props.validator.validate(e.target as HTMLFormElement);
        setIsValidated(true);
        if(!result){
            setSubmitting(false);
            return;
        }
        props.onSubmit(e);
        setSubmitting(false);
    }
    return (
        <Form id={props.controlId} noValidate validated={isValidated} onSubmit={handleSubmit}>
            {submitting ? <Loading message="Saving..." /> : null}
            {props.children}
        </Form>
    );
}
export default FormWithValidation;