import React, {useContext, useState} from 'react';
import Form from 'react-bootstrap/Form';
import {Button, Col, Row} from "react-bootstrap";
import {AuthenticationMethod, DirectLogInAuthentication} from "../../services/auth/authentication";
import {JwtApiClient} from "../../services/api/api";
import AlertDanger from "../Alerts/AlertDanger";
import {InputEvent} from "../../types/events";
import {TextInput} from "./Inputs/TextInput";
import Loading from "../Transitions/Loading";
import {RegisterValidator} from "../../helpers/validator";
import FormWithValidation from "./FormWithValidation";
import SessionContext, {SessionHelper} from "../../services/session/session";
import YesNoSwitch, {YesNoOptions} from "./Inputs/YesNoSwitch";

type RegistrationProps = {
  onRegisterSuccess(): void;
};



const Register = (props: RegistrationProps) => {
    const initialState = {
        emailAddress: "",
        password: "",
        confirmedPassword: "",
        aftMembershipDate: "",
        isFailed: false, isLoading: false,
        loadingMessage: "Registering...",
        isValid: false,
        termsAccepted: false

    }

    const session = useContext(SessionContext);

    const [state, setState] = useState(initialState);
    const setValid = (isValid: boolean) => {
        setState(p => ({...p, isValid: isValid}));
    }
    const handleChange = (e: InputEvent) => {
        let {name, value} = e.target;
        setState(prevState => ({...prevState, [name]: value}));
    }

    const setLoading= (isLoading: boolean, message: string):void => {
        const m = message ?? '';
        setState(p => ({...p, isLoading: isLoading, loadingMessage: m}));
    }
    const validator =  new RegisterValidator();

    let authMethod: AuthenticationMethod = new DirectLogInAuthentication();
    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        e.stopPropagation();
        let isInvalid = !validator.validate(e.target as HTMLFormElement);
        setValid(true);
        if(isInvalid){
            return;
        }
        setLoading(true, state.loadingMessage);
        authMethod.register(state).then(fulfilled => {
            setState(prevState => ({...prevState, isFailed: !fulfilled}));
            if(fulfilled){
                setLoading(true, "Creating your application...");
                (new JwtApiClient()).fetchApplication(session.data.product.productKey)
                  .then(SessionHelper.setFromApiResponse(session, state.emailAddress));
                props.onRegisterSuccess();
                return;
            }
            setLoading(false, initialState.loadingMessage);
        });
    }
    const tos = (<p>I accept the <a target={`_blank`} href={`/terms-of-service.pdf`}>Terms of Service</a> and <a target={`_blank`} href={`https://www.ajg.com/us/privacy-policy/`}>Privacy Policy</a>.</p>)

    const alert = state.isFailed ?  (<AlertDanger message="We were unable to register an account with the provided information. Please try again later." />):null;
    return (
        <>
            {state.isLoading ? <Loading message={state.loadingMessage} /> : null }
        {alert}
        <FormWithValidation controlId="form-register" validator={validator} onSubmit={handleSubmit}>
            <Form.Group controlId="email-address-register" className="mb-3">
                <TextInput label="Email Address" onChange={handleChange}
                           value={state.emailAddress} placeholder="Enter Email Address" name="emailAddress" controlId="reg-email" type="email" />

            </Form.Group>
            <Row>
                <Col xs={12} sm={6}>
                    <TextInput label="Password" onChange={handleChange}
                               value={state.password} placeholder="Enter Password" name="password" controlId="reg-password" type="password" />
                </Col>
                <Col xs={12} sm={6}>
                    <TextInput label="Confirm Password" onChange={handleChange} invalidMessage="Please make sure this field is filled out and matches the password field."
                               value={state.confirmedPassword} pattern={state.password} placeholder="Confirm Password" name="confirmedPassword" controlId="reg-confirm-password" type="password" />
                </Col>
            </Row>
            <Row>
                <Col>
                    <YesNoSwitch invalidMessage='Please accept the privacy policy and the terms and conditions.' required={true} label={tos} onNoSelected={() => {setState(prev => ({...prev, termsAccepted: false }))}} value={state.termsAccepted ? YesNoOptions.Yes: YesNoOptions.No} onYesSelected={() => {setState(prev => ({...prev, termsAccepted: true }))}} name={`termsAccepted`} controlId={`reg-terms-accepted`} />
                </Col>
            </Row>
            <Button variant="primary" className="w-100 mb-3" type="submit">Register</Button>
        </FormWithValidation>
        </>
    )
}
export default Register;


