import {faCog} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ProgressBar from "react-bootstrap/ProgressBar";

export type LoadingProps = {
    message: string
};
const Loading = (props: LoadingProps) => {
    return (
        <div className="loading border-1">
            <p className="mb-0 w-75">
                <FontAwesomeIcon spin={true} icon={faCog} className="me-3"/>
                {props.message}</p>
            <ProgressBar className="w-75" now={100} animated key="loading-bar"/>
        </div>
    );
}
Loading.defaultProps = {
    message: "Loading..."
}
export default Loading;