/* React Context which maintains a boolean variable named isSaving. */
import React, {Dispatch, SetStateAction} from "react";
type SaveInfo = {
  saving: boolean,
  showError: boolean,
  errorMessage: string
}
export type Saving = {
  saving: SaveInfo
  setSaving: Dispatch<SetStateAction<SaveInfo>>
};






export const ScreenCardSavingContext = React.createContext({} as Saving);
export const ScreenCardSavingContextProvider = ScreenCardSavingContext.Provider;
