import React, {Component, useContext, useState} from 'react';
import {Col, Row} from "react-bootstrap";
import AftLogo from "../Images/AftLogo";
import {RegistrationLayout} from "../../Layouts";
import {Page} from "../../types/page";
import LogInRegisterCard, {LoginRegisterProps} from "../Cards/LogInRegisterCard";
import Product from "../../types/product";
import {useNavigate, useParams} from "react-router-dom";
import {JwtApiClient, SerializedProduct} from "../../services/api/api";
import Loading from "../Transitions/Loading";
import {Session} from "inspector";
import SessionContext, {initialSession} from "../../services/session/session";
import ProductsStub from "../../stub/Products";


const ApplyPage = () => {

    let {productKey} = useParams();
    const api = new JwtApiClient();
    const session = useContext(SessionContext);

    const [isLoading, setIsLoading] = useState(true);

    api.fetchProductInfo(productKey as string).then((r: SerializedProduct) => {
        let s = {...initialSession};
        session.data = s;
        session.data.product = r;
        setIsLoading(false);
    });

    document.title = `AFT Benefits | ${session.data.product.displayName} Enrollment Application`;

    const loading = isLoading ? <Loading message="Loading Product Information..." />: null;

    const navigate = useNavigate();
    const navigateToProduct = () => {
        navigate(`/${productKey as string}`);
    }
    return  (
        <RegistrationLayout>
            {loading}
            <Row className="flex-column flex-lg-row align-items-center">
                <Col xs={12} md={12} lg={4}  xxl={6} className="text-center text-lg-start">
                    <AftLogo className="w-50"/>
                    <h1>Welcome!</h1>
                    <h2 className="text-muted">{session.data.product.displayName}</h2>
                    <hr className="d-block d-sm-none"/>
                </Col>
                <Col>
                    <LogInRegisterCard onLoginSuccess={navigateToProduct} onRegisterSuccess={navigateToProduct} />
                </Col>
            </Row>
        </RegistrationLayout>
    );
}
export default ApplyPage;
