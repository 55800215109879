import {Button, OverlayTrigger, Tooltip} from "react-bootstrap";
import {IconButtonProps} from "../../types/buttons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const IconButton = (props: IconButtonProps) => {
  const label = props.showLabel ? <span className="ms-2"><strong>{props.label}</strong></span>: null;
    return (
        // <OverlayTrigger
        //     key={props.controlId}
        //     placement="bottom"
        //     trigger="hover"
        //     overlay={
        //         <Tooltip id={`tooltip-${props.controlId}`}>
        //             {props.label}
        //         </Tooltip>
        //     }
        // >
        //     <Button onClick={props.onClick} aria-label={props.label} variant={props.variant}>
        //         <FontAwesomeIcon icon={props.icon} />
        //         {label}        //     </Button>
        // </OverlayTrigger>
        <Button className={props.className} onClick={props.onClick} aria-label={props.label} variant={props.variant}>
            <FontAwesomeIcon icon={props.icon} />
            {label}
        </Button>
    );
}
IconButton.defaultProps = {
  className: ""
}
export default IconButton;