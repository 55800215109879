import {SerializedProducts} from "../services/api/api";
import {ProductId} from "../types/product";


const ProductsStub: SerializedProducts =
    {
        "term-life-add": {
            id: ProductId.TERM_LIFE_ADD,
            displayName: "Term Life AD&D",
            productKey: "term-life-add",
            componentNames: [
                {name: "EConsentPage", display: "E-Consent"},
                {name: "DemographicsPage", display: "Demographics"},
                {name: "TermLifeAddMemberCoverageOptionsPage", display: "Member Coverage"},
                {name: "SpouseCoverageOptionsPage", display: "Spouse Coverage"},
                {name: "DependentCoverageOptionsPage", display: "Dependent Coverage"},
                {name: "BeneficiariesPage", display: "Beneficiaries"},
                {name: "UnderwritingPage", display: "Health Questions"},
                {name: "Prescription", display: "Physician & Prescription Information"},
                {name: "FraudsRepresentationsPage", display: "Fraud Representations"},
                {name: "PrivacyNoticePage", display: "Privacy Notice"},
                {name: "MIBPreNotice", display: "MIB Pre Notice"},
                {name: "DeclarationsAndSignaturesPage", display: "Declarations & Signatures"},
                {name: "AuthorizationsPage", display: "Authorizations"},
                {name: "OptInsPage", display: "Opt Ins"},
                {name: "SummaryPage",  display: "Summary"},
                {name: "PaymentPage",  display: "Payment"}
            ],
            showBasicAnnualEarnings: false,
            showHeight: false,
            showWeight: false,
            minAge: 18,
            maxAge: 70
        },
        "guaranteed-term-life-add": {
            id: ProductId.GUARANTEED_TERM_LIFE_ADD,
            displayName: "Guaranteed Term Life AD&D",
            productKey: "guaranteed-term-life-add",
            componentNames: [
                { name: "EConsentPage", display: "E-Consent" },
                { name: "DemographicsPage", display: "Demographics" },
                { name: "TermLifeGI25MemberCoverageOptions", display: "Member Coverage" },
                { name: "BeneficiariesPage", display: "Beneficiaries" },
                { name: "UnderwritingPage", display: "Health Questions" },
                { name: "FraudsRepresentationsPage", display: "Fraud Representations" },
                { name: "PrivacyNoticePage", display: "Privacy Notice" },
                { name: "DeclarationsAndSignaturesPage", display: "Declarations & Signatures" },
                { name: "AuthorizationsPage", display: "Authorizations" },
                { name: "OptInsPage", display: "Opt Ins" },
                { name: "SummaryPage", display: "Summary" },
                { name: "PaymentPage", display: "Payment" }
            ],
            showBasicAnnualEarnings: false,
            showHeight: false,
            showWeight: false,
            minAge: 18,
            maxAge: 45
        },
        "5k": {
            id: ProductId.GI_TERM_LIFE,
            displayName: "$5k, 12-Month Guaranteed Issue Term Life",
            productKey: "5k",
            componentNames: [
                {name: "EConsentPage", display: "E-Consent"},
                {name: "DemographicsPage", display: "Demographics"},
                {name: "TermLifeGIMemberCoverageOptions", display: "Member Coverage"},
                {name: "BeneficiariesPage", display: "Beneficiaries"},
                {name: "FraudsRepresentationsPage", display: "Fraud Representations"},
                {name: "PrivacyNoticePage", display: "Privacy Notice"},
                {name: "DeclarationsAndSignaturesPage", display: "Declarations & Signatures"},
                {name: "OptInsPage", display: "Opt Ins"},
                {name: "SummaryPage",  display: "Summary"},
            ],
            showBasicAnnualEarnings: false,
            showHeight: false,
            showWeight: false,
            minAge: 18,
            maxAge: 70
        },
        "ltd-disability": {
            id: ProductId.LTD_DISABILITY,
            displayName: "LTD - Disability Income",
            productKey: "ltd-disability",
            componentNames: [
                {name: "EConsentPage", display: "E-Consent"},
                {name: "DemographicsPage", display: "Demographics"},
                {name: "LtdDisabilityMemberCoverageOptions", display: "Member Coverage"},
                {name: "UnderwritingPage", display: "Health Questions"},
                {name: "Prescription", display: "Physician & Prescription Information"},
                {name: "FraudsRepresentationsPage", display: "Fraud Representations"},
                {name: "PrivacyNoticePage", display: "Privacy Notice"},
                {name: "MIBPreNotice", display: "MIB Pre Notice"},
                {name: "DeclarationsAndSignaturesPage", display: "Declarations & Signatures"},
                {name: "AuthorizationsPage", display: "Authorizations"},
                {name: "OptInsPage", display: "Opt Ins"},
                {name: "SummaryPage",  display: "Summary"},
                {name: "PaymentPage",  display: "Payment"}
            ],
            showBasicAnnualEarnings: true,
            showHeight: true,
            showWeight: true,
            minAge: 18,
            maxAge: 70
        }
    };

export default ProductsStub;