import ScreenCard from "../Cards/ScreenCard";

import {Page} from "../../types/page";
import {useContext, useState} from "react";
import SessionContext, {SessionData, SessionHelper} from "../../services/session/session";
import {Card, Col, Form, Row, Table} from "react-bootstrap";
import Numbers from "../../helpers/numbers";
import {JwtApiClient, RateDto} from "../../services/api/api";
import {LayoutSide, Margin} from "../Utility/Layout";
import IconButton from "../Buttons/IconButton";
import {faCreditCard} from "@fortawesome/free-regular-svg-icons";
import FormWithValidation from "../Forms/FormWithValidation";
import {HtmlFormValidator} from "../../helpers/validator";
import Listeners from "../../helpers/listeners";
import Loading from "../Transitions/Loading";
import {ProductId} from "../../types/product";
import {faCheck} from "@fortawesome/free-solid-svg-icons/faCheck";
import {useNavigate} from "react-router-dom";


const SummaryPage = (props: Page) => {
  const navigate = useNavigate();
  const session = useContext(SessionContext);
  let newVar: RateState = {totalAnnually: 0, totalMonthly: 0, lineItems: [], loading: true};
  const [r, setR] = useState(newVar);
  const [giLoad, setGiLoad] = useState(false)
  if (r.loading) {
    rates(session.data.applicationId).then((x: RateState) => {
      setR(prev => x);
    })
  }
  const isGi = session.data.product.id === ProductId.GI_TERM_LIFE;

  const onSubmitLocal = () => {
    if (isGi) {
      setGiLoad(true);
      const client = new JwtApiClient();
      const isUWReferral = session.data.uwReferral.member.shownSOHForm || session.data.uwReferral.member.shownLongForm || session.data.uwReferral.spouse.shownSOHForm || session.data.uwReferral.spouse.shownLongForm
      client.confirmGiApplication(session.data.applicationId, isUWReferral).then((x) => {
        setTimeout(() => {
          session.data.pdmSuccess = x.pdmSuccess;
          if (x.success) {
            navigate('/confirmation');
          }
        }, 3000);

      });

    } else {
      props.onSubmit();
    }
  }


  const {totalAnnually, totalMonthly} = r;


  const iconButtonProps = {
    label: isGi ? 'Submit Order' : 'Make Payment',
    icon: isGi ? faCheck : faCreditCard,
    onClick: isGi ? onSubmitLocal : Listeners.submitForm('payment-options')
  }

  return (
    <ScreenCard showNext={false} showPrevious={props.showPrevious}
                showSubmit={false} onNext={props.onNext}
                onPrevious={props.onPrevious} onSubmit={props.onSubmit} title="Your Order Summary" showRequiredField={false}>
      {r.loading ? <Loading message="Loading"/> : null}
      {giLoad ? <Loading message={"Submitting your application..."}/> : null}
      <Row className="mb-3">
        <Col xs={12} lg={6}>
          <Card className="card-borderless">
            <Card.Body>
              <h2 className={"card-title summary-title"}>Product &amp; Coverage Information</h2>
              <CoverageTable/>
            </Card.Body>
          </Card>


        </Col>
        <Col xs={12} lg={6}>
          <Card className="card-payment">
            <Card.Body>
              <h2 className={"card-title summary-title"}>Payment Breakdown</h2>
              <PaymentTable {...r} />
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <FormWithValidation validator={new HtmlFormValidator()} controlId="payment-options" onSubmit={props.onNext}>
        <Row className="d-flex align-items-center">
          {!isGi ? <Col xs={12} lg={3}><PaymentOptions totalAnnually={totalAnnually}
                                                       totalMonthly={totalMonthly}/></Col> : null}
          <Col>
            <IconButton showLabel={true} variant="success" controlId="btn-make-payment" {...iconButtonProps} />
          </Col>
        </Row>
      </FormWithValidation>
    </ScreenCard>
  );
}

type PaymentOptionsProps = {
  totalAnnually: number,
  totalMonthly: number,
}
const PaymentOptions = (props: PaymentOptionsProps) => {
  const session = useContext(SessionContext);
  const {totalMonthly, totalAnnually} = props;
  const setPaymentFreq = (i: number) => {
    return () => {
      session.data.paymentFrequency = i
    }
  }
  return (
    <Card className="card-borderless">
      <Card.Body>
        <h2 className="card-title h4 mb-3 summary-title">Choose Your Payment Option</h2>
        <Margin side={LayoutSide.BOTTOM} level={3}>
          <Form.Check type="radio">
            <Form.Check.Input aria-describedby={"pmt-opt-monthly-invalid"} type="radio" id={"pmt-opt-pay-in-full"}
                              onChange={setPaymentFreq(12)} name="paymentOptions" required={true}/>
            <Form.Check.Label htmlFor={"pmt-opt-pay-in-full"}>
              <span className="d-block">Pay in Full</span>
              <strong><span
                className="text-primary">{Numbers.toCurrency(totalAnnually)} Due</span></strong>
            </Form.Check.Label>
          </Form.Check>
        </Margin>

        <Form.Check type="radio">
          <Form.Check.Input type="radio" id={"pmt-opt-monthly"} aria-describedby={"pmt-opt-monthly-invalid"} onChange={setPaymentFreq(1)} name="paymentOptions" required={true}/>
          <Form.Check.Label htmlFor={"pmt-opt-monthly"} >
            <span className="d-block">Pay in Monthly Payments</span>
            <span
              className="text-primary"><strong>{Numbers.toCurrency(totalMonthly)} Due</strong></span></Form.Check.Label>
          <Form.Control.Feedback type="invalid" id={"pmt-opt-monthly-invalid"}>
            Please select a payment option.
          </Form.Control.Feedback>
        </Form.Check>

      </Card.Body>
    </Card>
  );
}


export const CoverageTable = () => {
  const session = useContext(SessionContext);

  // const lineItems = [];
  // if(SessionHelper.memberWantsAdd(session)){
  //   lineItems.push({
  //     name: 'AD&D Coverage Amount',
  //     value: SessionHelper.memberAddAmount(session),
  //   })
  // }
  //
  // if(SessionHelper.wantsSpouse(session)){
  //   lineItems.push({
  //     name: 'Spouse Coverage Amount',
  //     value: SessionHelper.spouseCoverageAmount(session)
  //   })
  // }
  // let numDependents = 0;
  // if(SessionHelper.hasDependents(session)){
  //   numDependents = SessionHelper.numberOfDependents(session);
  //   lineItems.push({
  //     name: `Coverage Amount for ${numDependents} child(ren)`,
  //     value: SessionHelper.dependentCoverageAmount(session)
  //   })
  // }


  const addLineItem = SessionHelper.memberWantsAdd(session) ?
    (<LineItem name={"AD&D Coverage Amount"} value={SessionHelper.memberAddAmount(session)}/>) : null;

  const wantsSpouse = SessionHelper.wantsSpouse(session) ?
    <LineItem name="Spouse Coverage Amount" value={SessionHelper.spouseCoverageAmount(session)}/> : null;

  let dependents = null;

  if (SessionHelper.hasDependents(session)) {
    let numDependents = SessionHelper.numberOfDependents(session);
    const coverageAmount = SessionHelper.dependentCoverageAmount(session);
    dependents = <LineItem name={`Coverage Amount for ${numDependents} child(ren)`} value={coverageAmount}/>
  }

  return (
    <Table borderless={true}>
      <tbody>
        <LineItemHeader name="Product" value={session.data.product.displayName}/>
        <LineItem name="Effective Date" value={effectiveDate(session.data)}/>
        <LineItem name="Coverage Amount" value={SessionHelper.memberCoverageAmount(session)}/>
        {addLineItem}
        {wantsSpouse}
        {dependents}
      </tbody>
    </Table>
  )
}

type RateState = {
  totalMonthly: number,
  totalAnnually: number;
  lineItems: PaymentLineItemProps[],
  loading: boolean
}

async function rates(appId: number): Promise<RateState> {
  const client = new JwtApiClient();
  const rates: RateDto[] = await client.fetchRates(appId);

  let totalMonthly = 0;
  const lineItems: PaymentLineItemProps[] = rates.map(x => {
    let plural = x.coverage === 'Child' ? "(ren)" : "(s)";
    let type = x.type;
    if (x.type !== 'AD&D') {
      type = x.type.charAt(0).toUpperCase() + x.type.slice(1).toLowerCase();
    }

    totalMonthly += x.amount;
    return {
      name: `${type} Coverage for ${x.number} ${x.coverage}${plural}`,
      monthly: Numbers.toCurrency(x.amount),
      annual: Numbers.toCurrency(x.amount * 12),
    }
  });
  const totalAnnually = totalMonthly * 12;
  return {totalMonthly, lineItems, totalAnnually, loading: false};
}

const PaymentTable = (props: RateState) => {
  const session = useContext(SessionContext);
  //TODO: Call API for this.
  const {lineItems, totalMonthly, totalAnnually} = props;


  return (
    <Table className="table-payment" borderless={true}>
      <thead>
      <tr>
        <th>Line Item</th>
        <th>Monthly</th>
        <th>Annual</th>
      </tr>
      </thead>
      <tbody>
      {lineItems.map(x => (<PaymentLineItem {...x} />))}
      </tbody>
      <tfoot className="text-primary">
      <PaymentTotal name={"Total Cost"} monthly={Numbers.toCurrency(totalMonthly)}
                    annual={Numbers.toCurrency(totalAnnually)}/>
      </tfoot>
    </Table>
  );
}


type LineItemProps = {
  name: string,
  value: string;
}
const LineItem = (props: LineItemProps) => {
  return (
    <tr>
      <th>{props.name}</th>
      <td>{props.value}</td>
    </tr>
  )
}

const LineItemHeader = (props: LineItemProps) => {
  return (
    <tr className="line-item-header">
      <th>{props.name}</th>
      <td>{props.value}</td>
    </tr>
  )
}

type PaymentLineItemProps = {
  name: string,
  monthly: string;
  annual: string;
}
const PaymentLineItem = (props: PaymentLineItemProps) => {
  return (
    <tr>
      <th>{props.name}</th>
      <td>{props.monthly}</td>
      <td>{props.annual}</td>
    </tr>
  )
}

const PaymentTotal = (props: PaymentLineItemProps) => {
  return (
    <tr>
      <th>{props.name}</th>
      <th>{props.monthly}</th>
      <th>{props.annual}</th>
    </tr>
  )
}




const effectiveDate = (s: SessionData) => {
  return new Date(s.effectiveDate).toLocaleDateString('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  });
}



export default SummaryPage;