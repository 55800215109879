import {Button, Col, Row, Stack} from "react-bootstrap";
import IconButton from "../Buttons/IconButton";
import {faBackward, faForward} from "@fortawesome/free-solid-svg-icons";
import {Margin} from "../Utility/Layout";

export type SequenceButtonProps = {
    showNext: boolean;
    showPrevious: boolean;
    showSubmit: boolean;
    onNext(): void;
    onPrevious(): void;
    onSubmit(): void;
}
const SequenceButtons = (props: SequenceButtonProps) => {
    const NextButton = props.showNext ? (<Col xs={6} lg={2} className="p-0">
        <IconButton icon={faForward} controlId="si-forward" showLabel={true} label="Next" onClick={props.onNext}
                    variant="success"/></Col>) : null;
    const PreviousButton = props.showPrevious ? (<Col xs={6} lg={2} className="p-0">
        <IconButton icon={faBackward} controlId="si-back" showLabel={true} label="Go Back" onClick={props.onPrevious}
                    variant="secondary"/></Col>) : null;
    const SubmitButton = props.showSubmit ? (<Col xs={6} lg={2} className="p-0">
        <Button variant="success" onClick={props.onSubmit}><strong>Submit Order</strong></Button></Col>) : null;
    return (
        <Row className="btn-group-sequence m-0 p-0" direction="horizontal">
            {PreviousButton}
            {NextButton}
            {SubmitButton}
        </Row>
    )
}
export default SequenceButtons;
