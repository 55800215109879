export default class Numbers {
    static toCurrency(value: number){
        return `$${value.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}`;
    }

    static isNaNOrUndefined(value: number|undefined): boolean{
        return value === undefined || isNaN(value as number);
    }

}

