import React, { useContext, useEffect, useState } from 'react';
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { useParams } from 'react-router-dom';
import { RegisterValidator } from "../../helpers/validator";
import { ResetPasswordData } from "../../services/api/api";
import { AuthenticationMethod, DirectLogInAuthentication } from "../../services/auth/authentication";
import SessionContext from "../../services/session/session";
import { InputEvent } from "../../types/events";
import AlertDanger from "../Alerts/AlertDanger";
import AlertPrimary from '../Alerts/AlertPrimary';
import AftLogo from '../Images/AftLogo';
import Loading from "../Transitions/Loading";
import FormWithValidation from "./FormWithValidation";
import { TextInput } from "./Inputs/TextInput";


const ResetPassword = () => {
    const initialState = {
        emailAddress: "",
        password: "",
        confirmedPassword: "",
        aftMembershipDate: "",
        isFailed: false, isLoading: false,
        isUpdated: false,
        loadingMessage: "Submitting...",
        isValid: false,
    }

    const { token } = useParams();
    const session = useContext(SessionContext);

    const [state, setState] = useState(initialState);
    const setValid = (isValid: boolean) => {
        setState(p => ({...p, isValid: isValid}));
    }
    const handleChange = (e: InputEvent) => {
        let {name, value} = e.target;
        setState(prevState => ({...prevState, [name]: value}));
    }

    const setLoading= (isLoading: boolean, message: string):void => {
        const m = message ?? '';
        setState(p => ({...p, isLoading: isLoading, loadingMessage: m}));
    }
    const validator =  new RegisterValidator();

    let authMethod: AuthenticationMethod = new DirectLogInAuthentication();
    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        e.stopPropagation();
        let isInvalid = !validator.validate(e.target as HTMLFormElement);
        setValid(!isInvalid);
        if(isInvalid){
            return;
        }

        const resetData: ResetPasswordData = {
            password: state.password,
            confirmedPassword: state.confirmedPassword,
            token: token
        }

        authMethod.resetPassword(resetData).then(fulfilled => {
            setState(prevState => ({...prevState, isFailed: !fulfilled}));
            if (fulfilled) {
                setState(prevState => ({...prevState, isUpdated: true}));
                return;
            }
            setLoading(false, initialState.loadingMessage);
        });
    }
    useEffect(() => {
        setLoading(true, "Checking token");
        if (!token) {
            setState(prevState => ({ ...prevState, isFailed: true }));
        } else {
            authMethod.checktoken(token).then(z => {
                if (z) {
                    setLoading(false, "Checking token");
                } else {
                    setLoading(false, "Checking token");
                    setState(prevState => ({ ...prevState, isFailed: true }));
                }
            });
        }
    },[]);

    

    const alert = state.isFailed ?  (<AlertDanger message="Token is invalid" />):null;
    const alert2 = state.isUpdated ?  (<AlertPrimary children="Pasword has been updated" />):null;
    return (
        <>
            <div className="d-flex flex-column min-vh-100 pt-3">
                <Container className="pb-5">
                    <Row className="d-flex justify-content-between">
                        <Col className="align-items-center">
                            <AftLogo className="w-50" />
                        </Col>
                        <Col className="d-flex flex-column justify-content-center align-items-end">
                            <div>
                                <h2 className="">Enrollment Form</h2>
                                <h2 className="text-primary ">{session.data.product.displayName}</h2>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <Container className="d-flex align-items-center justify-content-center">
                    <Row>
                        <Col>
                            <Card className="card-screen w-100">
                                <Card.Header>
                                    Reset password
                                </Card.Header>
                                <Card.Body>
                                    {state.isLoading ? <Loading message={state.loadingMessage} /> : null}
                                    {alert}
                                    {alert2}
                                    {!state.isUpdated && !state.isFailed ?
                                        <FormWithValidation controlId="form-register" validator={validator} onSubmit={handleSubmit}>
                                            <Row>
                                                <Col xs={12} sm={6}>
                                                    <TextInput label="Password" onChange={handleChange}
                                                        value={state.password} placeholder="Enter Password" name="password" controlId="reg-password" type="password" />
                                                </Col>
                                                <Col xs={12} sm={6}>
                                                    <TextInput label="Confirm Password" onChange={handleChange} invalidMessage="Please make sure this field is filled out and matches the password field."
                                                        value={state.confirmedPassword} pattern={state.password} placeholder="Confirm Password" name="confirmedPassword" controlId="reg-confirm-password" type="password" />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="text-center">
                                                    <Button variant="primary" className="w-50 mb-3" type="submit">Reset</Button>
                                                </Col>
                                        </Row>
                                        </FormWithValidation>
                                    : ""}
                                    <Row>
                                        <Button variant="link" href="/" >Return</Button>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
           
        </>
    )
}
export default ResetPassword;


