import {Collection, CollectionItem} from "../helpers/collections";
import {HealthQuestionProps} from "../components/Forms/HealthQuestions/HealthQuestion";

export enum HealthQuestionTypes {
  HEIGHT="Height",
  WEIGHT="Weight",
  YES_NO="Yes_No",
  TEXT="Text",
  SELECT="Select",
  STATE="State",
  DATE="Date",
  DATE_PAST="Date_Past",
  MONTH="Month",
  ZIP="Zip",
  PHONE="Phone",
  NUMBER="Number",
}

export enum HealthQuestionFormType {
  GI = "giForm",
  SHORT = "shortForm",
  LONG = "longForm",
  SOH = "soh",
  SOH_IN_PLACE = "sohInPlace",
  SOH_LONG_TRIGGER = "sohLongTriggers",
  SOH_SHORT_TRIGGER = "sohShortTriggers",
  CHILD = "child",
}

export enum HealthQuestionAskedTo {
  NONE = 0,  //0000
  MEMBER = 1 << 0, //0001
  SPOUSE = 1 << 1, //0010,
}

export type AggregatedForms =
  {
    shortForm: Collection<HealthQuestionProps>,
    sohShortTriggers: Collection<HealthQuestionProps>,
    longForm: Collection<HealthQuestionProps>,
    sohLongTriggers: Collection<HealthQuestionProps>,
    gi: Collection<HealthQuestionProps>,
    soh: Collection<HealthQuestionProps>
  };
export type ByAskedFormByFormTypeItem = CollectionItem & AggregatedForms

