import {Button} from "react-bootstrap";
import {JwtApiClient} from "../../services/api/api";
import SessionContext from "../../services/session/session";
import {useContext} from "react";

type AppPdfButtonProps = { appId: number, onError: () =>void };
const AppPdfButton = (props: AppPdfButtonProps) => {
  const session = useContext(SessionContext);
  const onButtonClick = () => {
    const client = new JwtApiClient();
    client.fetchAppPdf(props.appId).then(x => {      
      if(!x.ok){
        props.onError();
        return;
      }

      x.arrayBuffer().then(buffer => {
        try {
          const blob = new Blob([buffer], {type: 'application/pdf'});
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'application.pdf');
          document.body.appendChild(link);
          link.click();
        }
        catch(e){
          props.onError();
          return;
        }
      })
    }).catch(x => {
      props.onError();
      return;
    });
  }


  return (
    <>
      <Button variant={"success"} onClick={onButtonClick}>
        Download Application
      </Button>
      <p className={"mt-3"}>Please ensure all downloads and sites access are performed through a secure computing device and internet connection. To the maximum extent allowed by law, you expressly understand and agree that Gallagher Affinity Insurance Services, Inc. shall not be liable for any direct or indirect damages, resulting from your use of the site and disregard to these instructions. Refer to the Terms of Service for additional information regarding your use of the site.</p>
    </>
  );
}
export default AppPdfButton;