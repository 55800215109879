export enum LayoutSide {
    TOP = "mt",
    BOTTOM = "mb",
    START = "ms",
    END = "me",
    X = "mx",
    Y = "my"
}


type MarginProps = {
    side: LayoutSide,
    level: number;
    children: any;
}

export function Margin(props: MarginProps){
    return (
        <div className={`${props.side}-${props.level}`}>
            {props.children}
        </div>
    );
}
type PhoneNumberInlineProps = {
    phoneNumber: string,
    className: string,
};
export function PhoneNumberInline(props: PhoneNumberInlineProps){
    return (
        <a className={props.className} href={"tel:"+props.phoneNumber}>{props.phoneNumber}</a>
    )
}
