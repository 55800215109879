import {Collection, CollectionItem} from "../helpers/collections";

type BMIChartItem = CollectionItem & {
  lookupKey: string|number,
  weight: number
}

export const BMIChart = new Collection<BMIChartItem>([
  {
    lookupKey: 56,
    weight: 179,
  },
  {
    lookupKey: 57,
    weight: 183
  },
  {
    lookupKey: 58,
    weight: 189
  },
  {
    lookupKey: 59,
    weight: 191
  },
  {
    lookupKey: 60,
    weight: 195
  },
  {
    lookupKey: 61,
    weight: 201
  },
  {
    lookupKey: 62,
    weight: 205
  },
  {
    lookupKey: 63,
    weight: 210
  },
  {
    lookupKey: 64,
    weight: 216
  },
  {
    lookupKey: 65,
    weight: 222
  },
  {
    lookupKey: 66,
    weight: 228
  },
  {
    lookupKey: 67,
    weight: 234
  },
  {
    lookupKey: 68,
    weight: 240
  },
  {
    lookupKey: 69,
    weight: 246
  },
  {
    lookupKey: 70,
    weight: 252
  },
  {
    lookupKey: 71,
    weight: 258
  },
  {
    lookupKey: 72,
    weight: 267
  },
  {
    lookupKey: 73,
    weight: 274
  },
  {
    lookupKey: 74,
    weight: 281
  },
  {
    lookupKey: 75,
    weight: 288
  },
  {
    lookupKey: 76,
    weight: 297
  },
  {
    lookupKey: 77,
    weight: 304
  },
  {
    lookupKey: 78,
    weight: 311
  },
  {
    lookupKey: 79,
    weight: 320
  },
  {
    lookupKey: 80,
    weight: 327
  },
  {
    lookupKey: 81,
    weight: 335
  },
])