import { useState } from "react";
import { Button, Col, Row, Tab, Tabs } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import AlertPrimary from "../Alerts/AlertPrimary";
import ForgotPassword from "../Forms/ForgotPassword";
import LogIn from "../Forms/LogIn";
import Register from "../Forms/Register";
export type LoginRegisterProps = {
    onLoginSuccess(): void;
    onRegisterSuccess(): void;
}

const LogInRegisterCard = (props: LoginRegisterProps) => { 
    const [showForgotPassword, setShowForgotPassword] = useState(false);
    const [showForgotPasswordNotification, setShowForgotPasswordNotification] = useState(false);
    const handleForgotPasswordClick = () => {
        setShowForgotPasswordNotification(false);
        setShowForgotPassword(true);
    }
    const forgotPasswordSuccessHandle = () => {
        setShowForgotPassword(false);
        setShowForgotPasswordNotification(true);
        setTimeout(() => { setShowForgotPasswordNotification(false) }, 10000);
    };
    const notification = showForgotPasswordNotification ? (<AlertPrimary children="Request received. Please check your e-mail now for further instructions." />):null;
    return (
        <Card className="bg-light shadow">
            <Card.Body className="bg-light loginRegister">
                {notification} 
                {showForgotPassword ? <ForgotPassword onForgotPasswordBack={() => { setShowForgotPassword(false) }} onForgotPasswordSuccess={forgotPasswordSuccessHandle} /> :
                <Tabs fill defaultActiveKey="register" id="tabs-apply-page">
                    <Tab eventKey="register" title="New Customer Registration">
                        <div className="py-3">
                            <Register onRegisterSuccess={props.onRegisterSuccess} />
                        </div>
                    </Tab>
                    <Tab eventKey="login" title="Returning Customer Log In">
                        <div className="py-3">
                            <LogIn onLoginSuccess={props.onLoginSuccess} />
                        </div>
                    </Tab>
                </Tabs>
            }
            </Card.Body>
            {!showForgotPassword ?
                <Row className="forgotPassword">
                    <Col xs={12}>
                        <Button variant="link" className="w-100" onClick={handleForgotPasswordClick}>Forgot Password?</Button>
                    </Col>
                </Row> : null}
        </Card>
        
    );
}

export default LogInRegisterCard;




