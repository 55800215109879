type Product = {
    showHeight: boolean;
    showWeight: boolean;
    showBasicAnnualEarnings: boolean;
    name: string;
    path: string;
}

export enum ProductId {
    TERM_LIFE_ADD= 1,
    GI_TERM_LIFE = 2,
    LTD_DISABILITY = 3,
    GUARANTEED_TERM_LIFE_ADD = 4
}


export default Product;