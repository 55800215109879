import {ApplicationLayout} from "../../Layouts";
import ScreenCard from "../Cards/ScreenCard";
import Listeners from "../../helpers/listeners";
import {TextInput} from "../Forms/Inputs/TextInput";
import {useState} from "react";
import FormWithValidation from "../Forms/FormWithValidation";
import {HtmlFormValidator} from "../../helpers/validator";
import {YesNoOptions, YesNoToggle} from "../Forms/Inputs/YesNoSwitch";

type UiStencilProps = {};
const UiStencil = (props: UiStencilProps) => {

  const [ti, setTi] = useState("");
  const [yn, setYn] = useState(YesNoOptions.Yes);
  return (
    <ApplicationLayout  productName={"Sample Product"}  progressButtons={[]}>
      <ScreenCard showNext={true} showPrevious={true} showSubmit={true} onNext={Listeners.submitForm('stencil-form')} onPrevious={Listeners.doNothing} onSubmit={Listeners.doNothing} title={"Sample Screen Card"}>
        <FormWithValidation validator={new HtmlFormValidator()} controlId={"stencil-form"} onSubmit={Listeners.doNothing}>
          <TextInput onChange={(e) => setTi(e.target.value)} controlId={"stencil-req-text"} label={"Required Text"} name={"required"} value={ti} type={"text"} placeholder={"Required Text"} />

          <YesNoToggle label={"This is a yes-no toggle."} onNoSelected={()=>setYn(YesNoOptions.No)} value={yn} onYesSelected={() => setYn(YesNoOptions.Yes)} name={"stencil-yes-no"} controlId={"stencil-yes-no"} />
        </FormWithValidation>
      </ScreenCard>
    </ApplicationLayout>
  );
}
export default UiStencil;