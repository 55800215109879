import {Alert} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTriangleExclamation} from "@fortawesome/free-solid-svg-icons";

type AlertDangerProps = {
    message: string;
};
const AlertDanger = (props: AlertDangerProps) => {
    return (
        <Alert variant="danger" className="d-flex align-items-center">
            <FontAwesomeIcon icon={faTriangleExclamation} size="2x" className="me-3"/>
            <p className="mb-0">{props.message}</p>
        </Alert>
    );
}
export default AlertDanger;