import {FloatingLabel, Form, FormControl} from "react-bootstrap";
import React, {useState} from "react";
import ConsoleLogger from "../../../services/logging/logger";
import {ValidationMessages} from "../../../helpers/validator";


type TextInputProps = {
    name: string;
    type: string;
    label: string;
    controlId: string;
    value: string|number|string[]|undefined;
    placeholder?: string;
    required: boolean;
    disabled: boolean;
    pattern: string;
    invalidMessage: string;
    onChange(e: any): void;
    onBlur?(e: any): void;
    onFocus?(e: any): void;
    hideLabel?: boolean;
    appendLabel?: boolean;
    minValue: string|number|string[]|undefined;
    maxValue: string|number|string[]|undefined;
}

TextInput.defaultProps = {
    required: true,
    disabled: false,
    pattern: "",
    invalidMessage: "",
    appendLabel: true,
    minValue: undefined,
    maxValue: undefined
};



const invalidMessage = (controlId: string, type: string, override: string = ''): string => {
    if(override !== ''){
        return override
    }
    let invalidElement: HTMLInputElement|null = getControl(controlId);
    if(invalidElement == null){
        return override;
    }
    if(type === 'zip'){
        return ValidationMessages.ZIP_CODE;
    }
    if(type === 'ssn'){
        return ValidationMessages.SSN;
    }
    return invalidElement.validationMessage;
}

const getControl = (controlId:string): HTMLInputElement|null => {
    return document.getElementById(controlId) as HTMLInputElement|null;
}

export function TextInput(props: TextInputProps) {
    const required = props.required ? "required" : "";
    const [validState, setValidState] = useState(true);
    const p = props.pattern !== '' ? props.pattern : undefined;
    const className = `mb-3 `;
    const invMessage: string = invalidMessage(props.controlId, props.type, props.invalidMessage);
    const placeholder = !props.placeholder ? props.label : props.placeholder;
    const logger = new ConsoleLogger("TextInput");


    const validationParams = () => {
        if (props.type === 'dob' || props.type === 'past-date' || props.type === 'dependentDob' || props.type === 'dob-adult'){
            let max = new Date(Date.now());
            let day = max.getDate().toLocaleString('en-US', {minimumIntegerDigits:2});
            let month = (max.getMonth() + 1).toLocaleString('en-US', { minimumIntegerDigits: 2 });
            let min = new Date(Date.now());
            let minAge = (props.minValue ?? 18) as number;
            let maxAge = (props.maxValue ?? 70) as number;
            min.setFullYear(min.getFullYear() - maxAge);
            min.setDate(min.getDate() + 1);
            let formattedDate = min.toISOString().slice(0, 10)
            var params = {
                type: "date",
                max: `${max.getFullYear()}-${month}-${day}`,
            };


            if (props.type === 'dob') {
                return {
                    ...params,
                    min: `${formattedDate}`,
                }
            }
            if(props.type === 'dob-adult'){
                return {
                    ...params,
                    min: `${formattedDate}`,
                    max: `${max.getFullYear() - minAge}-${month}-${day}`
                }
            }

            if(props.type === 'dependentDob'){
                const depMin = new Date(Date.now());
                depMin.setFullYear(depMin.getFullYear() - 27);
                const depMonth = (depMin.getMonth()+1).toLocaleString('en-US', { minimumIntegerDigits: 2 })
                const depDay = depMin.getDate().toLocaleString('en-US', { minimumIntegerDigits: 2 });
                return {
                    min: `${depMin.getFullYear()}-${depMonth}-${depDay}`,
                    ...params
                }
            }

            return params;
        }

        if(props.type === 'zip'){
            //Regex pattern for only 5 digits
            return {
                type: 'text',
                pattern: "\\d{5}"
            }
        }
        
        if(props.type === 'month'){
            return {
                type: 'month',
                max: new Date().toISOString().slice(0, 7) 
            }
        }

        if(props.type === 'firstName'){
            return {
                type: 'text',
                max: 25
            }
        }

        if(props.type === 'middleName' || props.type === 'lastName'){
            return {
                type: 'text',
                max: 30
            }
        }



        if(props.type === 'ssn'){
            return {
                type: 'text',
                pattern: "[*]{3}-[*]{2}-[0-9]{4}|[0-9]{9}"
            }
        }

        if(props.type === 'weight'){
            return {
                type: 'number',
                min: 10
            }
        }

        if(props.type === 'phone'){
            return {
                type: 'text',
                pattern:"[0-9]{3}-[0-9]{3}-[0-9]{4}"
            }
        }

        if(props.type=== 'oneYear'){
            let minDate = new Date(Date.now());
            minDate.setFullYear(minDate.getFullYear() - 1);
            let minDateMonth = (minDate.getMonth() + 1).toLocaleString('en-US', { minimumIntegerDigits: 2 });
            let minDateDay = minDate.getDate().toLocaleString('en-US', { minimumIntegerDigits: 2 });
            let max = new Date(Date.now());
            return {
                min: `${minDate.getFullYear()}-${minDateMonth}-${minDateDay}`,
                type: "date",
                max: `${max.getFullYear()}-${minDateMonth}-${minDateDay}`,
            }
        }

        return {
            type: props.type
        }
    }
    var params = {
        onFocus: props.onFocus,
        onBlur: props.onBlur,
        name: props.name,
        value: props.value,
        onInputCapture: props.onChange,
        placeholder: placeholder,
        required: props.required,
        disabled: props.disabled,
        onChange: props.onChange,
        ...(validationParams())
    };


    if (!props.hideLabel) {
        if (props.label && props.label.length < 98) { // 98 - minimum length of long length question which label cutted off
            return (
                <>
                    {props.appendLabel && (
                        <Form.Label className={`d-block d-lg-none yn-label ${required}`}>
                            {props.label}
                        </Form.Label>
                    )}
                    <FloatingLabel controlId={props.controlId} className={className} label={props.label}>
                        <Form.Control {...params} aria-describedby={`${props.controlId}-invalid`} />
                        <Form.Control.Feedback id={`${props.controlId}-invalid`} type="invalid">{invMessage}</Form.Control.Feedback>
                    </FloatingLabel>
                </>
            );
        }
        else {
            params.placeholder = "";
            return (
                <>
                    {props.appendLabel && (
                        <Form.Label className={`d-block yn-label ${required}`}>
                            {props.label}
                        </Form.Label>
                    )}
                    <Form.Control {...params} id={props.controlId} aria-describedby={`${props.controlId}-invalid`} />
                    <Form.Control.Feedback id={`${props.controlId}-invalid`} type="invalid">{invMessage}</Form.Control.Feedback>
                </>
            );
        }
    } else {
        return (
            <>
                <Form.Control aria-describedby={`${props.controlId}-invalid`} id={props.controlId} {...params} />
                <Form.Control.Feedback id={`${props.controlId}-invalid`} type="invalid">{invMessage}</Form.Control.Feedback>
            </>
        );
    }
}