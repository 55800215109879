import {HealthQuestionProps} from "../components/Forms/HealthQuestions/HealthQuestion";
import {HealthQuestionAskedTo, HealthQuestionFormType, HealthQuestionTypes} from "../models/health-questions";
import {YesNoOptions} from "../components/Forms/Inputs/YesNoSwitch";
import {ProductId} from "../types/product";
import {Collection} from "../helpers/collections";

const productId = ProductId.LTD_DISABILITY;
const emptyChildren = new Collection<HealthQuestionProps>();
const NO_CHOICES: string[] = [];
const DEFINITION_DEFAULT = null;
const inPlaceSOHQuestions = (parentLookupKey: string, productId: ProductId, askedTo: HealthQuestionAskedTo): Collection<HealthQuestionProps> => {
  return new Collection<HealthQuestionProps>([
    {
      lookupKey: `${parentLookupKey}_condition`,
      type: HealthQuestionTypes.TEXT,
      label: "Condition / Diagnosis",
      value: undefined,
      askedTo: HealthQuestionAskedTo.MEMBER,
      product: productId,
      definition: DEFINITION_DEFAULT,
      formType: HealthQuestionFormType.LONG,
      children: emptyChildren,
      choices: NO_CHOICES
    },
    {
      lookupKey: `${parentLookupKey}_prescriptions`,
      type: HealthQuestionTypes.TEXT,
      label: "Please list any medication prescribed that you did not already identify in the Prescription Information above.",
      value: undefined,
      askedTo: HealthQuestionAskedTo.MEMBER,
      product: productId,
      definition: DEFINITION_DEFAULT,
      formType: HealthQuestionFormType.LONG,
      children: emptyChildren,
      choices: NO_CHOICES
    },
    {
      lookupKey: `${parentLookupKey}_date-of-diagnosis`,
      type: HealthQuestionTypes.MONTH,
      label: "Date of diagnosis (Month/Year)",
      value: undefined,
      askedTo: HealthQuestionAskedTo.MEMBER,
      product: productId,
      definition: DEFINITION_DEFAULT,
      formType: HealthQuestionFormType.LONG,
      children: emptyChildren,
      choices: NO_CHOICES
    },
    {
      lookupKey: `${parentLookupKey}_date-of-last-treatment`,
      type: HealthQuestionTypes.MONTH,
      label: "Date of last treatment (Month/Year)",
      value: undefined,
      askedTo: HealthQuestionAskedTo.MEMBER,
      product: productId,
      definition: DEFINITION_DEFAULT,
      formType: HealthQuestionFormType.LONG,
      children: emptyChildren,
      choices: NO_CHOICES
    },
    {
      lookupKey: `${parentLookupKey}_type-of-treatment`,
      type: HealthQuestionTypes.TEXT,
      label: "Type of treatment",
      value: undefined,
      askedTo: HealthQuestionAskedTo.MEMBER,
      product: productId,
      definition: DEFINITION_DEFAULT,
      formType: HealthQuestionFormType.LONG,
      children: emptyChildren,
      choices: NO_CHOICES
    },
    {
      lookupKey: `${parentLookupKey}_physicians-name`,
      type: HealthQuestionTypes.TEXT,
      label: "Physician's Name",
      value: undefined,
      askedTo: HealthQuestionAskedTo.MEMBER,
      product: productId,
      definition: DEFINITION_DEFAULT,
      formType: HealthQuestionFormType.LONG,
      children: emptyChildren,
      choices: NO_CHOICES
    },
    {
      lookupKey: `${parentLookupKey}_date-last-physician-visit`,
      type: HealthQuestionTypes.DATE_PAST,
      label: "Date of last visit",
      value: undefined,
      askedTo: HealthQuestionAskedTo.MEMBER,
      product: productId,
      definition: DEFINITION_DEFAULT,
      formType: HealthQuestionFormType.LONG,
      children: emptyChildren,
      choices: NO_CHOICES
    },
    {
      lookupKey: `${parentLookupKey}_reason-for-visit`,
      type: HealthQuestionTypes.TEXT,
      label: "Reason for visit",
      value: undefined,
      askedTo: HealthQuestionAskedTo.MEMBER,
      product: productId,
      definition: DEFINITION_DEFAULT,
      formType: HealthQuestionFormType.LONG,
      children: emptyChildren,
      choices: NO_CHOICES
    },
    {
      lookupKey: `${parentLookupKey}_physician-street-address`,
      type: HealthQuestionTypes.TEXT,
      label: "Physician Street Address",
      value: undefined,
      askedTo: HealthQuestionAskedTo.MEMBER,
      product: productId,
      definition: DEFINITION_DEFAULT,
      formType: HealthQuestionFormType.LONG,
      children: emptyChildren,
      choices: NO_CHOICES
    },
    {
      lookupKey: `${parentLookupKey}_physician-city`,
      type: HealthQuestionTypes.TEXT,
      label: "City",
      value: undefined,
      askedTo: HealthQuestionAskedTo.MEMBER,
      product: productId,
      definition: DEFINITION_DEFAULT,
      formType: HealthQuestionFormType.LONG,
      children: emptyChildren,
      choices: NO_CHOICES
    },
    {
      lookupKey: `${parentLookupKey}_physician-state`,
      type: HealthQuestionTypes.STATE,
      label: "State",
      value: undefined,
      askedTo: HealthQuestionAskedTo.MEMBER,
      product: productId,
      definition: DEFINITION_DEFAULT,
      formType: HealthQuestionFormType.LONG,
      children: emptyChildren,
      choices: NO_CHOICES
    },
    {
      lookupKey: `${parentLookupKey}_physician-zip-code`,
      type: HealthQuestionTypes.ZIP,
      label: "Zip Code",
      value: undefined,
      askedTo: HealthQuestionAskedTo.MEMBER,
      product: productId,
      definition: DEFINITION_DEFAULT,
      formType: HealthQuestionFormType.LONG,
      children: emptyChildren,
      choices: NO_CHOICES
    },
    {
      lookupKey: `${parentLookupKey}_physician-phone`,
      type: HealthQuestionTypes.PHONE,
      label: "Phone Number",
      value: undefined,
      askedTo: HealthQuestionAskedTo.MEMBER,
      product: productId,
      definition: DEFINITION_DEFAULT,
      formType: HealthQuestionFormType.LONG,
      children: emptyChildren,
      choices: NO_CHOICES
    },
  ]);
}
export const LTD_HOSPITALIZATION_QUESTION: HealthQuestionProps = {
  lookupKey: "has-been-hospitalized-90-days",
  type: HealthQuestionTypes.YES_NO,
  label: "Have you been Hospitalized as defined below (not including well-baby delivery) in the past 90 days?",
  value: YesNoOptions.NotSelected,
  askedTo: HealthQuestionAskedTo.MEMBER,
  product: ProductId.LTD_DISABILITY,
  definition: "Hospitalized means admission for inpatient care in a hospital; receipt of care in a hospice facility, intermediate care facility, or long term care facility; or receipt of the following treatment wherever performed: chemotherapy, radiation therapy, or dialysis.",
  formType: HealthQuestionFormType.SHORT,
  children: emptyChildren,
  choices: NO_CHOICES,
};


export const LTD_HOSPITALIZATION_QUESTION_WITH_SOH: HealthQuestionProps = {
  lookupKey: "has-been-hospitalized-90-days", // to do check if we need that question, so previous is the same
  type: HealthQuestionTypes.YES_NO,
  label: "Have you been Hospitalized as defined below (not including well-baby delivery) in the past 90 days?",
  value: YesNoOptions.NotSelected,
  askedTo: HealthQuestionAskedTo.MEMBER,
  product: ProductId.LTD_DISABILITY,
  definition: "Hospitalized means admission for inpatient care in a hospital; receipt of care in a hospice facility, intermediate care facility, or long term care facility; or receipt of the following treatment wherever performed: chemotherapy, radiation therapy, or dialysis.",
  formType: HealthQuestionFormType.LONG,
  children: inPlaceSOHQuestions('has-been-hospitalized-90-days', ProductId.LTD_DISABILITY, HealthQuestionAskedTo.MEMBER),
  choices: NO_CHOICES,
};

function abbreviatedHealthQuestions(formType: HealthQuestionFormType): Array<HealthQuestionProps> {
  const SOH_TRIGGER_TYPE = formType === HealthQuestionFormType.SHORT
    ? HealthQuestionFormType.SOH_SHORT_TRIGGER : HealthQuestionFormType.SOH_LONG_TRIGGER;
  return [
    {
      lookupKey: "past-5-years-doctor-said-stop-alcohol-or-drugs",
      type: HealthQuestionTypes.YES_NO,
      label: "In the past 5 years, have you received medical treatment or counseling by a physician or other health care provider for, or been advised by a physician or other health care provider to discontinue, the use of alcohol or prescribed or non-prescribed drugs?",
      value: YesNoOptions.NotSelected,
      askedTo: HealthQuestionAskedTo.MEMBER,
      product: ProductId.LTD_DISABILITY,
      definition: "",
      formType: formType,
      choices: NO_CHOICES,
      children:  new Collection<HealthQuestionProps>([
          ...inPlaceSOHQuestions('past-5-years-doctor-said-stop-alcohol-or-drugs', productId, HealthQuestionAskedTo.MEMBER).toArray()
      ])
    },
    {
      lookupKey: "has-unfavorable-past-application",
      type: HealthQuestionTypes.YES_NO,
      label: "Have you had any application for life, accidental death and dismemberment or disability insurance declined, postponed, withdrawn, rated, modified, or issued other than as applied for?",
      value: YesNoOptions.NotSelected,
      askedTo: HealthQuestionAskedTo.MEMBER,
      product: ProductId.LTD_DISABILITY,
      definition: "",
      formType: formType,
      children:  new Collection<HealthQuestionProps>([
      {
        lookupKey: "has-unfavorable-past-application_type",
        type: HealthQuestionTypes.SELECT,
        label: "Which?",
        value: undefined,
        askedTo: HealthQuestionAskedTo.MEMBER,
        product: ProductId.LTD_DISABILITY,
        definition: "",
        formType: HealthQuestionFormType.LONG,
        children: emptyChildren,
        choices: [
          'Declined',
          'Postponed',
          'Withdrawn',
          'Rated',
          'Modified',
          'Issued other than as applied'
        ]

      },
      {
        lookupKey: "has-unfavorable-past-application_reason",
        type: HealthQuestionTypes.TEXT,
        label: "Reason",
        value: undefined,
        askedTo: HealthQuestionAskedTo.MEMBER,
        product: ProductId.LTD_DISABILITY,
        definition: "",
        formType: HealthQuestionFormType.LONG,
        children: emptyChildren,
        choices: NO_CHOICES,
      },
        ...inPlaceSOHQuestions('has-unfavorable-past-application', productId, HealthQuestionAskedTo.MEMBER).toArray()

    ]),
      choices: NO_CHOICES,
    },
    {
      lookupKey: "disability-benefits",
      type: HealthQuestionTypes.YES_NO,
      label: "Are you now receiving or applying for any disability benefits, including workers’ compensation?",
      value: YesNoOptions.NotSelected,
      askedTo: HealthQuestionAskedTo.MEMBER,
      product: ProductId.LTD_DISABILITY,
      definition: "",
      formType: formType,
      children: new Collection<HealthQuestionProps>([
        {
          lookupKey: "details",
          type: HealthQuestionTypes.TEXT,
          label: "Details",
          value: undefined,
          askedTo: HealthQuestionAskedTo.MEMBER,
          product: ProductId.LTD_DISABILITY,
          definition: "",
          formType: HealthQuestionFormType.CHILD,
          children: emptyChildren,
          choices: NO_CHOICES,
        },
        ...inPlaceSOHQuestions('disability-benefits', productId, HealthQuestionAskedTo.MEMBER).toArray()
      ]),
      choices: NO_CHOICES,
    },
    {
      lookupKey: "hiv-aids-non-ct",
      type: HealthQuestionTypes.YES_NO,
      label: "Have you ever been diagnosed or treated by a physician or other health care provider for Acquired Immunodeficiency Syndrome (AIDS), AIDS Related Complex (ARC) or the Human Immunodeficiency Virus (HIV) infection?",
      value: YesNoOptions.NotSelected,
      askedTo: HealthQuestionAskedTo.MEMBER,
      product: ProductId.LTD_DISABILITY,
      definition: "",
      formType: formType,
      children: inPlaceSOHQuestions('hiv-aids-non-ct', productId, HealthQuestionAskedTo.MEMBER),
      choices: NO_CHOICES,
    },
    {
      lookupKey: 'diagnosed-with-cardiovascular-disorder',
      type: HealthQuestionTypes.YES_NO,
      label: "Have you ever been diagnosed, treated or given medical advice by a physician or other health care provider for cardiac or cardiovascular disorder?",
      value: YesNoOptions.NotSelected,
      askedTo: HealthQuestionAskedTo.MEMBER,
      product: productId,
      definition: DEFINITION_DEFAULT,
      formType: SOH_TRIGGER_TYPE,
      children: inPlaceSOHQuestions('diagnosed-with-cardiovascular-disorder', productId, HealthQuestionAskedTo.MEMBER ),
      choices: NO_CHOICES,
    },
    {
      lookupKey: 'diagnosed-with-circulatory-disorder',
      type: HealthQuestionTypes.YES_NO,
      label: "Have you ever been diagnosed, treated or given medical advice by a physician or other health care provider for stroke or circulatory disorder?",
      value: YesNoOptions.NotSelected,
      askedTo: HealthQuestionAskedTo.MEMBER,
      product: productId,
      definition: DEFINITION_DEFAULT,
      formType: SOH_TRIGGER_TYPE,
      children: inPlaceSOHQuestions('diagnosed-with-circulatory-disorder', productId, HealthQuestionAskedTo.MEMBER ),
      choices: NO_CHOICES,
    },
    {
      lookupKey: 'diagnosed-with-high-blood-pressure',
      type: HealthQuestionTypes.YES_NO,
      label: "Have you ever been diagnosed, treated or given medical advice by a physician or other health care provider for high blood pressure?",
      value: YesNoOptions.NotSelected,
      askedTo: HealthQuestionAskedTo.MEMBER,
      product: productId,
      definition: DEFINITION_DEFAULT,
      formType: SOH_TRIGGER_TYPE,
      children: inPlaceSOHQuestions('diagnosed-with-high-blood-pressure', productId, HealthQuestionAskedTo.MEMBER ),
      choices: NO_CHOICES,
    },
    {
      lookupKey: 'diagnosed-with-cancer',
      type: HealthQuestionTypes.YES_NO,
      label: "Have you ever been diagnosed, treated or given medical advice by a physician or other health care provider for cancer, Hodgkins disease, lymphoma or tumors?",
      value: YesNoOptions.NotSelected,
      askedTo: HealthQuestionAskedTo.MEMBER,
      product: productId,
      definition: DEFINITION_DEFAULT,
      formType: SOH_TRIGGER_TYPE,
      children: inPlaceSOHQuestions('diagnosed-with-cancer', productId, HealthQuestionAskedTo.MEMBER ),
      choices: NO_CHOICES,
    },
    {
      lookupKey: 'diagnosed-with-diabetes',
      type: HealthQuestionTypes.YES_NO,
      label: "Have you ever been diagnosed, treated or given medical advice by a physician or other health care provider for diabetes?",
      value: YesNoOptions.NotSelected,
      askedTo: HealthQuestionAskedTo.MEMBER,
      product: productId,
      definition: DEFINITION_DEFAULT,
      formType: SOH_TRIGGER_TYPE,
      children: new Collection<HealthQuestionProps>([
          {
              lookupKey: "diagnosed-with-diabetes_age",
              type: HealthQuestionTypes.NUMBER,
              label: "Age at diagnosis",
              value: undefined,
              askedTo: HealthQuestionAskedTo.MEMBER,
              product: productId,
              definition: "",
              formType: HealthQuestionFormType.CHILD,
              children: emptyChildren,
              choices: NO_CHOICES,
          },
          {
              lookupKey: "diagnosed-with-diabetes_insulin-treated",
              type: HealthQuestionTypes.YES_NO,
              label: "Insulin treated?",
              value: YesNoOptions.NotSelected,
              askedTo: HealthQuestionAskedTo.MEMBER,
              product: productId,
              definition: "",
              formType: HealthQuestionFormType.CHILD,
              children: emptyChildren,
              choices: NO_CHOICES,
          },
          ...inPlaceSOHQuestions('diagnosed-with-diabetes', productId, HealthQuestionAskedTo.MEMBER).toArray()
      ]),
      choices: NO_CHOICES,
    },
    {
      lookupKey: 'diagnosed-with-musculoskeletal-disorder',
      type: HealthQuestionTypes.YES_NO,
      label: "Have you ever been diagnosed, treated or given medical advice by a physician or other health care provider for back, neck, knee, spinal, joint or other musculoskeletal disorder?",
      value: YesNoOptions.NotSelected,
      askedTo: HealthQuestionAskedTo.MEMBER,
      product: productId,
      definition: DEFINITION_DEFAULT,
      formType: SOH_TRIGGER_TYPE,
      children: inPlaceSOHQuestions('diagnosed-with-musculoskeletal-disorder', productId, HealthQuestionAskedTo.MEMBER ),
      choices: NO_CHOICES,
    },
    {
      lookupKey: 'diagnosed-with-mental-health-disorder',
      type: HealthQuestionTypes.YES_NO,
      label: "Have you ever been diagnosed, treated or given medical advice by a physician or other health care provider for mental, anxiety, depression, attempted suicide or nervous disorder?",
      value: YesNoOptions.NotSelected,
      askedTo: HealthQuestionAskedTo.MEMBER,
      product: productId,
      definition: DEFINITION_DEFAULT,
      formType: SOH_TRIGGER_TYPE,
      children: inPlaceSOHQuestions('diagnosed-with-mental-health-disorder', productId, HealthQuestionAskedTo.MEMBER ),
      choices: NO_CHOICES,
    },
  ];
}


function fullHealthQuestions(formType: HealthQuestionFormType): Array<HealthQuestionProps>{
  return [
    {
      lookupKey:"has-prescribed-diet",
      type: HealthQuestionTypes.YES_NO,
      label: "Are you now on a diet prescribed by a physician or other health care provider?",
      value: YesNoOptions.NotSelected,
      askedTo: HealthQuestionAskedTo.MEMBER,
      product: ProductId.LTD_DISABILITY,
      definition: DEFINITION_DEFAULT,
      formType: formType,
      children: new Collection<HealthQuestionProps>([
        {
          lookupKey: "has-prescribed-diet_diet-type",
          type: HealthQuestionTypes.TEXT,
          label: "Diet Type",
          value: undefined,
          askedTo: HealthQuestionAskedTo.MEMBER,
          product: ProductId.LTD_DISABILITY,
          definition: "",
          formType: HealthQuestionFormType.CHILD,
          children: emptyChildren,
          choices: NO_CHOICES,
        },

      ]),
      choices: NO_CHOICES,
    },
    {
      lookupKey:"is-pregnant",
      type: HealthQuestionTypes.YES_NO,
      label: "Are you now pregnant?",
      value: YesNoOptions.NotSelected,
      askedTo: HealthQuestionAskedTo.MEMBER,
      product: ProductId.LTD_DISABILITY,
      definition: DEFINITION_DEFAULT,
      formType: formType,
      children: new Collection<HealthQuestionProps>([
        {
          lookupKey: "is-pregnant_pregnancy-due-date",
          type: HealthQuestionTypes.DATE,
          label: "Due Date",
          value: undefined,
          askedTo: HealthQuestionAskedTo.MEMBER,
          product: ProductId.LTD_DISABILITY,
          definition: "",
          formType: HealthQuestionFormType.CHILD,
          children: emptyChildren,
          choices: NO_CHOICES,
        },
        {
          lookupKey: "is-pregnant_pregnancy-physician-name",
          type: HealthQuestionTypes.TEXT,
          label: "Physician's Name",
          value: undefined,
          askedTo: HealthQuestionAskedTo.MEMBER,
          product: ProductId.LTD_DISABILITY,
          definition: "",
          formType: HealthQuestionFormType.CHILD,
          children: emptyChildren,
          choices: NO_CHOICES,
        },
        {
          lookupKey: "is-pregnant_pregnancy-physician-phone",
          type: HealthQuestionTypes.PHONE,
          label: "Physician's Phone",
          value: undefined,
          askedTo: HealthQuestionAskedTo.MEMBER,
          product: ProductId.LTD_DISABILITY,
          definition: "",
          formType: HealthQuestionFormType.CHILD,
          children: emptyChildren,
          choices: NO_CHOICES,
        },
      ]),
      choices: NO_CHOICES,
    },
    {
      lookupKey: "tobacco-past-two-years",
      type: HealthQuestionTypes.YES_NO,
      label: "Are you now, or have you in the past 2 years, used tobacco in any form?",
      value: YesNoOptions.NotSelected,
      askedTo: HealthQuestionAskedTo.MEMBER,
      product: ProductId.LTD_DISABILITY,
      definition: "",
      formType: formType,
      children: emptyChildren,
      choices: NO_CHOICES,
    },
    {
      lookupKey: "dui-past-five-years",
      type: HealthQuestionTypes.YES_NO,
      label: "In the past 5 years, have you been convicted of driving while intoxicated or under the influence of alcohol and/or any drug?",
      value: YesNoOptions.NotSelected,
      askedTo: HealthQuestionAskedTo.MEMBER,
      product: ProductId.LTD_DISABILITY,
      definition: "",
      formType: formType,
      children: new Collection<HealthQuestionProps>([
        {
          lookupKey: "dui_date-of-conviction",
          type: HealthQuestionTypes.TEXT,
          label: "Date(s) of conviction",
          value: undefined,
          askedTo: HealthQuestionAskedTo.MEMBER,
          product: ProductId.LTD_DISABILITY,
          definition: "",
          formType: HealthQuestionFormType.CHILD,
          children: emptyChildren,
          choices: NO_CHOICES,
        },
        ...inPlaceSOHQuestions('dui-past-five-years', productId, HealthQuestionAskedTo.MEMBER,).toArray()
      ]),
      choices: NO_CHOICES,
    },
    {
      lookupKey: 'diagnosed-with-blood-disorder',
      type: HealthQuestionTypes.YES_NO,
      label: "Have you ever been diagnosed, treated or given medical advice by a physician or other health care provider for anemia, leukemia or other blood disorder?",
      value: YesNoOptions.NotSelected,
      askedTo: HealthQuestionAskedTo.MEMBER,
      product: productId,
      definition: DEFINITION_DEFAULT,
      formType: formType,
      children: inPlaceSOHQuestions('diagnosed-with-blood-disorder', productId, HealthQuestionAskedTo.MEMBER, ),
      choices: NO_CHOICES,
    },
    {
      lookupKey: 'diagnosed-with-lung-disease',
      type: HealthQuestionTypes.YES_NO,
      label: "Have you ever been diagnosed, treated or given medical advice by a physician or other health care provider for asthma, COPD, emphysema or other lung disease?",
      value: YesNoOptions.NotSelected,
      askedTo: HealthQuestionAskedTo.MEMBER,
      product: productId,
      definition: DEFINITION_DEFAULT,
      formType: formType,
      children: inPlaceSOHQuestions('diagnosed-with-lung-disease', productId, HealthQuestionAskedTo.MEMBER ),
      choices: NO_CHOICES,
    },
    {
      lookupKey: 'diagnosed-with-stomach-or-liver-disorder',
      type: HealthQuestionTypes.YES_NO,
      label: "Have you ever been diagnosed, treated or given medical advice by a physician or other health care provider for ulcers, stomach, hepatitis or other liver disorder?",
      value: YesNoOptions.NotSelected,
      askedTo: HealthQuestionAskedTo.MEMBER,
      product: productId,
      definition: DEFINITION_DEFAULT,
      formType: formType,
      children: inPlaceSOHQuestions('diagnosed-with-stomach-or-liver-disorder', productId, HealthQuestionAskedTo.MEMBER ),
      choices: NO_CHOICES,
    },
    {
      lookupKey: 'diagnosed-with-intestinal-disorder',
      type: HealthQuestionTypes.YES_NO,
      label: "Have you ever been diagnosed, treated or given medical advice by a physician or other health care provider for colitis, Crohn’s, diverticulitis or other intestinal disorder?",
      value: YesNoOptions.NotSelected,
      askedTo: HealthQuestionAskedTo.MEMBER,
      product: productId,
      definition: DEFINITION_DEFAULT,
      formType: formType,
      children: inPlaceSOHQuestions('diagnosed-with-intestinal-disorder', productId, HealthQuestionAskedTo.MEMBER ),
      choices: NO_CHOICES,
    },
    {
      lookupKey: 'diagnosed-with-memory-loss',
      type: HealthQuestionTypes.YES_NO,
      label: "Have you ever been diagnosed, treated or given medical advice by a physician or other health care provider for memory loss?",
      value: YesNoOptions.NotSelected,
      askedTo: HealthQuestionAskedTo.MEMBER,
      product: productId,
      definition: DEFINITION_DEFAULT,
      formType: formType,
      children: inPlaceSOHQuestions('diagnosed-with-memory-loss', productId, HealthQuestionAskedTo.MEMBER ),
      choices: NO_CHOICES,
    },
    {
      lookupKey: 'diagnosed-neurological-disorder',
      type: HealthQuestionTypes.YES_NO,
      label: "Have you ever been diagnosed, treated or given medical advice by a physician or other health care provider for epilepsy, paralysis, seizures, dizziness or other neurological disorder?",
      value: YesNoOptions.NotSelected,
      askedTo: HealthQuestionAskedTo.MEMBER,
      product: productId,
      definition: DEFINITION_DEFAULT,
      formType: formType,
      children: inPlaceSOHQuestions('diagnosed-neurological-disorder', productId, HealthQuestionAskedTo.MEMBER ),
      choices: NO_CHOICES,
    },
    {
      lookupKey: 'diagnosed-with-fatigue-syndromes',
      type: HealthQuestionTypes.YES_NO,
      label: "Have you ever been diagnosed, treated or given medical advice by a physician or other health care provider for Epstein-Barr, chronic fatigue syndrome or fibromyalgia?",
      value: YesNoOptions.NotSelected,
      askedTo: HealthQuestionAskedTo.MEMBER,
      product: productId,
      definition: DEFINITION_DEFAULT,
      formType: formType,
      children: inPlaceSOHQuestions('diagnosed-with-fatigue-syndromes', productId, HealthQuestionAskedTo.MEMBER ),
      choices: NO_CHOICES,
    },
    {
      lookupKey: 'diagnosed-with-muscular-dystrophy',
      type: HealthQuestionTypes.YES_NO,
      label: "Have you ever been diagnosed, treated or given medical advice by a physician or other health care provider for multiple sclerosis, ALS or muscular dystrophy?",
      value: YesNoOptions.NotSelected,
      askedTo: HealthQuestionAskedTo.MEMBER,
      product: productId,
      definition: DEFINITION_DEFAULT,
      formType: formType,
      children: inPlaceSOHQuestions('diagnosed-with-muscular-dystrophy', productId, HealthQuestionAskedTo.MEMBER ),
      choices: NO_CHOICES,
    },
    {
      lookupKey: 'diagnosed-with-auto-immune',
      type: HealthQuestionTypes.YES_NO,
      label: "Have you ever been diagnosed, treated or given medical advice by a physician or other health care provider for lupus, scleroderma, auto immune disease or connective tissue disorder?",
      value: YesNoOptions.NotSelected,
      askedTo: HealthQuestionAskedTo.MEMBER,
      product: productId,
      definition: DEFINITION_DEFAULT,
      formType: formType,
      children: inPlaceSOHQuestions('diagnosed-with-auto-immune', productId, HealthQuestionAskedTo.MEMBER ),
      choices: NO_CHOICES,
    },
    {
      lookupKey: 'diagnosed-with-arthritis',
      type: HealthQuestionTypes.YES_NO,
      label: "Have you ever been diagnosed, treated or given medical advice by a physician or other health care provider for arthritis?",
      value: YesNoOptions.NotSelected,
      askedTo: HealthQuestionAskedTo.MEMBER,
      product: productId,
      definition: DEFINITION_DEFAULT,
      formType: formType,
      children: new Collection<HealthQuestionProps>([
        {
          lookupKey: "diagnosed-with-arthritis_type",
          type: HealthQuestionTypes.SELECT,
          label: "Type",
          value: undefined,
          askedTo: HealthQuestionAskedTo.MEMBER,
          product: productId,
          definition: "",
          formType: HealthQuestionFormType.LONG,
          children: emptyChildren,
              choices: [
                  'Osteoarthritis',
                  'Rheumatoid Arthritis',
                  'Other'
              ],
        },
        ...inPlaceSOHQuestions('diagnosed-with-arthritis', productId, HealthQuestionAskedTo.MEMBER ).toArray()
      ]),
      choices: NO_CHOICES,
    },
    {
      lookupKey: 'diagnosed-with-carpal-tunnel',
      type: HealthQuestionTypes.YES_NO,
      label: "Have you ever been diagnosed, treated or given medical advice by a physician or other health care provider for carpal tunnel syndrome?",
      value: YesNoOptions.NotSelected,
      askedTo: HealthQuestionAskedTo.MEMBER,
      product: productId,
      definition: DEFINITION_DEFAULT,
      formType: formType,
      children: inPlaceSOHQuestions('diagnosed-with-carpal-tunnel', productId, HealthQuestionAskedTo.MEMBER ),
      choices: NO_CHOICES,
    },
    {
      lookupKey: 'diagnosed-with-kidney-urinary-tract-prostate-disorder',
      type: HealthQuestionTypes.YES_NO,
      label: "Have you ever been diagnosed, treated or given medical advice by a physician or other health care provider for kidney, urinary tract or prostate disorder?",
      value: YesNoOptions.NotSelected,
      askedTo: HealthQuestionAskedTo.MEMBER,
      product: productId,
      definition: DEFINITION_DEFAULT,
      formType: formType,
      children: inPlaceSOHQuestions('diagnosed-with-kidney-urinary-tract-prostate-disorder', productId, HealthQuestionAskedTo.MEMBER ),
      choices: NO_CHOICES,
    },
    {
      lookupKey: 'diagnosed-with-kidney-urinary-tract-prostate-disorder',
      type: HealthQuestionTypes.YES_NO,
      label: "Have you ever been diagnosed, treated or given medical advice by a physician or other health care provider for kidney, urinary tract or prostate disorder?",
      value: YesNoOptions.NotSelected,
      askedTo: HealthQuestionAskedTo.MEMBER,
      product: productId,
      definition: DEFINITION_DEFAULT,
      formType: formType,
      children: inPlaceSOHQuestions('diagnosed-with-kidney-urinary-tract-prostate-disorder', productId, HealthQuestionAskedTo.MEMBER ),
      choices: NO_CHOICES,
    },
    {
      lookupKey: 'diagnosed-with-gland-disorder',
      type: HealthQuestionTypes.YES_NO,
      label: "Have you ever been diagnosed, treated or given medical advice by a physician or other health care provider for thyroid or other gland disorder?",
      value: YesNoOptions.NotSelected,
      askedTo: HealthQuestionAskedTo.MEMBER,
      product: productId,
      definition: DEFINITION_DEFAULT,
      formType: formType,
      children: inPlaceSOHQuestions('diagnosed-with-gland-disorder', productId, HealthQuestionAskedTo.MEMBER ),
      choices: NO_CHOICES,
    },
    // {
    //   lookupKey: 'diagnosed-with-mental-health-disorder',
    //   type: HealthQuestionTypes.YES_NO,
    //   label: "Have you ever been diagnosed, treated or given medical advice by a physician or other health care provider for mental, anxiety, depression, attempted suicide or nervous disorder?",
    //   value: YesNoOptions.NotSelected,
    //   askedTo: askedTo,
    //   product: productId,
    //   definition: DEFINITION_DEFAULT,
    //   formType: HealthQuestionFormType.SOH_LONG_TRIGGER,
    //   children: inPlaceSOHQuestions('diagnosed-with-mental-health-disorder', productId, askedTo ),
    //   choices: NO_CHOICES,
    // },
    {
      lookupKey: 'diagnosed-with-sleep-apnea',
      type: HealthQuestionTypes.YES_NO,
      label: "Have you ever been diagnosed, treated or given medical advice by a physician or other health care provider for sleep apnea?",
      value: YesNoOptions.NotSelected,
      askedTo: HealthQuestionAskedTo.MEMBER,
      product: productId,
      definition: DEFINITION_DEFAULT,
      formType: formType,
      children: inPlaceSOHQuestions('diagnosed-with-sleep-apnea', productId, HealthQuestionAskedTo.MEMBER),
      choices: NO_CHOICES,
    },
  ]
}

export function formMembershipWithinOneYear(isBmiFavorable: boolean): Array<HealthQuestionProps>{
    const questions: Array<HealthQuestionProps> = [];
    // We always ask this question if they are within one year.
    questions.push({...LTD_HOSPITALIZATION_QUESTION});
    // Abbreviated Questions are the long form in this scenario.
    abbreviatedHealthQuestions(HealthQuestionFormType.LONG).forEach(x => questions.push(x));
    // If BMI is favorable, remaining questions are SOH; otherwise, remaining questions are LONG.
    let SOH_TYPE = HealthQuestionFormType.SOH;
    if(!isBmiFavorable){
      SOH_TYPE = HealthQuestionFormType.LONG;
    }
    fullHealthQuestions(SOH_TYPE).forEach(x => questions.push(x));
    //questions.push({...LTD_HOSPITALIZATION_QUESTION_WITH_SOH, formType: SOH_TYPE})
    return questions;
}


export function formMembershipOutsideOneYear(isBmiFavorable: boolean): Array<HealthQuestionProps>{
  const questions: Array<HealthQuestionProps> = [];

  // Abbreviated Health Questions are the short form.
  //questions.push({...LTD_HOSPITALIZATION_QUESTION_WITH_SOH});
  abbreviatedHealthQuestions(HealthQuestionFormType.SHORT).forEach(x => questions.push(x));
  // If BMI is unfavorable, SOH questions become Short questions; otherwise, they become Long questions.
  const SOH_TYPE= isBmiFavorable ? HealthQuestionFormType.LONG : HealthQuestionFormType.SHORT
  fullHealthQuestions(SOH_TYPE).forEach(x => questions.push(x));
  questions.push(LTD_HOSPITALIZATION_QUESTION_WITH_SOH);
  return questions;
}
